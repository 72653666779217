// Primary Button
.vs-btn {
    border: none;
    display: inline-block;
    text-transform: capitalize;
    line-height: 1;
    font-size: 16px;
    font-weight: 600;
    padding: 22px 33px;
    background-color: $theme-color;
    color: $white-color;
    border-radius: 7px;
    text-align: center;
    overflow: hidden;
    position: relative;
    z-index: 1;
    vertical-align: middle;
    &:after {
        content: "";
        position: absolute;
        z-index: -1;
        background-color: $title-color;
        left: auto;
        right: 0;
        top: 0;
        height: 100%;
        width: 0;
        border-radius: inherit;
        transition: all ease 0.4s;
    }
    i {
        margin-left: 4px;
    }

    &:hover {
        color: $white-color;

        &:after {
            width: 101%;
            right: auto;
            left: 0;
        }
    }
    &.btn-noradius {
        border-radius: 0;
    }

    @include xs {
        font-size: 14px;
        padding: 17px 26px;
    }

    &.outline-white {
        border: 2px solid $white-color;
        background-color: transparent;

        &:after {
            display: none;
        }

        &:hover {
            background-color: $theme-color;
            color: $white-color;
            border-color: $theme-color;
        }
    }

    &.style-white {
        background-color: $white-color;
        color: $title-color;
        &:after {
            background-color: $title-color;
        }
        &:hover {
            color: $white-color;
        }
        &.active {
            background-color: $theme-color;
            color: $white-color;
        }
    }
    &.style-white-2 {
        background-color: $white-color;
        color: $theme-color;
        &:after {
            background-color: $theme-color;
        }
        &:hover {
            color: $white-color;
        }
    }

    &.style-black {
        background-color: $black-color;
        color: $white-color;

        &:hover {
            color: $white-color;
        }

        &:after {
            background-color: $theme-color;
        }
    }

    &.style-white-hover {
        &:after {
            background-color: $white-color;
        }

        &:hover {
            color: $title-color;
        }
    }
    &.vs-btn-flex {
        min-width: 192px;
        width: 192px;
        max-width: 100%;
    }
    &.btn-center {
        margin: 0 auto;
    }
    &.btn-fw {
        width: 100%;
        display: block;
    }
    &.btn-fhw {
        width: 100%;
        height: 100%;
    }
    &.btn-sm {
        padding: 15px 25px;
    }
    &.style-outline {
        --border-color: rgb(105, 98, 98);
        border: 2px solid $border-color;
        background-color: transparent;
        padding: 20px 33px;
        &:after {
            display: none;
        }
        &:hover {
            border-color: inherit;
        }
    }
    &.style-phone {
        margin-left: calc(var(--icon-shape) / 2);
        &:after {
            display: none;
        }
        &:before {
            content: '';
            position: absolute;
            left: calc(var(--icon-shape) / -2);
            top: 50%;
            @include equal-size(var(--icon-shape));
            margin-top: calc(var(--icon-shape) / -2);
            background-color: $white-color;
            border-radius: 50%;
            box-shadow: inset 0px 5px 5px 0px rgba(246, 60, 37, 0.3);
            -webkit-transition: inherit;
            transition: inherit;
        }
        i {
            position: absolute;
            left: calc(var(--icon-size) / -2);
            top: 50%;
            @include equal-size-lineHeight(var(--icon-size));
            margin-top: calc(var(--icon-size) / -2);
            border-radius: 50%;
            background-color: $theme-color;
            font-size: 14px;
            -webkit-transition: inherit;
            transition: inherit;
            margin-left: 0;
        }
        &:hover {
            background-color: $theme-color;
            color: $white-color;
            i {
                -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
                animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
            }
        }
    }
}

// Icon Btn
.icon-btn {
    --btn-size: 50px;
    display: inline-block;
    @include equal-size-lineHeight(var(--btn-size));
    background-color: var(--icon-bg, #f3f3f3);
    color: $title-color;
    text-align: center;
    border-radius: 50%;

    &:hover,
    &.active {
        background-color: $theme-color;
        color: $white-color;
    }

    &.btn-noradius {
        border-radius: 0;
    }
    &.style-outline {
        background-color: transparent;
        border: 2px solid $white-color;
        color: $white-color;

        &:hover {
            background-color: $title-color;
            color: $white-color;
            border-color: transparent;
            transition-delay: 0s;
        }
    }
    &.style2 {
        --btn-size: 60px;
        border-radius: 0;
        line-height: 54px;
        border: 2px solid rgba(#ffffff, 0.25);
        background-color: transparent;
        color: $white-color;
        i {
            color: inherit;
        }
        &:hover {
            border-color: #ffffff;
        }
        @include xs {
            --btn-size: 48px;
            line-height: 44px !important;
        }
    }
    &.style3 {
        @extend .style2;
        border-radius: 7px;
        border: 2px solid rgba(#000, 0.1);
        color: $theme-color;
        i {
            color: inherit;
        }
        &:hover {
            border-color: $theme-color;
        }
    }
    &.style4 {
        --btn-size: 45px;
        border: none;
        border-radius: 5px;
        color: $body-color;
        background-color: $white-color;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.03);
        &:hover {
            background-color: $theme-color;
            color: $white-color;
        }
    }
    &.style5 {
        @extend .style3;
        color: $white-color;
        border: 2px solid rgba(#fff, 0.4);
        &:hover {
            border-color: $white-color;
        }
    }
    &.style6 {
        --btn-size: 36px;
        border-radius: 5px;
        font-size: 14px;
        line-height: 37px;
    }
}

[class*="vs-arrow"] {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    line-height: 47px;
    background-color: $smoke-color !important;
}

// Play Button
// Play Button
.play-btn {
    position: relative;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    color: $white-color;
    border: 2px solid;
    @include equal-size(100px);

    > i {
        color: $white-color;
        position: relative;
        z-index: 2;
        line-height: 100px;
        text-align: center;
        font-size: 18px;
        border-radius: inherit;
        transition: all ease 0.4s;
    }

    &.style-1 {
        &::before {
            content: "";
            position: absolute;
            border: 2px solid rgba(255, 255, 255, 0.4);
            @include equal-size(150px);
            top: -25px;
            left: -25px;
            z-index: 1;
            @extend .ripple-animation;
            border-radius: 50%;
            @include xs {
                @include equal-size(70px);
                left: -12px;
                top: -12px;
            }
        }

        &::after {
            content: "";
            position: absolute;
            border: 2px solid rgba(255, 255, 255, 0.4);
            @include equal-size(195px);
            top: -46px;
            left: -46px;
            z-index: 1;
            border-radius: 50%;
            @extend .ripple-animation;
            @include xs {
                @include equal-size(90px);
                left: -22px;
                top: -22px;
            }
        }
        &:hover {
            color: $theme-color;
            background-color: transparent;
            i {
                color: inherit;
            }
        }
        @include xs {
            @include equal-size(50px);
            i {
                font-size: 14px;
                line-height: 47px;
            }
        }
    }
}

// Ripple icon --------------
.ripple-icon {
	display: inline-block;
	position: relative;
	z-index: 1;
    &:after, 
    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $theme-color;
        -webkit-animation: ripple-sm 4s infinite ease-in-out;
        animation: ripple-sm 4s infinite ease-in-out;
        z-index: -1;
        border-radius: 50%;
    }
    &:after {
        -webkit-animation-delay: 1.2s;
        animation-delay: 1.2s;
    }
    i {
        @include equal-size-lineHeight(60px);
        background-color: $theme-color;
        color: $white-color;
        border: 4px solid rgba(var(--theme-color), 0.3);
        display: inline-block;
        text-align: center;
        font-size: 1.3em;
        border-radius: 50%;
    }
}

// Scroll To Top
.scroll-btn {
    --btn-size: 60px;
    --extra-shape: -6px;
    position: fixed;
    bottom: 300px;
    right: 30px;
    z-index: 94;
    opacity: 0;
    visibility: hidden;
    display: inline-block;
    border-radius: 5px;
    i {
        display: inline-block;
        background-color: $theme-color;
        color: $white-color;
        text-align: center;
        font-size: 16px;
        width: var(--btn-size);
        height: var(--btn-size);
        line-height: var(--btn-size);
        z-index: 2;
        border-radius: inherit;
        position: relative;
        transition: all ease 0.8s;
    }
    &:focus,
    &:hover {
        i {
            background-color: $title-color;
            color: $white-color;
        }
    }

    &.show {
        bottom: 120px;
        opacity: 1;
        visibility: visible;
    }

    @include sm {
        --btn-size: 40px;
        --extra-shape: -4px;
        right: 15px;
        bottom: 50px;

        &.show {
            bottom: 15px;
        }
    }
}

.scrollToTop {
    position: fixed;
    right: 60px;
    bottom: 500px;
    opacity: 0;
    visibility: hidden;
    transition: all ease 0.4s;
    z-index: 96;

    &.show {
        bottom: 60px;
        opacity: 1;
        visibility: visible;
    }
}

.cart-icon {
    color: $title-color;
    line-height: 1;
    position: relative;
    vertical-align: middle;

    .badge {
        right: -13px;
        background-color: $black-color;
        top: -7px;
        padding: 0.3em 0.45em 0.2em 0.4em;
    }
}

.simple-link {
    font-weight: 600;

    i {
        position: relative;
        top: 1px;
        margin-right: 8px;
    }

    &:hover {
        color: $theme-color !important;
    }
}

@include sm {

    .scrollToTop {
        right: 20px;

        &.show {
            bottom: 20px;
        }
    }
}

.hamburger-btn {
    padding: 7px;
    display: inline-block;
    overflow: hidden;
    background-color: transparent;
    border: none;
    z-index: 5;
    transition: all ease 0.4s;
    line-height: 1;

    .bar {
        width: 22px;
        display: inline-block;
        height: 18px;
        position: relative;
    }

    .inner,
    .hidden {
        display: inline-block;
        opacity: 0;
        width: 100%;
        height: 3px;
        background-color: $white-color;
        position: absolute;
        transition-property: transform, opacity;
        transition-timing-function: ease;
        transition-duration: 0.4s;

        &:after,
        &:before {
            content: "";
            width: 100%;
            height: 3px;
            background-color: $white-color;
            border-radius: 4px;
            position: absolute;
            transition-property: transform, opacity;
            transition-timing-function: ease;
            transition-duration: 0.4s;
        }

        &:before {
            top: -10px;
        }

        &:after {
            top: 10px;
        }
    }

    .inner {
        top: 50%;
        opacity: 1;
        transform: translate(-25px, -50%);

        &:after,
        &:before {
            opacity: 1;
            transform: translate(-5px, 0);
        }
    }

    .hidden {
        top: 50%;
        background-color: $theme-color;
        transform: translate(51px, -50%);

        &:after,
        &:before {
            background-color: $theme-color;
            transform: translate(102px, 0);
        }
    }

    &:hover {
        .inner {
            transform: translate(-51px, 50%);
            opacity: 0;

            &:after,
            &:before {
                transform: translate(102px, 0);
                opacity: 0;
            }
        }

        .hidden {
            opacity: 1;
            transform: translate(-14px, -50%);

            &:after,
            &:before {
                opacity: 1;
                transform: translate(-11px, 0);
            }
        }
    }
}

@include md {
    .play-btn {
        --icon-size: 100px;
    }
}
@include xs {
    .icon-btn.style2 {
        width: var(--btn-size, 48px);
        height: var(--btn-size, 48px);
        line-height: var(--btn-size, 43px);
    }
    .vs-btn.style-outline {
        padding: 15px 26px;
    }
}