.breadcumb-wrapper {
  padding-top: 190px;
  padding-bottom: 190px;
  background-color: $black-color;
}
.breadcumb-title {
	font-size: 60px;
	font-weight: 600;
	margin-top: 0;
	color: $white-color;
	margin-bottom: 0;
  max-width: 850px;
  line-height: 1.15;
}

.breadcumb-menu {
  max-width: 100%;
  margin: 0 0 0 0;
  padding: 0;
  list-style-type: none;
  display: flex;

  li {
    display: inline-block;
    margin-right: 3px;
    padding-right: 8px;
    list-style: none;
    position: relative;

    &:after {
      content: '/';
      margin-left: 13px;
    }

    &:last-child {
      padding-right: 0;
      margin-right: 0;

      &:after {
        display: none;
      }
    }

    
  }

  li,
  a,
  span {
    white-space: normal;
    color: inherit;
    word-break: break-word;
    font-weight: 500;
    font-size: 18px;
    color: $white-color;
    
    i {
      margin-right: 5px;
      font-size: 0.8rem;
      position: relative;
      top: -1px;
    }
    a:hover {
      color: $theme-color;
    }   
  }
}

@include xl {
  .breadcumb-title {
    font-size: 60px;
  }
}

@include ml {
  .breadcumb-title {
    font-size: 48px;
  }
}

@include lg {
  .breadcumb-wrapper {
    padding-top: 130px;
    padding-bottom: 130px;
  }
  .breadcumb-title {
    font-size: 42px;
    max-width: 650px;
  }

  .breadcumb-menu li,
  .breadcumb-menu a,
  .breadcumb-menu span {
    font-size: 17px;
  }
}

@include md {
  .breadcumb-wrapper {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .breadcumb-menu li {
    padding-right: 5px;
    --icon-size : auto;
    
    &:first-child {
      &:before {
        background-color: transparent;
        font-size: 14px;
        margin-right: 10px;
      }
    }
  }

  .breadcumb-menu li:after {
    margin-left: 11px;
  }

  .breadcumb-title {
    font-size: 36px;
    max-width: 450px;
  }
}


@include sm {
  .breadcumb-wrapper {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .breadcumb-title {
    font-size: 28px;
    max-width: 300px;
  }
}

@include xs {
  .breadcumb-title {
    margin-bottom: 20px;
  }
}