.brand {
    text-align: center;
}
.brand-section.bg-smokedark {
	--smoke-dark: rgba(244, 244, 244, 1);
}
.brand-img {
    img {
        opacity: .4;
        @include transition(.4s);
    }
    &:hover {
        img {
            opacity: 1;
        }
    }
}


@include md {
    .brand-row.px-100 {
        padding-left: 50px;
        padding-right: 50px;
    }
    .brand-row.pt-50 {
        padding-top: 30px;
    }
    .brand-img {
        width: 150px;
        margin: 0 auto;
    }
}
@include sm {
    .brand-row.px-100 {
        padding-left: 0;
        padding-right: 0;
    }
}

@include vxs {
    .brand-img {
        width: 120px;
    }
}