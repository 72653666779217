.z-index-step1 {
  position: relative;
  z-index: 4 !important;
}

.z-index-common {
  position: relative;
  z-index: 3;
}

.z-index-n1 {
  z-index: -1;
}


.media-body {
  flex: 1;
}


.badge {
  position: absolute;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-block;
  text-align: center;
  background-color: $theme-color;
  color: $white-color;
  padding: .35em .55em;
  border-radius: 50%;
}


.vs-surface {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.outline-social {
  --icon-size: 50px;
  --border-size: 2px;
  
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    display: inline-block;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    width: var(--icon-size);
    height: var(--icon-size);
    line-height: calc(var(--icon-size)  - var(--border-size));
    border: var(--border-size) solid $white-color;
    color: $white-color;
    font-size: 16px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;

    &:hover {
      background-color: $theme-color;
      color: $white-color;
      border-color: transparent;
    }
  }
}


.checked-list {
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    padding-left: 25px;
    font-size: 16px;
    margin-bottom: 3px;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: '\f00c';
      font-family: $icon-font;
      position: absolute;
      left: 0;
    }
  }
}