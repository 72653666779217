.tinv-wishlist {

  input[type=checkbox] {
    display: inline-block;
    opacity: 1;
    visibility: visible;
    width: auto;
    height: auto;

  }

  .cart-empty {
    padding: 12px 25px;
    background-color: #eee;
    border-radius: 5px;
    font-weight: 700;
    font-size: 14px;
  }

  p.return-to-shop .button {
    display: inline-block;
    background-color: $theme-color;
    color: #fff;
    font-size: 14px;
    padding: 10px 25px;
    border-radius: 4px;
    margin-top: 10px;
    font-weight: 700;

    &:Hover {
      background-color: $title-color;
      color: $white-color;
    }
  }
  table {
    th {
      color: $title-color;
    }
    td, th {
      padding: 10px;
      border: 1px solid var(--border-color);
      text-align: center;
    }
  }
  .product-cb,
  .product-remove {
    width: 40px;
    text-align: center;
  }
  .product-thumbnail {
    width: 110px;
  }
  .stock.in-stock {
    margin-bottom: 0;
  }
  ins {
    text-decoration: none;
  }
  .product-remove button {
    border: none;
    height: 22px;
    width: 22px;
    text-align: center;
    font-size: 12px;
    line-height: 22px;
  }
  .tinvwl-mobile {
    display: none;
  }
  .social-buttons {
    display: flex;
    max-width: 295px;
    margin-left: auto;
    align-items: center;
    ul {
      padding-left: 0;
      margin-bottom: 0;
      margin-left: auto;
    }
  }

  table.tinvwl-table-manage-list {
    font-size: 14px;
  }

  table.tinvwl-table-manage-list button {
    border-radius: 5px;
  }

  .product-stock i {
    margin-right: 5px;
  }

  .tinv-modal {
    .icon_big_times {
      margin-bottom: 5px;
      color: $theme-color;
    }
  }


  button.button {
    border: none;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    font-weight: 700;
    background-color: $theme-color;
    color: #fff;
    padding: 1px 15px;
  }

  // button.button:hover {
  //   background-color: $title-color;
  //   color: #fff;
  // }

  button.button i {
    font-size: 14px !important;
    margin-right: 3px !important;
  }

  th,
  td.product-name {
    font-size: 16px;
    font-weight: 700;
    font-family: $title-font;
  }

  td.product-name {
    a {
      color: $title-color;

      &:hover {
        color: $theme-color;
      }
    }
  }

  td.product-price del {
    font-size: 0.9em;
  }

  .social-buttons {
    >span {
      font-weight: 700;
      margin-right: 10px;
      font-family: $title-font;
      color: $title-color;
    }

    li {
      a.social {
        background-color: $theme-color;
        color: #fff;
        width: 30px;
        height: 30px;
        font-size: 14px;
        line-height: 30px;

        i {
          line-height: inherit;
        }

        &:hover {
          background-color: $title-color;
          color: $white-color;
        }
      }
    }
  }
}


@include md {
  .tinvwl-full {
    display: none;
  }
  .tinv-wishlist .tinvwl-mobile {
    display: block ;
  }
  .tinvwl-txt {
    display: none;
  }
  .product-stock {
    width: 40px;
    text-align: center;
  }
}

@include sm {
  .tinv-wishlist table {
    table-layout: fixed;
  }
  .tinv-wishlist table.tinvwl-table-manage-list tbody td.product-remove, .tinv-wishlist table.tinvwl-table-manage-list thead th:not(.product-name) {
    display: none;
  }
  .tinv-wishlist table.tinvwl-table-manage-list tbody td {
    display: block;
    width: 100% !important;
    text-align: center;
  }
  .product-name {
    text-align: center;
  }
  .tinv-wishlist table {
    td,
    th {
      border-bottom: none;
    }
    tbody {
      border-bottom: 1px solid $border-color;
    }
  }
  .tinv-wishlist .social-buttons {
    max-width: 100%;
    margin-left: unset;
    flex-direction: column;
    ul {
      margin-left: unset;
      margin-top: 5px;
    }
  }
  .tinvwl-txt {
    display: inline-block;
  }
}