#pbox1 {
    .slick-list {
        padding-bottom: 40px;
        margin-bottom: -40px;
    }
}

.vs-product-box {
    --smoke-dark: rgb(241, 241, 241);
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 20px 22px 0px rgba(0, 0, 0, 0.03);
    .product-img {
        position: relative;
    }
    .price {
        color: $theme-color;
    }
    .product-tag {
        --icon-size: 50px;
        --tag-gap: 20px;
        @include equal-size(var(--icon-size));
        line-height: 51px;
        background-color: $theme-color;
        border-radius: 50%;
        position: absolute;
        top: var(--tag-gap);
        right: var(--tag-gap);
        color: $white-color;
        font-size: 14px;
    }
      
    .product-title {
        margin-bottom: 10px;
    }
    .product-content {
        padding: 35px 20px 31px 20px;
        border: 2px solid $smoke-dark;
        border-top: none;
        border-radius: 0 0 10px 10px;
    }
    .actions-btn {
        position: absolute;
        top: 48%;
        left: 0;
        right: 0;
        visibility: hidden;
        opacity: 0;
        text-align: center;
        @include transition(.4s ease);
        .icon-btn {
            margin: 5px;
        }
    }
    &:hover {
        .actions-btn {
            margin-top: -20px;
            visibility: visible;
            opacity: 1;
        }
    }
}

// List View Style --------------------
.product-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    text-align: left;
    .icon-btn.style4 {
        --btn-size: 38px;
        font-size: 12px;
        margin: 3px;
    }
    .product-img {
        width: 100%;
        max-width: 180px;
    }
    .product-content {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        border-top: 2px solid $smoke-dark;
        border-radius: 0 10px 10px 0;
        border-left: none;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
}
 

// Shop Details -----------------------
.shop-wrap {
	padding: 80px;
}
.product-big-img {
	position: relative;
    --pos-gap: 30px;
    .slick-dots {
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        bottom: 25px;
        margin-bottom: 0;
    }
    .price {
        font-size: 30px;
        font-weight: 700;
        color: $theme-color;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        -webkit-box-align: end;
        -webkit-align-items: flex-end;
        -ms-flex-align: end;
        align-items: flex-end;
        font-family: $title-font;
        position: absolute;
        right: var(--pos-gap);
        top: var(--pos-gap);
        line-height: 1;
        del {
            margin-right: 20px;
            font-size: 0.7em;
            color: $body-color;
            line-height: 1.1;
        }
    }
    .add_to_wishlist {
        position: absolute;
        right: var(--pos-gap);
        bottom: var(--pos-gap);
        font-weight: 400;
        color: $title-color;
        line-height: 1;
        i {
            margin-right: 5px;
            color: $theme-color;
        }
        &:hover {
            color: $theme-color;
        }
    }
}

.product-thumb-img {

    .slick-current {
        .thumb {
            border-color: $theme-color;
        }
    }

    .slick-arrow {
        position: absolute;
        left: -60px;
        top: 50%;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        width: auto;
        height: auto;
        padding: 0;
        line-height: 1;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.3);
        opacity: 0;
        visibility: hidden;

        &.slick-next {
            left: auto;
            right: -60px;
        }
    }

    .slick-dots {
        margin-top: 20px;
    }
}
.product-thumb {
    width: 150px;
    max-width: 100%;
    transition: all ease 0.4s;
    cursor: pointer;
    &.slick-current {
        img {
            border: 1px solid $theme-color;
        }
    }
}

.product-big-img,
.product-thumb-img {
    .slick-arrow {
        position: absolute;
        left: -60px;
        top: 50%;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        width: auto;
        height: auto;
        padding: 0;
        line-height: 1;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.3);
        opacity: 0;
        visibility: hidden;
        z-index: 3;
        margin: 0;

        &.slick-next {
            left: auto;
            right: -60px;
        }
    }
}

.product-big-img {
    .slick-arrow {
        left: 30px;

        &.slick-next {
            left: auto;
            right: 30px;
        }
    }
}

.container {
    .row {
        &:hover {
            .product-big-img,
            .product-thumb-img {
                .slick-arrow {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}

.product-details {
    .woocommerce-product-rating {
        margin-bottom: 7px;
        .star-rating {
            display: inline-block;
            vertical-align: middle;
        }
        .woocommerce-review-link {
            color: $body-color;
            margin-left: 10px;
            font-size: 14px;
            &:hover {
                color: $theme-color;
            }
        }
    }
    .product-title {
        padding-bottom: 20px;
        border-bottom: 1px solid $border-color;
    }
    .vs-input-group {
        display: flex;
        margin-top: 10px;
        margin-bottom: 40px;
    }
    .actions {
        margin-bottom: 35px;
    }
}

.size-select {
    input[type="radio"] {
        ~ label {
            font-size: 12px;
            padding: 8px 14px;
            background-color: $white-color;
            border-radius: 999px;
            text-transform: none;
            font-weight: 400;
            -webkit-transition: all ease 0.4s;
            transition: all ease 0.4s;
            &:before {
                display: none;
            }
        }
        &:checked ~ label {
            background-color: $theme-color;
            color: $white-color;
        }
    }
}

.quantity {
    position: relative;
    display: inline-block;
    vertical-align: middle;

    input {
        width: 75px;
        height: 60px;
        text-align: center;
        border: 1px solid $smoke-color;
        font-family: $body-font;
        color: $body-color;
        font-weight: 600;
        font-size: 18px;
        padding-left: 0;
        padding-right: 30px;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        &[type="number"] {
            -moz-appearance: textfield;
        }
    }

    .qut-btn {
        position: absolute;
        top: 11px;
        right: 10px;
        padding: 0;
        line-height: 1;
        color: $body-color;
        font-size: 14px;
        border: none;
        background-color: transparent;
        &.quantity-minus {
            top: 30px;
        }
        &:hover {
            color: $theme-color;
        }
    }
}

.product_meta {
    font-weight: 400;
    font-size: 18px;
    text-transform: capitalize;
    font-family: $body-font;

    > span {
        display: block;
        margin-bottom: 3px;
        color: $title-color;

        &:last-child {
            margin-bottom: 0;
        }

        a {
            color: inherit;

            &:hover {
                color: $theme-color;
            }
        }

        > a,
        > span {
            position: relative;
            color: $body-color;

            &:after {
                content: ",";
                margin-right: 5px;
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }

            &:first-child {
                margin-left: 7px;
            }
        }
    }
}

.product-info-list {
	list-style-type: none;
	margin: 0 0 30px 0;
	padding: 0;
    li {
        font-size: 18px;
        position: relative;
        color: $body-color;
        margin-bottom: 13px;
        padding-left: 43px;
        &:before {
            content: '\f00c';
            font-family: $icon-font;
            position: absolute;
            left: 0;
            top: 0;
            @include equal-size-lineHeight(25px);
            border-radius: 50%;
            font-size: 14px;
            background-color: $white-color;
            color: $theme-color;
            box-shadow: 0px 10px 42.75px 2.25px rgba(0, 0, 0, 0.05);
            text-align: center;
        }
        span {
            color: $title-color;
            margin-right: 3px;
        }
    }
}
.vs-text-box {
	border-left: 8px solid $theme-color;
	font-style: italic;
	font-size: 18px;
	line-height: 1.6;
	padding: 48px 50px;
	margin-bottom: 30px;
}

// Tab Indicator -------------------
.product-tab {
	position: relative;
	border-bottom: 1px solid $border-color;
	margin-top: -0.2em;
    a {
        color: $title-color;
        font-size: 18px;
        font-weight: 700;
        padding: 0 20px 20px 20px;
        &:hover,
        &.active {
            color: $theme-color;
        }
    }
    .indicator {
        position: absolute;
        left: 0;
        top: auto !important;
        height: 0 !important;
        bottom: -1px;
        -webkit-transition: all ease 0.4s;
        transition: all ease 0.4s;
        border-bottom: 1px solid $theme-color;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            width: 12px;
            height: 12px;
            border-top: 1px solid $theme-color;
            border-left: 1px solid $theme-color;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            margin: -6px 0 0 -6px;
            background-color: $white-color;
        }
    }
}




.product-tab-style1 {
    border-bottom: 1px solid #e4e4e4;

    a {
        color: $title-color;
        font-size: 18px;
        font-weight: 600;
        text-transform: capitalize;
        display: inline-block;
        position: relative;
        line-height: 1;
        padding: 0 20px 10px 20px;
        font-family: $title-font;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            bottom: -1px;
            height: 1px;
            width: 0;
            background-color: $theme-color;
            transition: all ease 0.4s;
        }

        &.active {
            &:before {
                width: 100%;
            }
        }
    }
}

.product-inner-list {
    > ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    li {
        position: relative;
        padding-left: 15px;

        &:before {
            content: "-";
            position: absolute;
            left: 0;
        }
    }
}

.product-about {
    .price {
        margin-top: -0.2em;
        font-size: 30px;
        font-weight: 600;
        color: $theme-color;
        display: block;
        margin-bottom: 15px;

        del {
            color: $body-color;
            font-weight: 400;
            font-size: 0.8em;
        }
    }

    .actions {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .vs-btn {
            font-size: 16px;
            padding: 17px 28px;
        }
    }
}

.product-details .vs-comments-wrap {
    margin-top: 0;
}

.discount-tagged {
    display: inline-block;
    width: 80px;
    height: 80px;
    position: absolute;
    right: 22px;
    top: 22px;
    background-color: #ffe400;
    color: #222222;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    padding: 15px 0;
    line-height: 1.2;
    border-radius: 50%;
    z-index: 3;
}

.product-tagged {
    position: absolute;
    left: 27px;
    top: 27px;
    z-index: 1;

    a {
        font-size: 15px;
        font-weight: 600;
        text-transform: uppercase;
        background-color: #4adf3b;
        color: $white-color;
        display: block;
        width: max-content;
        min-width: 83px;
        text-align: center;
        line-height: 1;
        padding: 6px 8px;
        margin-bottom: 4px;

        &.offers {
            background-color: #ed0c0c;
        }

        &:last-child {
            margin-bottom: 0;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 100%;
                border-left: 10px solid #4adf3b;
                border-bottom: 10px solid transparent;
            }
        }
    }
}

@include ml {
    .discount-tagged {
        width: 70px;
        height: 70px;
        font-size: 16px;
        padding: 14px 0;
    }
}

@include lg {
    .shop-wrap {
        padding: 40px;
        .gx-60, .g-60 {
            --bs-gutter-x: 30px;
        }
    }
}

@include md {
    .shop-wrap {
        padding: 40px 20px;
    }
}
@include sm {
    .vs-text-box {
        padding: 30px 30px;
    }
}

@include xs {
    .product-about {
        .actions {
            display: block;

            .quantity {
                display: block;
                width: max-content;
                margin-bottom: 20px;
            }
        }
    }
}
