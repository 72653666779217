#priceBox1 {
    .slick-list {
        padding-bottom: 60px;
        margin-bottom: -60px;
    }
}
.package-card {
    --border-white: rgba(255, 255, 255, 0.3);
    --package-space: 60px;
    background-color: $white-color;
    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.04);
    @include transition(0.4s);
    .package-content {
        padding: var(--package-space);
        padding-top: 0;
        position: relative;
        z-index: 2;
        &:before {
            content: "";
            @include equal-size(100%);
            background-color: $white-color;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            @include transition(0.4s);
        }
    }
    &:hover {
        .package-content {
            &:before {
                visibility: hidden;
                opacity: 0;
            }
        }
    }
    .package-name {
        font-size: 24px;
        font-weight: 600;
        padding: 17px;
        background-color: $theme-color;
        color: $white-color;
        display: block;
    }
    .package-price {
        border-bottom: 1px solid $border-color;
        padding: 30px 0;
        margin-bottom: 30px;
        &.yearly {
            display: none;
        }
    }
    ul {
        padding: 0;
        list-style: none;
        margin-bottom: 30px;
        li {
            margin: 14px;
        }
    }
    &:hover {
        box-shadow: none;
        ul li,
        .package-price {
            color: $white-color;
        }
        .package-price {
            border-bottom: 1px solid $border-white;
        }
        .package-content {
            &:before {
                left: 100%;
            }
        }
    }
}

// Switcher-----------------------------
// toggle styling
.slideToggle {
    --icon-size: 80px;
    --toggle-size: 240px;
    --item-size: calc(var(--toggle-size) - var(--icon-size));
    display: flex;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
    width: var(--toggle-size);
    height: var(--icon-size);
    color: $title-color;
    font-size: 18px;
    font-weight: 500;
    position: relative;
    i {
        margin: 0 15px;
    }
    .beforeinputs {
        display: none;
    }
    .afterinput,
    .beforeinputs {
        text-align: center;
        width: var(--item-size);
    }
    .afterinput {
        margin-left: var(--icon-size);
    }
}
.form-switch {
    align-items: center;
    display: flex;
    cursor: pointer;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    i {
        font-size: 20px;
        color: $white-color;
        background-color: $theme-color;
        position: relative;
        display: inline-block;
        @include equal-size-lineHeight(var(--icon-size));
        text-align: center;
        padding: 0;
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.4s ease-in-out;
    }
    input {
        display: none;
        transition: 0.4s;
        &:checked + i {
            left: var(--item-size);
            &:before {
                content: "\f060";
            }
            
        }
    }
}

@include lg {
    .package-card {
        --package-space: 50px 30px;
    }
}