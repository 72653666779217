.vs-content-box1 {
    min-height: 283px;
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @include transition(0.4s);
    position: relative;
    &:before {
        content: "";
        @include equal-size(0);
        border-width: 30px 30px 0 0;
        border-color: $white-color transparent transparent transparent;
        border-style: solid;
        position: absolute;
        left: 20px;
        top: 20px;
        @include transition(0.4s);
    }
    .about-number {
        font-size: 60px;
        margin-bottom: 0;
    }
    &:hover {
        &:before {
            transform: scale(1.4);
            left: 6px;
            top: 6px;
        }
    }
}
.top-round {
    border-radius: 70% 0 0 0;
    overflow: hidden;
}
.bottom-round {
    border-radius: 0 0 70% 0;
    overflow: hidden;
}
.about-contents {
    height: inherit;
    box-shadow: 0px 20px 73px 0px rgba(87, 87, 87, 0.06);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px 70px;
}

// Achive Responsive -----------------------------------------
@include lg {
    .vs-content-box1 {
        min-height: unset;
        padding: 80px 40px 40px 40px;
        .about-number {
            font-size: 40px;
        }
    }
    .about-contents {
        padding: 20px 30px;
    }
}
@include md {
    .about-col-1,
    .about-col-2 {
        display: flex;
    }
    .about-col-2 {
        margin-top: 30px;
    }
    .vs-img-box1,
    .vs-content-box1 {
        min-height: calc(100% - 30px);
        width: 100%;
        margin-bottom: 30px;
        img {
            width: 100%;
        }
        &:first-child {
            margin-right: 30px;
        }
    }
    .about-col-2 {
        .vs-img-box1 {
            margin-bottom: 0;
        }
    }
    .about-contents {
        padding: 40px;
    }
    .vs-content-box1 .about-number {
        font-size: 36px;
    }
}
@include xs {
    .top-round {
        border-radius: 0 0 0 0;
    }
    .bottom-round {
        border-radius: 0 0 0 0;
    }
}
@include vxs {
    .about-col-1,
    .about-col-2 {
        display: block;
    }
    .about-col-2 {
        .vs-img-box1 {
            &:nth-child(1) {
                margin-bottom: 30px;
            }
        }
    }
    .about-contents {
        padding: 20px;
    }
}
