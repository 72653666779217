.vs-process {
	text-align: center;
    padding: 0 20px;
    --border-color: rgb(236, 236, 236);
    .icon-box {
        --box-size: 148px;
        --box-gap: 17px;
        position: relative;
        @include equal-size-lineHeight(var(--box-size));
        margin: 0 auto;
        margin-bottom: 35px;
        z-index: 2;
        &:before {
            content: "";
            @include equal-size(100%);
            border: 2px solid $border-color;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            @include transition(.4s);
        }
        &:after {
            content: "";
            @include equal-size(100%);
            background-color: $theme-color;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            @include transition(.4s);
            opacity: 0;
            visibility: hidden;
            z-index: -1;
        }
        .process-num {
            border-radius: 50%;
            background-color: $white-color;
            box-shadow: 0px 15px 40px 0px rgba(0, 0, 0, 0.09);
            @include equal-size-lineHeight(50px);
            text-align: center;
            font-size: 18px;
            font-weight: 500;
            position: absolute;
            top: 0;
            right: 0;
        }
        img {
            max-width: 50px;
        }
        .process-desc {
            margin-bottom: 0;
        }
        &:hover {
            &:after {
                @include equal-size(calc(100% - var(--box-gap)*2));
                top: var(--box-gap);
                left: var(--box-gap);
                opacity: 1;
                visibility: visible;
            }
            img {
               filter: brightness(0) invert(1);
            }
        }
    }
    .process-desc {
        margin-bottom: 0;
    }
}

// Responsive ------------------------------
@include lg {
    .vs-process {
        padding: 0;
    }
}
@include md {
    .vs-process {
        margin-bottom: 30px;
    }
    .col-lg-3.col-sm-6:last-child {
        .vs-process {
            margin-bottom: 0;
        }
    }
}
@include xs {
    .vs-process {
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
    }
}