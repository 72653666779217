.vs-hero-bg {
	padding: 240px 0 250px 0;
	position: relative;
	z-index: 2;
}
.hero-subtitle {
	border-bottom: 1px solid;
	padding-bottom: 8px;
	margin-bottom: 25px;
	font-size: 24px;
	display: inline-block;
}
.hero-slider1 {
	.hero-subtitle {
		border-bottom: 1px solid $smoke-color;
	}
}
.hero-desc {
	max-width: 630px;
	font-size: 18px;
	&.style-2 {
		max-width: 615px;
		font-size: 24px;
		font-weight: 500;
		line-height: 1.4;
	}
}
.hero-slider,
.hero-slider3 {
	.slick-dots {
		position: absolute;
		right: 175px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 1;
		li {
			display: block;
			margin-right: 0;
		}
	}
}
  
.number-nav {
	.slick-dots {
		li {
			button {
				border: none;
				text-indent: 0;
				color: $theme-color;
				font-size: 18px;
				font-weight: 500;
				background-color: $white-color;
				@include equal-size(58px);
				line-height: 55px;
				padding: 0;
				border-radius: 10px;
				margin-bottom: 21px;
				@include transition(.3s);
			}
			&.slick-active {
				button {
					background-color: $theme-color;
					color: $white-color;
					transform: scale(1.4);
					margin-bottom: 31px;
					margin-top: 11px;
				}
			}
		}
	}
	&.style-2 {
		.slick-dots {
			li {
				button {
					border: 2px solid;
					border-color: rgba(#fff, .36);
					color: $white-color;
					font-size: 18px;
					font-weight: 500;
					background-color: transparent;
					border-radius: 50%;
					margin-bottom: 21px;
					@include transition(.3s);
					&:hover {
						border-color: #fff;
					}
				}
				&.slick-active {
					button {
						background-color: $theme-color;
						border-color: $theme-color;
						color: $white-color;
						transform: scale(1);
						margin-bottom: 21px;
						margin-top: unset;
					}
				}
			}
		}
	}
}

// Hero 2 ------------------------------------
.hero-slider2,
.hero-slider4 {
	.vs-hero-bg {
        padding: 240px 0 383px 0;
        position: relative;
        z-index: 2;
    }
	.hero-subtitle {
		display: inline-block;
		padding-bottom: 5px;
		margin-bottom: 10px;
	}
	.hero-title {
		font-size: 96px;
	}
}
.hero-text {
	font-size: 40px;
	color: rgba(255, 255, 255, 0.26);
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 9px;
	transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	position: absolute;
	right: -75px;
	top: 42%;
	text-align: center;
}

// Hero 3 -------------------------------------
.hero-shape {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

// Hero 4 -------------------------------------
.hero-slider4 {
	.vs-hero-bg {
        padding: 340px 0 300px 0;
    }
}
.overlay-img {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	background-color: $title-color;
	background-image: url('../img/bg/overlay-bg.png');
	background-size: cover;
	background-repeat: no-repeat;
	z-index: -1;
	opacity: .8;
}

// BMI Form -----------------------------------
.bmi-wrapper {
	filter: drop-shadow(0px 15px 50px rgba(0, 0, 0, 0.05));
	position: relative;
	&:after {
		content: "";
		height: 100%;
		width: 100%;
		background-color: $white-color;
		position: absolute;
		top: 0;
		left: calc(100% - 1px);
	}
}
.bmi-negative {
	transform: translateY(-135px);
	margin-bottom: -135px;
}
.bmi-inner {
	padding: 92px 0 86px 120px;
	clip-path: polygon(0 0, 100% 0%, 100% 100%, 30px 100%);
}
.bmi-wrapper.plain-style {
	&:after {
		display: none;
	}
	.bmi-inner {
		padding: 92px 100px 86px 100px;
		clip-path: none;
	}
}
.home4-bmi {
	.bmi-negative {
		transform: translateY(-115px);
		margin-bottom: -115px;
	}
}

.sm-border-bottom {
	position: relative;
	&:before,
	&:after {
		content: "";
		height: 3px;
		width: 30px;
		background-color: $theme-color;
		position: absolute;
		bottom: -22px;
		left: 0;
	}
	&:after {
		width: 10px;
		left: 35px;
	}
}

.bmi-form {
	max-width: 640px;
	label {
		margin-top: 0;
		margin-bottom: 5px;
		text-transform: uppercase;
		font-size: 12px;
	}
	select, .form-select, .form-control {
		display: inline-block;
		border-radius: 0;
		height: 60px;
		width: 130px;
		color: $title-color;
		font-weight: 600;
	}
}

// Responsive Hero ----------------------------
@include ml {
	.vs-hero-wrapper .vs-hero-bg {
		padding: 140px 0 150px 0;
	}
	.hero-slider4 .vs-hero-bg {
		padding: 280px 0 240px 0;
	}
	.hero-slider,
	.hero-slider3 {
		.slick-dots {
			right: 100px;
		}
	}
	.number-nav .slick-dots li.slick-active button {
		transform: scale(1.1);
		margin-bottom: 20px;
		margin-top: 0;
	}
	// Hero 2 --------------
	.hero-slider2 .vs-hero-bg {
		padding: 140px 0 295px 0;
	}
	.hero-text {
		font-size: 30px;
	}
}
@include lg {
	.hero-slider,
	.hero-slider3 {
		.slick-dots {
			right: 60px;
		}
	}
	// Hero 2 --------------
	.hero-slider2 .vs-hero-bg {
		padding: 140px 0 140px 0;
	}
	.hero-slider2 .hero-title, .hero-slider3 .hero-title,  .hero-slider4 .hero-title {
		font-size: 70px;
	}
	.hero-text {
		font-size: 24px;
		letter-spacing: 5px;
		top: 46%;
	}
	.bmi-scetion {
		background-color: $white-color;
		padding: 72px 0 66px 0;
		filter: drop-shadow(0px 15px 50px rgba(0, 0, 0, 0.05));
	}
	.bmi-negative {
		transform: translateY(0);
		margin-bottom: 0;
	}
	.bmi-inner {
		padding: 0;
		clip-path: none;
	}
	.bmi-wrapper {
		filter: none;
	}
	// Home 4
	.hero-slider4 .vs-hero-bg {
		padding: 290px 0 140px 0;
	}
	.home4-bmi .bmi-negative {
		transform: translateY(0);
		margin-bottom: 0;
	}
	.bmi-wrapper.plain-style .bmi-inner {
		padding: 0;
		clip-path: none;
	}
}
@include md {
	// Hero 2 --------------
	.hero-slider2 .hero-title, .hero-slider3 .hero-title, .hero-slider4 .hero-title {
		font-size: 50px;
	}
	.hero-desc.style-2 {
		font-size: 18px;
		font-weight: 400;
	}
	.hero-slider4 .vs-hero-bg {
		padding: 220px 0 140px 0;
	}
}
@include sm {
	.vs-hero-wrapper .vs-hero-bg {
		padding: 80px 0 80px 0;
	}
	.hero-subtitle {
		padding-bottom: 1px;
		margin-bottom: 20px;
		font-size: 20px;
	}
	.hero-slider2 .hero-subtitle, .hero-slider4 .hero-subtitle {
		padding-bottom: 1px;
	}
	// Hero 2 --------------
	.hero-slider2 .vs-hero-bg {
		padding: 80px 0 80px 0;
	}
	.hero-slider2 .hero-desc {
		font-size: 18px;
	}
	.hero-text {
		display: none;
	}
	.bmi-form select, .bmi-form .form-select, .bmi-form .form-control {
		margin-bottom: 15px;
	}
	.bmi-form label {
		margin-bottom: 0;
	}
	// Home 4
	.hero-slider4 .vs-hero-bg {
		padding: 180px 0 80px 0;
	}
}
@include xs {
	.bmi-form select, .bmi-form .form-select, .bmi-form .form-control {
		width: 100%;
		height: 48px;
	}
	.hero-shape {
		display: none;
	}
	.hero-slider2 .hero-title, .hero-slider3 .hero-title, .hero-slider4 .hero-title {
		font-size: 40px;
	}
	.hero-desc {
		line-height: 1.6;
		font-size: 16px;
	}
}