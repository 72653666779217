.vs-content-box2 {
    p {
        line-height: 1.625;
        margin: 0 0 27px 0;
    }
}
.tik-list {
	list-style: none;
	padding: 0;
    display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 20px;
    width: 370px;
    li {
        font-weight: 600;
        &:before {
            content: "\f00c";
            font-family: $icon-font;
            display: inline-block;
            background-color: $white-color;
            box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.08);
            @include equal-size-lineHeight(30px);
            text-align: center;
            margin-right: 20px;
            font-weight: 300;
        }
    }
}

// Image Boxs-------------------------
.vs-img-box4 {
    position: relative;
    .vs-video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.img-box5 {
	position: relative;
    .content {
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #fff;
        padding: 40px;
        border-radius: 0 50px 0 0;
    }
      
}

// Features card ---------------------
.vs-feature-card {
    padding-top: 55px;
    .fr-number {
        margin-bottom: 22px;
    }
    .fr-name {
        display: block;
        background-color: $theme-color;
        color: $white-color;
        font-size: 18px;
        padding: 17px;
        font-weight: 500;
    }
}

// Icon Boxs -----------------------------------
.vs-icon-box {
    --icon-szie: 60px;
    [class*="arrow"] {
        @include equal-size-lineHeight(var(--icon-szie));
        background-color: $theme-color;
        color: $white-color;
        text-align: center;
        border-radius: 10px;
        border: none;
        &:hover {
            background-color: $white-color;
            color: $theme-color;
            box-shadow: 0px 20px 40px 0px rgba(34, 34, 34, 0.08);
        }
    }
    .arrow-left {
        margin-right: 20px;
    }
}

// Others ----------------------------
.two-btns {
    .vs-btn:first-child {
        margin-right: 20px;
    }
}
// Accordion ----------------
.accordion-item { 
    margin-bottom: 20px;
    --smoke-dark: rgba(238, 238, 238, 1);
    &:last-child {
        margin-bottom: 0;
    }
}
.accordion-button {
	border: none;
	background-color: $smoke-dark;
	color: $title-color;
	font-size: 20px;
	padding: 20px 40px;
	border-radius: 10px !important;
	margin-bottom: 0;
    text-align: left;
    &:focus {
        box-shadow: none;
    }
    &:after {
        content: "+";
        background-image: none;
        font-weight: 400;
        line-height: 1;
        width: auto;
    }
    &:not(.collapsed) {
        background-color: $smoke-dark;
        color: $title-color;
        border-radius: 10px 10px 0 0 !important;
        padding: 35px 40px 6px 40px;
        &:after {
            content: "-";
            background-image: none;
        }
    }
}
.accordion-collapse {
	border: none;
}
.accordion-body {
	background-color: $smoke-dark;
	border: none;
	border-radius: 0 0 10px 10px;
	padding: 5px 40px 12px 40px;
}
// Style 2--------------------------
.vs-accordion {
    --smoke-color: rgba(245, 245, 245, 1);
    border-bottom: 1px solid $border-color;
    .accordion-item {
        margin-bottom: 0;
    }
    .accordion-button {
        border: none;
        background-color: $white-color;
        color: $title-color;
        font-size: 18px;
        font-weight: 500;
        padding: 25px 0;
        border-radius: 0 !important;
        margin-bottom: 0;
        border-top: 1px solid $border-color;
        text-align: left;
        line-height: 1.556;
        &:before {
            content: "+";
            background-image: none;
            font-weight: 400;
            background-color: $smoke-color;
            color: $title-color;
            text-align: center;
            @include equal-size-lineHeight(30px);
            min-width: 30px;
            border-radius: 50%;
            margin-right: 30px;
        }
        &:after {
            display: none;
        }
        &:not(.collapsed) {
            border-radius: 0 !important;
            padding-bottom: 18px;
            &:before {
                content: "-";
                background-color: $theme-color;
                background-image: none;
                color: $white-color;
            }
        }
    }
    .accordion-body {
        background-color: $white-color;
        border: none;
        border-radius: 0;
        padding: 0 0 10px 60px;
    }
}

// Number Box -------------------------
.vs-num-card {
    padding: 20px 60px 20px 60px;
    height: 168px;
    border: 2px solid rgb(241, 241, 241);
    background-color: $white-color;
    box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon-box {
        width: 55px;
        margin-right: 35px;
    }
    .number {
        margin-bottom: 0;
    }
    .num-title {
        white-space: nowrap;
    }
}

// Session Card -------------------------------
.vs-session-card {
	background-color: $smoke-color;
	padding: 40px 60px 45px 60px;
    border: 2px solid $smoke-color;
    @include transition(.4s);
    .sn-title {
        font-weight: 500;
    }
    .sn-time {
        margin-bottom: 17px;
    }
    .sn-btn {
        color: $title-color;
        font-weight: 600;
        border-bottom: 2px solid $body-color;
        i {
            @include transition(.3s);
        }
        &:hover {
            color: $theme-color;
            border-color: $theme-color;
            i {
                margin-left: 3px;
            }
        }
    }
    &:hover {
        border-color: rgb(241, 241, 241);
        background-color: $white-color;
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.08);
    }
}

// CTA Section -------------------------------
.cta-desc.fs-24 {
	max-width: 700px;
    line-height: 1.47;
}

// Responsive -------------------------
@include md {
    .vs-session-card {
        padding: 30px 40px 35px 40px;
    }
    .vs-num-card {
        margin-bottom: 30px;
    }
    .col-lg-4.col-md-6:last-child {
        .vs-num-card {
            margin-bottom: 0;
        }
    }
    .vs-img-box3 {
        margin-left: -45px;
    }
    .img-box5 {
        margin-bottom: 40px;
    }
    .vs-content-box2.pl-50 {
        padding-left: 0;
    }
    .md-center {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    .mb-unset {
        margin-bottom: unset !important;
        padding-bottom: $space-mobile !important;
    }
    .pt-unset {
        padding-top: unset !important;
    }
}

@include sm {
    .vs-img-box3 {
        margin-left: -35px;
    }
    .vs-num-card .number {
        font-size: 36px;
    }
    .accordion-button {
        padding: 15px 20px;
        &:not(.collapsed) {
            background-color: $smoke-dark;
            color: $title-color;
            border-radius: 10px 10px 0 0 !important;
            padding: 15px 20px 10px 20px;
            &:after {
                content: "-";
                background-image: none;
            }
        }
    }
    .accordion-body {
        border-radius: 0 0 10px 10px;
        padding: 0 20px 5px 20px;
        p {
            margin-bottom: 10px;
        }
    }
    .img-box5 .content {
        border-radius: 0 40px 0 0;
    }
    .vs-num-card {
        padding: 20px 40px 20px 40px;
        height: 130px;
    }
}
@include xs {
    .vs-img-box3 {
        margin-left: -25px;
    }
    .vs-accordion .accordion-button::before {
        min-width: 22px;
        @include equal-size-lineHeight(22px);
        margin-right: 20px;
    }
    .vs-accordion .accordion-button {
        font-size: 16px;
    }
    .vs-accordion .accordion-body {
        padding: 0 0 10px 40px;
    }
}
@include vxs {
    .tik-list {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;
        margin-bottom: 50px;
    }
    .two-btns {
        .vs-btn {
            max-width: 200px;
            display: block;
            &:first-child {
                margin-bottom: 20px;
            }
        }
    }
    .text-center {
        .two-btns {
            .vs-btn {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    .vs-session-card {
        padding: 30px 30px 35px 30px;
    }
}
