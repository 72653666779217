/*
Template Name: Gimia
Template URL: https://angfuzsoft.com/themeforest/html/gimia/
Description: Gimia - Gym & Yoga Services HTML Template
Author: Angfuzsoft
Author URI: https://themeforest.net/user/angfuzsoft
Version: 1.0.0
*/
/*=================================
    CSS Index Here
==================================*/
/*

01. Theme Base
    1.1. Variable
    1.2. Mixin
    1.3. Function
    1.4. Typography
02. Reset
    2.1. Container
    2.2. Grid
    2.3. Input
    2.4. Slick Slider
    2.5. Mobile Menu
03. Utilities
    3.1. Preloader
    3.2. Buttons
    3.3. Titles
    3.4. Common
    3.6. Font
    3.7. Background
    3.8. Text Color
    3.9. Overlay
    3.10. Animation
04. Spacing
05. Template Style
    5.1. Widget
    5.2. Header
    5.3. Footer
    5.4. Breadcumb
    5.5. Blog
    5.6. Comments
    5.7. Hero Area
    5.8. Service
    5.9. Products
    5.10. Project
    5.11. Appointment
    5.12. Brand
    5.13. Components
    5.14. Schedule
    5.15. Process
    5.16. Gallery
    5.17. About
    5.18. Price Plan
    5.19. Subscribe
    5.20. Team
    5.21. Testimonial
    5.22. Simple Sections
    5.23. Counter
    5.24. Woocommerce

*/
/*=================================
    CSS Index End
==================================*/


/*=================================
   01. Theme Base
==================================*/
/*------------------- 1.1. Variable-------------------*/
@import 'base/variable';

/*------------------- 1.2. Mixin -------------------*/
@import 'base/mixin';

/*------------------- 1.3. Function -------------------*/
@import 'base/function';

/*------------------- 1.5. Typography -------------------*/
@import 'base/typography';


/*=================================
    02. Reset
==================================*/
/*------------------- 2.1. Container -------------------*/
@import 'reset/container';

/*------------------- 2.2. Grid -------------------*/
@import 'reset/grid';

/*------------------- 2.3. Input -------------------*/
@import 'reset/input';

/*------------------- 2.4. Slick Slider -------------------*/
@import 'reset/slick-slider';

/*------------------- 2.5. Mobile Menu -------------------*/
@import 'reset/vsmenu';

/*=================================
    03. Utilities
==================================*/
/*------------------- 3.1. Preloader -------------------*/
@import 'utilities/preloader';

/*------------------- 3.2. Buttons -------------------*/
@import 'utilities/btns';

/*------------------- 3.3. Titles -------------------*/
@import 'utilities/titles';

/*------------------- 3.4. Common -------------------*/
@import 'utilities/common';

/*------------------- 3.6. Font -------------------*/
@import 'utilities/font';

/*------------------- 3.7. Background -------------------*/
@import 'utilities/background';

/*------------------- 3.8. Text Color -------------------*/
@import 'utilities/text-color';

/*------------------- 3.9. Overlay -------------------*/
@import 'utilities/overlay';

/*------------------- 3.10. Animation -------------------*/
@import 'utilities/animation';

/*=================================
    04. Spacing
==================================*/
@import 'spacing/spacing';

/*=================================
    05. Template Style
==================================*/
/*------------------- 5.1. Widget  -------------------*/
@import 'template/widgets/widget-default-list';
@import 'template/widgets/widget-sidebar';
@import 'template/widgets/widget-footer';
@import 'template/widgets/widget-cart';


/*------------------- 5.2. Header  -------------------*/
@import 'template/headers/header';

/*------------------- 5.3. Footer  -------------------*/
@import 'template/footers/footer';


/*------------------- 5.4. Breadcumb  -------------------*/
@import 'template/breadcumb/breadcumb-v1';

/*------------------- 5.5. Blog  -------------------*/
@import 'template/sections/blog';

/*------------------- 5.6. Comments  -------------------*/
@import 'template/sections/comments';

/*------------------- 5.7. Hero Area  -------------------*/
@import 'template/sections/hero';

/*------------------- 5.8. Service  -------------------*/
@import 'template/sections/service';

/*------------------- 5.9. Products  -------------------*/
@import 'template/sections/products';

/*------------------- 5.10. Project  -------------------*/
@import 'template/sections/project';

/*------------------- 5.11. Appointment  -------------------*/
@import 'template/sections/appointment';

/*------------------- 5.12. Brand  -------------------*/
@import 'template/sections/brand';

/*------------------- 5.13. Components  -------------------*/
@import 'template/sections/vs-components';

/*------------------- 5.14. Schedule  -------------------*/
@import 'template/sections/schedule';

/*------------------- 5.15. Process  -------------------*/
@import 'template/sections/process';

/*------------------- 5.16. Gallery  -------------------*/
@import 'template/sections/gallery';

/*------------------- 5.17. About  -------------------*/
@import 'template/sections/about';

/*------------------- 5.18. Price Plan  -------------------*/
@import 'template/sections/price-plan';

/*------------------- 5.19. Subscribe  -------------------*/
@import 'template/sections/subscribe';

/*------------------- 5.20. Team  -------------------*/
@import 'template/sections/team';

/*------------------- 5.21. Testimonial  -------------------*/
@import 'template/sections/testimonial';

/*------------------- 5.22. Simple Sections  -------------------*/
@import 'template/sections/simple-sections';

/*------------------- 5.23. Counter  -------------------*/
@import 'template/sections/counter';

/*------------------- 5.24. Woocommerce  -------------------*/
@import 'template/sections/woocommerce';

/*------------------- 5.24. Woocommerce  -------------------*/
@import 'template/sections/cart';

/*------------------- 5.24. Woocommerce  -------------------*/
@import 'template/sections/checkout';

/*------------------- 5.24. Woocommerce  -------------------*/
@import 'template/sections/wishlist';



