.footer-widget {
  &,
  .widget {
    padding: 0;
    border: none;
    padding-bottom: 0;
    background-color: transparent;
  }
  p {
    color: $white-color;
    line-height: 1.6;
  }
  .widget_title {
    font-size: 24px;
    color: $white-color;
    position: relative;
    padding: 0;
    margin-bottom: 32px;
  }
  .footer-table {
    margin-top: -7px;
  }
  .menu {
    margin-top: -7px;
  }

  .vs-social {
    a {
      background-color: rgba(39, 71, 125, .3);
      margin-right: 8px;
      &:hover {
        background-color: $theme-color;
      }
    }
  }
  &.widget_nav_menu,
  &.widget_meta,
  &.widget_pages,
  &.widget_archive,
  &.widget_categories,
  & {
    a {
      color: $white-color;
      font-weight: 600;
      &:hover {
        color: $theme-color;
      }
    }
    li {
      a {
        font-weight: 500;
        &:before {
          content: "\f30b";
          font-family: var(--icon-font);
          margin-right: 10px;
        }
        &:hover {
          transform: translateX(5px);
        }
      }
    }
  }
  .vs-gallery-widget {
    max-width: 340px;
  }
  .footer-location {
    font-weight: 600;
    i {
      width: 25px;
      line-height: 1.6;
      float: left;
      display: block;
      overflow: hidden;
      height: 50px;
      padding-left: 2px;
    }
  }
  .menu-two-collumn ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    li {
      margin-bottom: 0;
    }
  }
}

.footer-table {
  font-weight: 600;
  color: $white-color;
  tr {
    td {
      border-bottom: 1px solid;
      border-color: $border-white;
      border-top: none;
      padding-top: 10px;
      padding-bottom: 10px;
      &:nth-child(2) {
        text-align: right;
      }
    }
    &:last-child td {
      padding-bottom: 10px !important;
    }
  }
}


// Footer Style 2 -------------------------------------
.footer-widget {
  &.style-2 {
    .widget_title {
      font-size: 30px;
      margin-bottom: 35px;
      color: $title-color;
    }
    p {
      color: $body-color;
      font-size: 18px;
      font-weight: 400;
      margin-top: -7px;
    }
    .widget_nav_menu a, .widget_meta a, .widget_pages a, .widget_archive a, .widget_categories a, a {
      color: $body-color;
      font-weight: 600 !important;
      &:hover {
        color: $theme-color;
      }
    }
    .post-title {
      margin-bottom: 0;
      a {
        font-weight: inherit !important;
        color: inherit;
        &:hover {
          color: $theme-color;
        }
      }
    }
    .vs-social {
      a {
        background-color: transparent;
        &:hover {
          background-color: $theme-color;
        }
      }
    }
  }
}


@include lg {
  .widget-area.space-negative, .widget-area.space-negative-bottom {
    padding-bottom: calc(var(--section-space) - 90px);
  }
  .footer-widget, .footer-widget .widget {
    margin-bottom: 40px;
  }
}

@include md {
  .widget-area.space-negative, .widget-area.space-negative-bottom {
    padding-bottom: calc(var(--section-space-mobile) - 40px);
  }
  .footer-widget .widget_title {
    margin-bottom: 25px;
  }
}

@include xs {
  .footer-widget.style-2 p {
    font-size: 16px;
  }
}