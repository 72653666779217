.widget_nav_menu,
.widget_meta,
.widget_pages,
.widget_archive,
.widget_categories {

  ul {
    list-style: none;
    // margin: 0 0 -10px 0;
    margin: 0;
    padding: 0;
  }

  a {
    display: block;
    color: $body-color;
    font-weight: 500;

    &:hover {
      color: $theme-color;
    }
  }

  li {
    display: block;
    position: relative;
    margin-bottom: 7px;
    &:last-child {
      margin-bottom: 0;
    }
    >span {
      text-align: center;
      position: absolute;
      right: 22px;
      top: 6px;
      transition: all ease 0.4s;
    }

    &:hover {
      > span {
        color: $theme-color;
      }
    }
  }

  .children {
    margin-left: 10px;
  }
}

.widget_nav_menu,
.widget_meta,
.widget_pages {
  a {
    padding-right: 20px;
  }
}


.widget_nav_menu {
  .sub-menu {
    margin-left: 10px;
  }
}

.wp-block-archives {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;

  a:not(:hover) {
    color: inherit;
  }
}

.vs-blog ul.wp-block-archives li {
  margin: 5px 0;
}
