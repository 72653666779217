.vs-project-card {
    position: relative;
    --project-space: 30px;
    .project-content {
        padding: 40px;
        position: absolute;
        bottom: 0;
        left: 0;
        box-shadow: 0px 15px 42.75px 2.25px rgba(39, 71, 125, 0.07);
        width: 100%;
        height: auto;
        display: flex;
        align-items: flex-end;
        opacity: 0;
        visibility: hidden;
        @include transition(.4s);
    }
    .icon-btn {
        background-color: $theme-color;
        color: $white-color;
        box-shadow: 0px 10px 30px 0px rgba(246, 60, 37, 0.25);
        margin-left: auto;
        &:hover {
            background-color: $title-color;
        }
    }
    &:hover {
        .project-content {
            width: calc(100% - var(--project-space)*2);
            bottom: var(--project-space);
            left: var(--project-space);
            opacity: 1;
            visibility: visible;
        }
    }
}
.project-menu {
    button.vs-btn {
        color: $body-color;
    }
}

// Project Responsive -----------------------
@include xl {
    .vs-project-card  {
        --project-space: 20px;
    }
}
@include ml {
    .vs-project-card .project-content {
        padding: 20px;
        .project-title {
            font-size: 20px;
        }
    }
}
@include xs {
    .project-menu button {
        margin-bottom: 10px;
    }
    .project-section {
        .container-fluid.px-30 {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}