.form-select {
    display: block;
    width: 100%;
    line-height: 1.5;
    vertical-align: middle;
    background-image: url("data:image/svg+xml;utf8,<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='sort-down' class='svg-inline--fa fa-sort-down fa-w-10' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path fill='grey' d='M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z'></path></svg>");
    background-repeat: no-repeat;
    background-position: right 24px top 18px !important;
    background-size: 16px 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

select,
.form-select,
.form-control {
    height: 60px;
    padding: 0 28px;
    border: 2px solid rgba(39, 71, 125, .1);
    color: $body-color;
    background-color: transparent;
    border-radius: 7px;
    font-weight: 400;
    font-size: 14px;
    &:focus {
        outline: 0;
        box-shadow: none;
        border-color: rgb(255, 51, 51);
    }

    @include inputPlaceholder {
        color: inherit;
    }
}
.form-select {
    cursor: pointer;
    background-position: right 1.3rem center;
}

input.form-control::-webkit-outer-spin-button,
input.form-control::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input.form-control[type="number"] {
    -moz-appearance: textfield;
}

textarea.form-control {
    min-height: 150px;
    padding-top: 17px;
    padding-bottom: 17px;
}

input[type="checkbox"] {
    visibility: hidden;
    opacity: 0;
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    display: none;

    &:checked {
        ~ label {
            &:before {
                content: "\f00c";
                color: $theme-color;
                border-color: $theme-color;
            }
        }
    }

    ~ label {
        position: relative;
        padding-left: 30px;
        cursor: pointer;
        display: block;

        &:before {
            content: "";
            font-family: $icon-font;
            font-weight: 700;
            position: absolute;
            left: 0;
            top: 4px;
            background-color: $white-color;
            border: 1px solid $border-color;
            height: 18px;
            width: 18px;
            line-height: 18px;
            text-align: center;
            font-size: 12px;
        }
    }
}

input[type="radio"] {
    visibility: hidden;
    opacity: 0;
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    display: none;

    ~ label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 1;
        display: inline-block;
        font-weight: 600;
        margin-bottom: 0;

        &::before {
            content: "\f111";
            position: absolute;
            font-family: $icon-font;
            left: 0;
            top: 1px;
            width: 15px;
            height: 15px;
            padding-left: 0.5px;
            font-size: 0.29rem;
            line-height: 13px;
            text-align: center;
            border: 1px solid $theme-color;
            border-radius: 100%;
            font-weight: 700;
            background: $white-color;
            color: transparent;
            transition: all 0.2s ease;
        }
    }

    &:checked {
        ~ label {
            &::before {
                border-color: $theme-color;
                background-color: $theme-color;
                color: $white-color;
            }
        }
    }
}

label {
    margin-bottom: 0.6em;
    margin-top: -0.3em;
    display: block;
}

.form-group {
    position: relative;
}

.form-group > i {
    position: absolute;
    right: 40px;
    top: 22px;
    font-size: 18px;
    color: $body-color;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    border: 1px solid $error-color !important;
    background-position: right calc(0.375em + 0.8875rem) center;
    background-image: none;

    &:focus {
        outline: 0;
        box-shadow: none;
    }
}

textarea.form-control.is-invalid {
    background-position: top calc(0.375em + 0.5875rem) right
        calc(0.375em + 0.8875rem);
}

.form-select.is-invalid, .was-validated .form-select:invalid {
	border-color: #dc3545;
	background-image: url("data:image/svg+xml;utf8,<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='sort-down' class='svg-inline--fa fa-sort-down fa-w-10' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path fill='currentColor' d='M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z'></path></svg>");
    border-width: 1px;

}

.row.no-gutters > .form-group {
    margin-bottom: 0;
}

.form-messages {
    display: none;

    &.mb-0 * {
        margin-bottom: 0;
    }

    &.success {
        color: $success-color;
        display: block;
    }

    &.error {
        color: $error-color;
        display: block;
    }
}
@include lg {
    .form-select,
    .form-control {
        padding: 0 20px;
    }
    .form-select {
        background-position: right 20px top 20px !important;
    }
}

@include xs {
    .form-select,
    .form-control {
        padding-right: 30px;
        padding-left: 15px;
    }
}
