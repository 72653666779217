.title-area {
    line-height: 1;
}
.sub-title {
    line-height: 1;
    display: inline-block;
    font-size: 18px;
    color: $theme-color;
    font-family: $subtitle-font;
    font-weight: 500;
    margin-bottom: 27px;
    position: relative;
    &:before,
    &:after {
        content: "";
        height: 2px;
        width: 25px;
        position: absolute;
        background-color: $theme-color;
        top: 8px;
    }
    &:before {
        right: calc(100% + 20px);
        background-image: linear-gradient(to left, $theme-color, transparent 130%);
        background-color: transparent;
    }
    &:after {
        left: calc(100% + 20px);
        background-image: linear-gradient(to right, $theme-color, transparent 130%);
        background-color: transparent;
    }
    &.style-2 {
        margin-bottom: 15px;
    }
    // Style 3 --------------
    &.style-3 {
        margin-bottom: 15px;
        margin-left: 47px;
        margin-top: -4px;
        display: block;
        &:after {
            display: none;  
        }
        &:before {
            background-color: $theme-color;
        }
    }
}

.sec-title {
    font-weight: 600;
    margin: -0.25em 0 27px 0;
}
// Style 2
.sec-title2 {
    font-size: 60px;
}
.sec-desc2 {
	font-size: 18px;
	font-weight: 500;
}


.title-area {
    margin-bottom: calc(var(--section-title-space) - 13px);
}

.sec-icon {
    margin-bottom: 36px;
    line-height: 1;

    img {
        margin-top: -0.2em;
    }
}

.inner-title {
    font-size: 40px;
    margin-top: -0.25em;
}

.border-title {
	position: relative;
	padding-bottom: 20px;
	margin-bottom: 30px;
	margin-top: -0.2em;
    &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 50px;
        height: 3px;
        background-color: $theme-color;
    }
    &.style2 {
        padding-bottom: 10px;
        margin-bottom: 20px;
    }
}
h3.border-title {
    margin-bottom: 50px;
}


@include lg {
    .sec-title {
        font-size: 36px;
    }

    .sub-title {
        font-size: 16px;
        &:before,
        &:after {
            top: 7px;
        }
    }

    .inner-title {
        font-size: 28px;
    }
    .sec-title2 {
        font-size: 44px;
    }
    .sec-desc2 {
        font-size: 16px;
    }
}

@include md {
    .sec-title {
        font-size: 30px;
    }
    .sub-title {
        font-size: 14px;
        &:before,
        &:after {
            top: 6px;
        }
    }
    .sec-title2 {
        font-size: 36px;
    }
    .sec-desc2 {
        font-size: 14px;
    }
}

@include sm {
    .sec-title {
        font-size: 26px;
    }
    .sec-title2 {
        font-size: 30px;
    }
    .sub-title {
        &:before {
            right: calc(100% + 20px);
        }
        // Style 3 --------------
        &.style-3 {
            margin-left: 37px;
            &:before {
                right: calc(100% + 10px);
            }
        }
    }
}
 