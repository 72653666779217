.space-minus-60 {
  --section-space: calc(150px - 60px);
}
.space-plus-60 {
  --section-space: calc(150px + 60px);
}
.space-original {
  --section-space: 150px;
}
.space,
.space-top {
  padding-top: $space;
}

.space,
.space-bottom {
  padding-bottom: $space;
}

.space-bottom-double {
  padding-bottom: $double-space;
}

.space-negative,
.space-negative-top {
  padding-top: $space-negative;
}

.space-negative,
.space-negative-bottom {
  padding-bottom: $space-negative;
}
.full-negative-space {
  margin-top: $full-negative;
  position: relative;
  z-index: 2;
}
.bottom-minus-60 {
  padding-bottom: calc(150px - 60px);
}
.trb-60 {
  transform: translateY(60px);
}

.top-negative-90 {
  margin-top: -85px;
  padding-top: 85px;
}
.mt-65 {
  margin-top: 65px;
}

@include md {
  .space,
  .space-top {
    padding-top: $space-mobile;
  }
  .space,
  .space-bottom {
    padding-bottom: $space-mobile;
  }
  .space-negative,
  .space-negative-top {
    padding-top: $space-mobile;
  }

  .space-negative,
  .space-negative-bottom {
    padding-bottom: $space-mobile;
  }
  .space-top-md-none {
    padding-top: 0;
  }
  .full-negative-space {
    margin-top: calc(0px - var(--section-space-mobile));
  }
  .space-bottom-double {
    padding-bottom: calc(var(--section-space-mobile) + var(--section-space-mobile));
  }
  .space-plus-60 {
    --section-space-mobile: calc(80px + 60px);
  }
  .space-minus-60 {
    --section-space-mobile: calc(80px - 60px);
  }
  .space-original {
    --section-space-mobile: 80px;
  }
  .mb-md-30 {
    margin-bottom: 30px;
  }
}
