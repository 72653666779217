.subscribe-box {
  position: relative;
  --btn-size: 60px;

  input {
    border: none;
    background-color: $white-color;
    height: 90px;
    border-radius: 0;
    padding-left: 49px;
    
    @include inputPlaceholder {
      color: $title-color;
    }
    
    &:focus {
      background-color: $white-color;
    }
  }

  button {
    position: absolute;
    right: 30px;
    top: 50%;
    width: var(--btn-size);
    height: var(--btn-size);
    transform: translateY(-50%);
    font-size: 22px;
    color: $theme-color;
    border: 2px solid $theme-color;
    background-color: transparent;
    overflow: hidden;

    .default {
      opacity: 1;
      visibility: visible;
      transition: all ease 0.4s;
    }

    .hidden {
      position: absolute;
      left: 140%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      visibility: hidden;
      transition: all ease 0.4s;
      z-index: 2;
    }
  }

  &.focus {
    button {
      .hidden {
        left: 50%;
        opacity: 1;
        visibility: visible;
      }
      .default {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}


.subscribe-shape {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  max-width: 100%;
  width: 1345px;
  height: 564px;
  max-height: 100%;
}

@include xl {
  .subscribe-shape {
    width: 1000px;
  }
}

@include lg {
  .subscribe-box.ms-xl-1.mt-40 {
    margin-top: 0;
  }

  .subscribe-shape {
    width: 800px;
    height: 508px;
  }
}

@include md {
  .subscribe-shape {
    width: 100%;
    height: 100%;
  }

  .subscribe-layout1 {
    padding-bottom: 80px;
  }

  .subscribe-box {
    --btn-size: 50px;
    
    input {
      height: 70px;
      padding-left: 30px;
    }

    button {
      right: 12px;
      font-size: 16px;
    }
  }
}

