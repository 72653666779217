.preloader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  background-color: $white-color;

  .vs-btn {
    padding: 15px 20px;
    border-radius: 0;
    font-size: 14px;
  }
}

.preloader-inner {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  img {
    display: block;
    margin: 0 auto 10px auto;
  }
}

.preload-logo {
	position: relative;
	height: 100px;
  width: 100px;
  z-index: 2;
}
.preload-bg {
	position: absolute;
	top: 0;
  left: 0;
	animation: preloadAni 5s linear infinite;
  -webkit-animation: preloadAni 5s linear infinite;
  -moz-animation: preloadAni 5s linear infinite;
	transform: translate(-50%, -50%);
}
.preload-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@keyframes preloadAni {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes preloadAni {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}