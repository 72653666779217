.footer-table {
  td {
    padding: 4px 0;
    text-transform: uppercase;
  }

  tr {
    &:first-child {
      td {
        padding-top: 0;
      }
    }

    &:last-child {
      td {
        padding-bottom: 0;
      }
    }
  }
}
.copyright-wrap {
  padding: 22px 40px;
}
.footer-wrapper2.bg-smoke {
  --smoke-color: rgb(247, 247, 247);
}
.copyright-wrap.bg-smoke.style-2 {
  --smoke-color: rgb(238, 238, 238);
  a {
    font-weight: 600;
  }
}

.footer-subscribe {
  position: relative;

  input {
    width: 100%;
    background-color: transparent;
    border: 2px solid $white-color;
    padding-left: 30px;
    padding-right: 50px;
    border-radius: 9999px;
    color: $white-color;
    font-size: 16px;
    font-weight: 500;

    @include inputPlaceholder {
      color: $white-color;
    }

    &:focus {
      background-color: transparent;
    }
  }

  button {
    border: none;
    background-color: transparent;
    color: $white-color;
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    border-left: 1px solid $white-color;
    padding: 0 0 0 20px;
    height: 30px;
    overflow: hidden;

    .hidden {
      position: absolute;
      margin-left: 100px;
      opacity: 0;
      visibility: hidden;
      transition: all ease 0.4s;
    }
    
    .default {
      opacity: 1;
      visibility: visible;
      transition: all ease 0.3s;
    }
  }
  
  
  &.focus {
    margin-right: 0;

    button {
      .hidden {
        margin-left: 0;
        opacity: 1;
        visibility: visible;
      }
      
      .default {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}


.footer-links {
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  li {
    position: relative;
    display: inline-block;
    margin-left: 24px;
    &:last-child {
      margin-right: 0;
      padding-right: 0;
    }
  }

  a {
    color: $white-color;
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }
}

@include md {
  .footer-wrapper.space-negative, .space-negative-top {
    padding-top: var(--section-space-mobile) !important;
  }
  .recent-post .post-title {
    font-size: 16px;
  }
}
@include sm {
  .footer-links li {
    margin-left: 15px;
  }
}

@include xs {
  .footer-subscribe {
    input {
      padding-left: 25px;
    }
    
    button {
      width: 45px;
      height: 45px;
      background-color: $theme-color;
      color: $white-color;
      text-align: center;
      padding-left: 0;
      border: none;
      border-radius: 50%;
      right: 8px;

      &:hover {
        background-color: $white-color;
        color: $white-color;
      }
    }
  }
}