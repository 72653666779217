.row {
	--bs-gutter-x: 30px;
}
.slick-track>[class*=col] {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x)/ 2);
  padding-left: calc(var(--bs-gutter-x)/ 2);
  margin-top: var(--bs-gutter-y);
}

.gx-200,
.g-200 {
  --bs-gutter-x: 200px;
}
.gy-200,
.g-200 {
  --bs-gutter-y: 200px;
}

.gx-60,
.g-60 {
  --bs-gutter-x: 60px;
}
.gy-60,
.g-60 {
  --bs-gutter-y: 60px;
}
.gx-50,
.g-50 {
  --bs-gutter-x: 50px;
}
.gy-50,
.g-50 {
  --bs-gutter-y: 50px;
}
.gx-40,
.g-40 {
  --bs-gutter-x: 40px;
}
.gy-40,
.g-40 {
  --bs-gutter-y: 40px;
}
.gx-30,
.g-30 {
  --bs-gutter-x: 30px;
}
.gy-30,
.g-30 {
  --bs-gutter-y: 30px;
}
.gx-20,
.g-20 {
  --bs-gutter-x: 20px;
}
.gy-20,
.g-20 {
  --bs-gutter-y: 20px;
}
.gx-12,
.g-12 {
  --bs-gutter-x: 12px;
}
.gy-12,
.g-12 {
  --bs-gutter-y: 12px;
}
.gx-10,
.g-10 {
  --bs-gutter-x: 10px;
}
.gy-10,
.g-10 {
  --bs-gutter-y: 10px;
}

.gx-0,
.g-0 {
  --bs-gutter-x: 0;
}
.gy-0,
.g-0 {
  --bs-gutter-y: 0;
}
.px-100 {
	padding-right: 100px;
	padding-left: 100px;
}
@media (min-width: $xl) {
  .gx-70 {
    --bs-gutter-x: 70px;
  }
}

@media (min-width: $ml) {
  .gx-20 {
    --bs-gutter-x: 20px;
  }

  .gx-30 {
    --bs-gutter-x: 30px;
  }
  
  .gx-40 {
    --bs-gutter-x: 40px;
  }

  .gx-50 {
    --bs-gutter-x: 50px;
  }

  .gx-60 {
    --bs-gutter-x: 60px;
  }
}


@include lg {
  .gx-200, .g-200 {
    --bs-gutter-x: 80px;
  }
  .gx-60,
  .g-60 {
    --bs-gutter-x: 30px;
  }
  .gy-60,
  .g-60 {
    --bs-gutter-y: 30px;
  }
}

@include md {
  .gx-200,
  .g-200 {
    --bs-gutter-x: 50px;
  }
  .gy-200,
  .g-200 {
    --bs-gutter-y: 50px;
  }
}