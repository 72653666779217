.schedule-table {
    --smoke-color: rgb(247, 247, 247);
    text-align: center;
    border-collapse: unset;
    margin-bottom: 0;
    th {
        background-color: $theme-color;
        padding: 20px;
        color: $white-color;
        border-right: 1px solid $border-white;
        margin-bottom: 0;
    }
    td {
        position: relative;
        padding: 65px 10px 32px 10px;
        border-top: none;
        min-width: 156px;
    }
    tr {
        &:last-child {
            td {
                border-bottom: 1px solid $smoke-color;
            }
        }
        td {
            &:first-child {
                border-left: 1px solid $smoke-color;
            }
            &:last-child {
                border-right: 1px solid $smoke-color;
            }
        }
    }
    .open {
        background-color: $smoke-color;
    }
    .closed {
        background-color: $white-color;
        height: 100%;
    }
    .schedule-time {
        font-size: 18px;
        font-weight: 600;
        display: inline-block;
        padding: 7px 33px;
        border-radius: 0 0 20px 20px;
        color: $theme-color;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: $white-color;
        box-shadow: 0px 13px 30px 0px rgba(98, 98, 98, 0.04);
    }
    img {
        margin-bottom: 10px;
    }
    .work-title {
        margin-bottom: 3px;
    }
}

// Table Responsive -----------------------------
@include md {
    .table-wrapper {
        overflow-x: scroll;
    }
}