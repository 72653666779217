.team-card {
    position: relative;
    margin-bottom: 120px;
    --team-space : 40px;
    --icon-size : 45px;
    @include transition(.4s);
    .team-content {
        padding-top: 35px;
        width: calc(100% - var(--team-space)*2);
        margin-left: var(--team-space);
        @include transition(.4s);
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.02);
        position: absolute;
        top: 100%;
        left: 0;
    }
    .team-desig {
        margin-bottom: 15px;
        display: block;
    }
    .team-social {
        height: 20px;
        visibility: hidden;
        opacity: 0;
        @include transition(.4s);
        a {
            i {
                @include equal-size-lineHeight(var(--icon-size));
                text-align: center;
                background-color: $smoke-color;
                color: $body-color;
                margin: 0 5px;
                @include transition(.3s);
                &:hover {
                    background-color: $theme-color;
                    color: $white-color;
                }
            }
        }
    }
    &:hover {
        .team-content {
            transform: translateY(-55px);
        }
        .team-social {
            height: var(--icon-size);
            visibility: visible;
            opacity: 1;
        }
    }
}

// Team Style 2 ----------------------------------
.vs-team-card2 {
    --team-space: 40px;
    position: relative;
    .team-content {
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        padding: var(--team-space);
        display: flex;
        align-items: flex-end;
    }
    .team-desig {
        display: inline-block;
        color: $white-color;
        background-color: $theme-color;
        padding: 7px 30px;
    }
    .team-name {
        padding: 15px 30px;
        background-color: $white-color;
        margin-bottom: 0;
        min-width: 214px;
    }
}

// Team Details ----------------------------------
.team-schedule {
	padding: 60px 60px 58px 60px;
	position: relative;
	margin-bottom: 70px;
    .vs-btn {
        box-shadow: 0px 15px 65px 0px rgba(39,71,125,0.06);
        background-color: $white-color;
        color: $theme-color;
        font-size: 30px;
        --icon-shape: 60px;
        --icon-size: 44px;
        padding: 0 50px;
        line-height: 80px;
        height: 80px;
        margin-bottom: -40px;
        position: absolute;
        left: calc(50% - 15px);
        bottom: 0;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        display: inline-block;
        white-space: nowrap;
        border-radius: 100px;
        overflow: visible;
        i {
            color: $white-color;
            font-size: 1.1rem;
        }
    }
}

.team-schedule-table {
    margin-top: -0.5em;
    td {
        padding-left: 0;
        border-top: none;
        border-bottom: 1px dashed rgb(223, 223, 224);
        &:last-child {
            text-align: right;
        }
    }
    tr {
        &:first-child {
            td {
                padding-top: 0;
            }
        }
    }
}
  

.member-table {
	margin-top: 60px;
	margin-bottom: 60px;
    th {
        color: $title-color;
    }
    th, td {
        font-size: 14px;
        border: none;
        padding: 20px 30px;
        vertical-align: top;
    }
    tr {
        &:nth-child(2n+1) {
            th,
            td {
                background-color: $smoke-color;
            }
        }
    }
    .member-info-list {
        padding-left: 0;
        margin-bottom: 0;
        li {
            list-style: none;
            position: relative;
            padding-left: 25px;
            margin-bottom: 5px;
            &:last-child {
                margin-bottom: 0;
            }
            &:before {
                content: '\f00c';
                font-family: $icon-font;
                color: $theme-color;
                position: absolute;
                left: 0;
            }
        }
    }
}

.form-title-box.bg-theme {
	padding: 60px;
}
.no-shadow {
    box-shadow: none;
}


// Team Responsive -----------------------------
@include lg {
    .team-card {
        --team-space : 30px;
    }
    .team-schedule .vs-btn {
        font-size: 24px;
        --icon-shape: 40px;
        --icon-size: 30px;
        padding: 0 30px;
        line-height: 60px;
        height: 60px;
        margin-bottom: -30px;
    }
    .team-schedule {
        padding: 40px 40px 40px 40px;
        margin-bottom: 65px;
    }
}

@include sm {
    #team-section {
        background-position: bottom right;
    }
    .team-schedule {
        padding: 40px 20px 40px 20px;
    }
    .form-title-box.bg-theme {
        padding: 30px 40px;
    }
}

@include xs {
    .team-schedule .vs-btn {
        font-size: 18px;
        padding: 0 26px;
        line-height: 50px;
        height: 50px;
    }
    .form-title-box.bg-theme {
        padding: 20px;
    }
    .member-table th, .member-table td {
        padding: 20px 20px;
    }
    .team-card {
        --team-space: 0px;
        margin-bottom: 180px;
        .team-content {
            padding-bottom: 35px;
        }
        .team-social {
            height: var(--icon-size);
            visibility: visible;
            opacity: 1;
        }
        .team-img {
            img {
                width: 100%;
            }
        }
        &:hover {
            .team-content {
                transform: translateY(0);
                padding-bottom: 35px;
            }
        }
    }
}