.vs-menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0,0,0, 0.60);
  z-index: 999999;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.8s;


  // Mobile Menu Logo
  .mobile-logo {
    padding-bottom: 40px;
    padding-top: 37px;
    display: block;
    background-color: $theme-overlay;
  }

  // Menu Close Button
  .vs-menu-toggle {
    border: none;
    font-size: 22px;
    position: absolute;
    right: 0;
    top: 10px;
    padding: 0;
    line-height: 1;
    width: 33px;
    height: 33px;
    line-height: 33px;
    font-size: 18px;
    z-index: 1;
    color: $white-color;
    background-color: $theme-color;
    border-radius: 50%;
    transform:translateX(50%);

    &:hover {
      background-color: $title-color;
      color: $white-color;
    }
  }

  .vs-menu-area {
    width: 100%;
    max-width: 310px;
    background-color: #fff;
    border-right: 3px solid $theme-color;
    height: 100%;
    position: relative;
    left: -1000px;
    opacity: 0;
    visibility: hidden;
    transition: all ease 1s;
    z-index: 1;
  }

  &.vs-body-visible {
    opacity: 1;
    visibility: visible;
    transition: all ease 0.3s;

    .vs-menu-area {
      left: 0;
      opacity: 1;
      visibility: visible;
      transition: all ease 1s;
    }
  }
}


// Mobile Menu
.vs-mobile-menu {
  overflow-y: scroll;
  max-height: calc(100vh - 200px);
  padding-bottom: 40px;
  margin-top: 33px;
  text-align: left;

  

  ul {
    margin: 0;
    padding: 0 0;

    li {
      border-bottom: 1px solid #fdedf1;
      list-style-type: none;

      li:first-child {
        border-top: 1px solid #fdedf1;
      }

      a {
        display: block;
        position: relative;
        padding: 12px 0;
        line-height: 1;
        font-size: 16px;
        text-transform: capitalize;
        color: $title-color;

        &:before {
          content: '\f105';
          font-family: $icon-font;
          position: relative;
          left: 0;
          top: 0;
          margin-right: 10px;
          display: inline-block;
        }
      }

      &.vs-active {
        >a {
          color: $theme-color;

          &:before {
            transform: rotate(90deg);
          }
        }
      }

      ul {
        li {
          padding-left: 20px;

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }

    .vs-item-has-children {
      >a {
        &:after {
          content: '\f067';
          font-family: $icon-font;
          position: absolute;
          right: 0;
          top: 50%;
          font-weight: 400;
          font-size: 12px;
          width: 25px;
          height: 25px;
          line-height: 25px;
          margin-top: -12.5px;
          display: inline-block;
          text-align: center;
          background-color: $smoke-color;
          color: $title-color;
          box-shadow: 0 0 20px -8px rgba($theme-color, 0.3);
          border-radius: 50%;
        }
      }

      &.vs-active {
        >a {
          &:after {
            content: '\f068';
          }
        }
      }
    }
  }

  >ul {
    padding: 0 40px;

    >li {
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.mobile-menu-form {
  width: 100%;
  max-width: 230px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;

  input {
    height: 35px;
    border: none;
    background-color: $white-color;
    border-radius: 5px;
    box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.09);
    padding-left: 20px;
    padding-right: 40px;

    &:focus {
      box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.09);
    }

    @include inputPlaceholder {
      color: $smoke-color;
      color: $theme-color;
    }
  }

  button {
    border: none;
    background-color: transparent;
    color: $theme-color;
    position: absolute;
    right: 13px;
    top: 3px;
    
  }
}



.vs-menu-toggle:not(.hamburger-btn) {
  width: 50px;
  height: 50px;
  padding: 0;
  font-size: 24px;
  border: none;
  background-color: $theme-color;
  color: $white-color;
  display: inline-block;
  border-radius: 50%;
}


@media (max-width: 400px) {
  .vs-menu-wrapper .vs-menu-area {
    width: 100%;
    max-width: 270px;
  }

  .vs-mobile-menu>ul {
    padding: 0 20px;
  }
}