.testimonial-section {
    position: relative;
}
.testi-wrapper {
    position: relative;
}
.left-img-section {
	position: absolute;
	left: 0;
	top: 0;
	width: 43%;
	z-index: 1;
    height: 100%;
    img {
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }
}
.testi-inner {
    padding-top: 92px;
    padding-left: 40px;
    padding-bottom: 92px;
}
.testi-content {
    p {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.889;
        font-style: italic;
    }
}
.rating i {
    color: $yellow-color;
}
.testi-thumb-wrapper {
    max-width: 550px;
}
.testi-thumb {
    --autho-size: 80px;
    opacity: .3;
    cursor: pointer;
    @include transition(.4s);
    .author-img {
        @include equal-size(var(--autho-size));
        border-radius: 50%;
        overflow: hidden;
        border: 2px solid;
        border-color: transparent;
        padding: 8px;
        margin-right: 20px;
        img {
            border-radius: 50%;
        }
    }
    a {
        color: $theme-color;
        &:hover {
            color: $title-color;
        }
    }
    &.slick-current {
        opacity: 1;
        .author-img {
            border-color: $theme-color;
        }
    }
}
.testi-thumb-text .testi-desig {
	line-height: 1.4;
    margin-top: 5px;
    font-size: 14px;
    a {
        color: inherit;
        &:hover {
            color: $theme-color;
        }
    }
}

// Gradient bg ----------------------
.bg-smoke-gr {
    background-image: linear-gradient(to bottom, $smoke-color, transparent);
}

@include ml {
    .testi-inner {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}
@include md {
    .testi-inner {
        padding-top: 80px;
        padding-left: 0;
        padding-bottom: 80px;
    }
    .testi-content p {
        font-size: 16px;
        font-weight: 400;
    }
}