// Comment ----------------------------
.blog-details {
    .vs-comments-layout1 {
        margin-top: 70px;
    }
}
ul.comment-list,
.children {
	list-style: none;
	margin: 0;
	padding: 0;
} 
.vs-post-comment {
    --border-color: rgba(0,0,0,0.05);
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 35px 35px 30px 35px;
	border: 1px solid $border-color;
	margin-bottom: 30px;
    @include transition(.3s);
    .author-img {
        width: 90px;
        height: 90px;
        margin-right: 30px;
        overflow: hidden;
    }
    .comment-content {
        width: calc(100% - 120px);
        position: relative;
        line-height: 1;
    }
    .name {
        margin-bottom: 8px;
        margin-top: -5px;
    }
    .commented-on {
        font-size: 14px;
        display: block;
        font-weight: 400;
        color: $theme-color;
        margin-bottom: 16px;
    }
    .reply_and_edit,
    .rating {
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
    }
    .reply-btn {
        background-color: $smoke-color;
        color: $title-color;
        min-width: 112px;
        padding: 0 20px;
        height: 40px;
        display: inline-block;
        text-align: center;
        line-height: 40px;
        border-radius: 50px;
        font-weight: 600;
        font-size: 14px;
        &:before {
            content: "\f3e5";
            font-family: $icon-font;
            font-weight: 400;
            margin-right: 5px;
        }
    }
    p {
        margin-bottom: 0;
    }
    &:hover {
        background-color: $smoke-color;
        border-color: $smoke-color;
        .reply-btn {
            background-color: $theme-color;
            color: $white-color;
            &:hover {
                background-color: $title-color;
            }
        }
    }
}
.children {
    margin-left: 70px;
}

// Comment form ----------------------
.vs-comment-form {
    background-color: $smoke-color;
    padding: 60px;
    margin-top: 80px;
    h3 {
        margin-top: -0.2em;
    }
    .form-group {
        > i {
            right: 40px;
            top: 22px;
            color: $body-color;
        }
        input,
        textarea {
            &:focus {
                ~ i {
                    color: $title-color;
                }
            }
        }
    }

}

@include lg {
    .vs-comment-form {
        padding: 30px;
    }
}
@include md {
    .vs-comment-form {
        margin-bottom: 30px;
    }
}
@include sm {
    .blog-details .vs-comments-layout1 {
        margin-top: 40px;
    }
    .vs-post-comment {
        padding: 20px;
        margin-bottom: 20px;
    }
    .vs-post-comment .author-img {
        margin-right: 20px;
    }
    .children {
        margin-left: 40px;
    }
    .vs-comment-form {
        margin-top: 40px;
        padding: 20px;
        .mb-40 {
            margin-bottom: 20px;
        }
    }
    .form-title p {
        font-size: 14px;
        line-height: 20px;
        margin-top: 10px;
    }
}
@include xs {
    .vs-post-comment {
        flex-direction: column;
        .author-img {
            margin-right: 20px;
            margin-bottom: 20px;
        }
        .comment-content {
            width: 100%;
        }
    }
}