.service-section {
	position: relative;
    .section-dots-shape {
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
    }
}
#sbox3 .slick-list {
	padding-bottom: 40px;
	margin-bottom: -40px;
}
.vs-service-card {
    padding: 60px 40px 40px 40px;
    position: relative;
    overflow: hidden;
    &:after {
        content: "";
        border-style: solid;
        border-width: 0 30px 30px 0;
        border-color: transparent $theme-color transparent transparent;
        position: absolute;
        top: 30px;
        right: 30px;
        @include transition(.4s);
    }
    .service-icon {
        img {
            height: 80px;
            width: auto;
        }
    }
    .service-desc {
        margin-bottom: 0;
        line-height: 1.7;
    }
    &:hover {
        &:after {
            transform: scale(1.7);
            top: 10px;
            right: 10px;
        }
    }
}

// Service Design Two -----------------------
.sr-content-box {
    max-width: 460px;
}
.vs-service-card2 {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    @include transition(.4s);
    .service-thumb {
        position: relative;
        img {
            width: 100%;
        }
    }
    .service-content {
        width: 100%;
        height: 0;
        visibility: hidden;
        opacity: 0;
        line-height: 1;
        padding: 50px;
        background-image: linear-gradient(0deg, rgba(0,0,0,.8) 50%, transparent);
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        @include transition(.4s);
    }
    .service-title {
        margin-bottom: 5px;
    }
    &:hover {
        .service-content {
            height: 100%;
            visibility: visible;
            opacity: 1;
        }
    }
}

#sbox2 .slick-slide.slick-current + .slick-slide {
    .vs-service-card2 {
        .service-content {
            height: 100%;
            visibility: visible;
            opacity: 1;
        }
    }
}

// Service Design 3 -----------------------
.vs-service-card3 {
    --service-space: 20px;
    --icon-size: 80px;
    --border-color: rgb(241, 241, 241);
    @include transition(.4s);
    .service-thumb {
        width: calc(100% - var(--service-space)*2);
        margin: 0 var(--service-space);
        position: relative;
        z-index: 3;
        img {
            border-radius: 10px;
            width: 100%;
            @include transition(0.3s);
        }
    }
    .s-icon {
        position: absolute;
        bottom: calc(var(--icon-size) / -2);
        right: 30px;
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.04);
        background-color: #fff;
        @include equal-size(var(--icon-size));
        text-align: center;
        line-height: 78px;
        border-radius: 50%;
        @include transition(0.3s);
        img {
            max-width: 40px;
            @include transition(0.3s);
        }
    }
    .service-content {
        padding: 130px 40px 30px 40px;
        border: 2px solid $border-color;
        background-color: $white-color;
        border-radius: 10px;
        margin-top: -90px;
        position: relative;
        z-index: 1;
        @include transition(.4s);
    }
      
    &:hover {
        .s-icon {
            background-color: $theme-color;
            img {
                filter: brightness(0) invert(1);
            }
        }
        .service-content {
            box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.04);
        }
    }
      
}

// Service Details --------------------------------
.service-bar {
    background-color: $white-color;
    box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.04);
    padding: 60px 80px;
    margin-top: -90px;
    margin-bottom: 60px;
    .bar-subtitle {
        display: block;
        margin-bottom: 3px;
    }
}
.service-content {
    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}
  

/*
// Active Style 
.slick-current {
    .vs-service-card2 {
        height: calc(100% + 50px);
        .service-content {
            height: 100%;
            visibility: visible;
            opacity: 1;
        }
    }
}
*/

@include lg {
    .service-bar {
        padding: 40px 40px;
        margin-top: -70px;
    }
}

@include md {
    .service-bar {
        margin-top: 40px;
    }
}

@include sm {
    #sbox2 {
        margin-bottom: 30px;
    }
    .vs-service-card3 {
        --icon-size: 60px;
        .s-icon {
            line-height: 60px;
            img {
                max-width: 30px;
            }
        }
    }
}