.widget {
  select,
  input {
    color: $body-color;
    padding-left: 30px;
    font-size: 16px;
    background-color: $white-color;
    border: none;
    height: 60px;
    border-radius: 0;
    font-weight: 500;
    width: 100%;

    @include inputPlaceholder {
      color: $body-color;
    }
  }
}

.widget_title {
  font-size: 20px;  
  line-height: 1em;
  margin-bottom: 30px;
  margin-top: -0.15em;
  padding-bottom: 10px;
  font-weight: 600;
}

.widget {
  .search-form {
    position: relative;

    input {
      padding-right: 50px;
      background-color: $white-color;
      border-radius: 5px;
    }

    button {
      text-align: center;
      padding: 0;
      color: $theme-color;
      background-color: transparent;
      border: none;
      position: absolute;
      right: 30px;
      line-height: 1;
      top: 50%;
      transform: translateY(-50%);

      &:hover {
        border-color: $theme-color;
        background-color: $white-color;
        color: $theme-color;
      }
    }
  }
}

.sidebar-area .widget {
  --smoke-color: rgba(247, 247, 247, 1);
  margin-bottom: 40px;
  padding: 40px;
  background-color: $smoke-color;
  &.widget_search,
  &.widget_popular_post,
  &.widget_tag_cloud {
      ul {
          list-style: none;
          margin: 0;
          padding: 0;
      }
  }
  .widget_title {
      color: $title-color;
      margin-bottom: 5px;
      padding-bottom: 20px;
      position: relative;
  }
  .recent-post {
    padding-bottom: 15px;
    border-bottom: 1px solid $border-color;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
      padding: 0;
      border-bottom: none;
    }
    .recent-post .media-img {
      margin-right: 20px;
    }
    .post-title {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 9px;
      line-height: 20px;
    }
  }
}

.widget {
&.widget_categories {
    ul {
        margin: 0;
        padding: 0;
        li {
            padding: 19px 25px;
            margin-bottom: 10px;
            display: block;
            position: relative;
            background-color: $white-color;
            border-radius: 5px;
            transition: 0.3s;
            @include transition(.3s);
            &:last-child {
                margin-bottom: 0;
            }
            a {
                font-size: 16px;
                color: $body-color;
                font-weight: 400;
                &:before {
                    content: '\f138';
                    font-family: $icon-font;
                    font-weight: 900;
                    margin-right: 10px;
                    color: $theme-color;
                }
            }
            span {
                position: absolute;
                top: 18px;
                right: 25px;
                color: var(--white-color);
                background-color: var(--theme-color);
                width: 30px;
                height: 30px;
                text-align: center;
                border-radius: 50%;
                line-height: 30px;
                font-size: 10px;
            }
            &:hover {
                background-color: $theme-color;
                a {
                    color: $white-color !important;
                    &:before {
                      color: $white-color;
                    }
                }
                span {
                    color: $theme-color;
                    background-color: $white-color;
                }
            }
        }
    }
}
}

.wp-block-tag-cloud,
.tagcloud {

  a {
    display: inline-block;
    border: none;
    text-transform: capitalize;
    font-size: 16px;
    line-height: 1;
    padding: 10.5px 23px;
    margin-right: 5px;
    margin-bottom: 10px;
    color: $body-color;
    background-color: $smoke-color;
    border-radius: 8px;

    &:hover {
      background-color: $theme-color;
      color: $white-color !important;
    }
  }
}

.tagcloud {
  margin-right: -5px;
  margin-bottom: -10px;
}

.widget {
  .tagcloud {
    a:not(:hover) {
      background-color: $white-color;
    }
  }
}

.recent-post {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  .post-title {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 5px;
    line-height: 24px;
  }

  .media-img {
    margin-right: 25px;
    width: 90px;
    width: 100px;
    border-radius: 5px;
    overflow: hidden;
  }

  .recent-post-meta {
    line-height: 1;
    margin-bottom: 7px;
    
    a {
      margin-right: 15px;
      font-size: 14px;
      font-weight: 400 !important;
      color: $body-color;
      &:hover {
        color: $theme-color;
      }

      i {
        margin-right: 4px;
        font-size: inherit;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.vs-video-widget {
  .video-thumb {
    position: relative;
  }
  
  .play-btn {
    --icon-size: 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    i {
      font-size: 16px;
    }
  }

  .video-thumb-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    margin-top: 10px;
    margin-bottom: -0.3em;
  }
}

.sidebar-gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  .gallery-thumb {
    .icon-thumb {
      font-size: 30px;
    }
  }
}

@include lg {
  .sidebar-area .widget {
    margin-bottom: 30px;
    padding: 30px;
  }
}

@include md {
  .sidebar-gallery {
    grid-template-columns: repeat(3, 1fr);
  }

  .wp-block-tag-cloud a,
  .tagcloud a {
    padding: 10.5px 18px;
  }
}

@include sm {
  .sidebar-area .widget {
    padding: 20px;
  }
}