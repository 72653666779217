.appointment-section {
    position: relative;
}
.appoint-shape img {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}
.vs-img-box2 {
    position: relative;
    height: 100%;
    .big-img {
        max-width: calc(100% - 70px);
    }
    .small-img {
        position: absolute;
        bottom: 0;
        right: 0;
        border: 10px solid $white-color;
        max-width: 284px;
        box-shadow: 0px 5px 120px 0px rgba(14, 28, 52, 0.15);
    }
}

.appoint-form-area {
    padding: 58px;
    box-shadow: 0px 5px 120px 0px rgba(14, 28, 52, 0.1);
}

/* After Before */ 
.comparison-wrapper {
    min-height: 500px;
}
.twentytwenty-handle {
	border: 3px solid white;
    background-color: $white-color;
}
.twentytwenty-left-arrow {
	border-right: 6px solid $theme-color;
    margin-left: -15px;
}
.twentytwenty-right-arrow {
	border-left: 6px solid $theme-color;
    margin-right: -15px;
}

// Appointment Responsive --------------------------
@include lg {
    .comparison-wrapper {
        min-height: 383px;
    }
}
@include md {
    .vs-img-box2 {
        margin-bottom: 30px;
    }
    .comparison-wrapper {
        min-height: 285px;
    }
}
@include sm {
    .form-sub-title.mb-40 {
        margin-bottom: 20px;
    }
    .comparison-wrapper {
        min-height: 211px;
        img {
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }
    .appoint-form-area {
        padding: 40px;
    }
}
@include xs {
    .appoint-form-area {
        padding: 40px 20px;
    }
    .vs-img-box2 .small-img {
        max-width: 200px;
    }
}