@charset "UTF-8";
/*
Template Name: Gimia
Template URL: https://angfuzsoft.com/themeforest/html/gimia/
Description: Gimia - Gym & Yoga Services HTML Template
Author: Angfuzsoft
Author URI: https://themeforest.net/user/angfuzsoft
Version: 1.0.0
*/
/*=================================
    CSS Index Here
==================================*/
/*

01. Theme Base
    1.1. Variable
    1.2. Mixin
    1.3. Function
    1.4. Typography
02. Reset
    2.1. Container
    2.2. Grid
    2.3. Input
    2.4. Slick Slider
    2.5. Mobile Menu
03. Utilities
    3.1. Preloader
    3.2. Buttons
    3.3. Titles
    3.4. Common
    3.6. Font
    3.7. Background
    3.8. Text Color
    3.9. Overlay
    3.10. Animation
04. Spacing
05. Template Style
    5.1. Widget
    5.2. Header
    5.3. Footer
    5.4. Breadcumb
    5.5. Blog
    5.6. Comments
    5.7. Hero Area
    5.8. Service
    5.9. Products
    5.10. Project
    5.11. Appointment
    5.12. Brand
    5.13. Components
    5.14. Schedule
    5.15. Process
    5.16. Gallery
    5.17. About
    5.18. Price Plan
    5.19. Subscribe
    5.20. Team
    5.21. Testimonial
    5.22. Simple Sections
    5.23. Counter
    5.24. Woocommerce

*/
/*=================================
    CSS Index End
==================================*/
/*=================================
   01. Theme Base
==================================*/
/*------------------- 1.1. Variable-------------------*/
:root {
  --theme-color: #5c08b2;
  --title-color: #15171b;
  --body-color: #818790;
  --smoke-color: rgb(249, 249, 249);
  --smoke-dark: rgb(242, 242, 242);
  --theme-overlay: rgba(246, 60, 37, .1);
  --light-color: #818790;
  --black-color: #000000;
  --white-color: #ffffff;
  --yellow-color: #ffcc00;
  --success-color: #28a745;
  --error-color: #dc3545;
  --border-color: rgba(0,0,0,0.10);
  --border-white: rgba(255,255,255,.10);
  --title-font: "Roboto", sans-serif;
  --body-font: "Roboto", sans-serif;
  --subtitle-font: "Roboto", sans-serif;
  --icon-font: "Font Awesome 5 Pro";
  --main-container: 1220px;
  --container-gutters: 1.5rem;
  --section-space: 150px;
  --section-space-mobile: 80px;
  --section-title-space: 80px;
  --ripple-ani-duration: 5s;
}

/*------------------- 1.2. Mixin -------------------*/
/*------------------- 1.3. Function -------------------*/
/*------------------- 1.5. Typography -------------------*/
html,
body {
  scroll-behavior: auto !important;
  overflow-x: hidden;
}

body {
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
  color: var(--body-color);
  line-height: 26px;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
}

iframe {
  border: none;
  width: 100%;
}

.slick-slide:focus,
button:focus,
a:focus,
a:active,
input,
input:hover,
input:focus,
input:active,
textarea,
textarea:hover,
textarea:focus,
textarea:active {
  outline: none;
}

input:focus {
  outline: none;
  box-shadow: none;
}

img:not([draggable]),
embed,
object,
video {
  max-width: 100%;
  height: auto;
}

ul {
  list-style-type: disc;
}

ol {
  list-style-type: decimal;
}

table {
  margin: 0 0 1.5em;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  font-weight: 500;
}

td,
th {
  border-top: 1px solid var(--border-color);
  padding: 9px;
}

a {
  color: var(--theme-color);
  text-decoration: none;
  outline: 0;
  transition: all ease 0.4s;
}
a:hover {
  color: var(--title-color);
}
a:active, a:focus, a:hover, a:visited {
  text-decoration: none;
  outline: 0;
}

button {
  transition: all ease 0.4s;
}

img {
  border: none;
  max-width: 100%;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
p a,
span a {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
}
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
p a:hover,
span a:hover {
  color: var(--theme-color);
}

p {
  margin: 0 0 18px 0;
  color: var(--body-color);
  line-height: 1.8;
}

.h1, h1,
.h2, h2,
.h3, h3,
.h4, h4,
.h5, h5,
.h6, h6 {
  font-family: var(--title-font);
  color: var(--title-color);
  text-transform: none;
  font-weight: 700;
  line-height: 1.2;
  margin: 0 0 15px 0;
}

.h1,
h1 {
  font-size: 72px;
}

.h2,
h2 {
  font-size: 40px;
}

.h3,
h3 {
  font-size: 36px;
}

.h4,
h4 {
  font-size: 24px;
}

.h5,
h5 {
  font-size: 18px;
}

.h6,
h6 {
  font-size: 16px;
}

/* Large devices */
@media (max-width: 1199px) {
  .h1,
h1 {
    font-size: 42px;
  }

  .h2,
h2 {
    font-size: 32px;
  }

  .h3,
h3 {
    font-size: 26px;
  }

  .h4,
h4 {
    font-size: 22px;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .h1,
h1 {
    font-size: 38px;
  }

  .h2,
h2 {
    font-size: 28px;
  }

  .h3,
h3 {
    font-size: 24px;
  }

  .h4,
h4 {
    font-size: 20px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .h1,
h1 {
    font-size: 32px;
  }

  .h2,
h2 {
    font-size: 24px;
  }

  .h3,
h3 {
    font-size: 22px;
  }

  body {
    font-size: 16px;
  }
}
/* Extra small devices */
@media (max-width: 575px) {
  .h1,
h1 {
    font-size: 24px;
  }

  .h2,
h2 {
    font-size: 22px;
  }
}
/*=================================
    02. Reset
==================================*/
/*------------------- 2.1. Container -------------------*/
@media only screen and (min-width: 1300px) {
  .container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container {
    max-width: calc(var(--main-container) + var(--container-gutters));
    padding-left: calc(var(--container-gutters) / 2);
    padding-right: calc(var(--container-gutters) / 2);
  }
}
@media only screen and (max-width: 1600px) {
  .container-fluid.px-0 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .container-fluid.px-0 .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (min-width: 1700px) {
  .vs-container {
    --main-container: 1663px;
  }
}
@media (min-width: 1200px) {
  .vs-container {
    --main-container: 1400px;
  }
}
@media (min-width: 1300px) {
  .vs-container-style2 {
    --main-container: 1850px;
    margin-right: 0;
    padding-right: 0;
  }
}
@media (min-width: 1500px) {
  .vs-container-style3 {
    --main-container: 1440px;
  }
}
@media (min-width: 1501px) {
  .vs-container-style2 {
    --main-container: 1523px;
    margin-right: 0;
    padding-right: 0;
  }
}
@media (min-width: 2000px) {
  .vs-container-style2 {
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 991px) and (max-width: 1199px) {
  .vs-container-style2 {
    margin-right: 0;
    padding-right: 0;
    max-width: 100%;
  }
}
/*------------------- 2.2. Grid -------------------*/
.row {
  --bs-gutter-x: 30px;
}

.slick-track > [class*=col] {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.gx-200,
.g-200 {
  --bs-gutter-x: 200px;
}

.gy-200,
.g-200 {
  --bs-gutter-y: 200px;
}

.gx-60,
.g-60 {
  --bs-gutter-x: 60px;
}

.gy-60,
.g-60 {
  --bs-gutter-y: 60px;
}

.gx-50,
.g-50 {
  --bs-gutter-x: 50px;
}

.gy-50,
.g-50 {
  --bs-gutter-y: 50px;
}

.gx-40,
.g-40 {
  --bs-gutter-x: 40px;
}

.gy-40,
.g-40 {
  --bs-gutter-y: 40px;
}

.gx-30,
.g-30 {
  --bs-gutter-x: 30px;
}

.gy-30,
.g-30 {
  --bs-gutter-y: 30px;
}

.gx-20,
.g-20 {
  --bs-gutter-x: 20px;
}

.gy-20,
.g-20 {
  --bs-gutter-y: 20px;
}

.gx-12,
.g-12 {
  --bs-gutter-x: 12px;
}

.gy-12,
.g-12 {
  --bs-gutter-y: 12px;
}

.gx-10,
.g-10 {
  --bs-gutter-x: 10px;
}

.gy-10,
.g-10 {
  --bs-gutter-y: 10px;
}

.gx-0,
.g-0 {
  --bs-gutter-x: 0;
}

.gy-0,
.g-0 {
  --bs-gutter-y: 0;
}

.px-100 {
  padding-right: 100px;
  padding-left: 100px;
}

@media (min-width: 1500px) {
  .gx-70 {
    --bs-gutter-x: 70px;
  }
}
@media (min-width: 1399px) {
  .gx-20 {
    --bs-gutter-x: 20px;
  }

  .gx-30 {
    --bs-gutter-x: 30px;
  }

  .gx-40 {
    --bs-gutter-x: 40px;
  }

  .gx-50 {
    --bs-gutter-x: 50px;
  }

  .gx-60 {
    --bs-gutter-x: 60px;
  }
}
/* Large devices */
@media (max-width: 1199px) {
  .gx-200, .g-200 {
    --bs-gutter-x: 80px;
  }

  .gx-60,
.g-60 {
    --bs-gutter-x: 30px;
  }

  .gy-60,
.g-60 {
    --bs-gutter-y: 30px;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .gx-200,
.g-200 {
    --bs-gutter-x: 50px;
  }

  .gy-200,
.g-200 {
    --bs-gutter-y: 50px;
  }
}
/*------------------- 2.3. Input -------------------*/
.form-select {
  display: block;
  width: 100%;
  line-height: 1.5;
  vertical-align: middle;
  background-image: url("data:image/svg+xml;utf8,<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='sort-down' class='svg-inline--fa fa-sort-down fa-w-10' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path fill='grey' d='M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z'></path></svg>");
  background-repeat: no-repeat;
  background-position: right 24px top 18px !important;
  background-size: 16px 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select,
.form-select,
.form-control {
  height: 60px;
  padding: 0 28px;
  border: 2px solid rgba(39, 71, 125, 0.1);
  color: var(--body-color);
  background-color: transparent;
  border-radius: 7px;
  font-weight: 400;
  font-size: 14px;
}
select:focus,
.form-select:focus,
.form-control:focus {
  outline: 0;
  box-shadow: none;
  border-color: #ff3333;
}
select::-moz-placeholder,
.form-select::-moz-placeholder,
.form-control::-moz-placeholder {
  color: inherit;
}
select::-webkit-input-placeholder,
.form-select::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder {
  color: inherit;
}
select:-ms-input-placeholder,
.form-select:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  color: inherit;
}
select::placeholder,
.form-select::placeholder,
.form-control::placeholder {
  color: inherit;
}

.form-select {
  cursor: pointer;
  background-position: right 1.3rem center;
}

input.form-control::-webkit-outer-spin-button,
input.form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.form-control[type=number] {
  -moz-appearance: textfield;
}

textarea.form-control {
  min-height: 150px;
  padding-top: 17px;
  padding-bottom: 17px;
}

input[type=checkbox] {
  visibility: hidden;
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  display: none;
}
input[type=checkbox]:checked ~ label:before {
  content: "\f00c";
  color: var(--theme-color);
  border-color: var(--theme-color);
}
input[type=checkbox] ~ label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  display: block;
}
input[type=checkbox] ~ label:before {
  content: "";
  font-family: var(--icon-font);
  font-weight: 700;
  position: absolute;
  left: 0;
  top: 4px;
  background-color: var(--white-color);
  border: 1px solid var(--border-color);
  height: 18px;
  width: 18px;
  line-height: 18px;
  text-align: center;
  font-size: 12px;
}

input[type=radio] {
  visibility: hidden;
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  display: none;
}
input[type=radio] ~ label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 1;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 0;
}
input[type=radio] ~ label::before {
  content: "\f111";
  position: absolute;
  font-family: var(--icon-font);
  left: 0;
  top: 1px;
  width: 15px;
  height: 15px;
  padding-left: 0.5px;
  font-size: 0.29rem;
  line-height: 13px;
  text-align: center;
  border: 1px solid var(--theme-color);
  border-radius: 100%;
  font-weight: 700;
  background: var(--white-color);
  color: transparent;
  transition: all 0.2s ease;
}
input[type=radio]:checked ~ label::before {
  border-color: var(--theme-color);
  background-color: var(--theme-color);
  color: var(--white-color);
}

label {
  margin-bottom: 0.6em;
  margin-top: -0.3em;
  display: block;
}

.form-group {
  position: relative;
}

.form-group > i {
  position: absolute;
  right: 40px;
  top: 22px;
  font-size: 18px;
  color: var(--body-color);
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border: 1px solid var(--error-color) !important;
  background-position: right calc(0.375em + 0.8875rem) center;
  background-image: none;
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  outline: 0;
  box-shadow: none;
}

textarea.form-control.is-invalid {
  background-position: top calc(0.375em + 0.5875rem) right calc(0.375em + 0.8875rem);
}

.form-select.is-invalid, .was-validated .form-select:invalid {
  border-color: #dc3545;
  background-image: url("data:image/svg+xml;utf8,<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='sort-down' class='svg-inline--fa fa-sort-down fa-w-10' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path fill='currentColor' d='M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z'></path></svg>");
  border-width: 1px;
}

.row.no-gutters > .form-group {
  margin-bottom: 0;
}

.form-messages {
  display: none;
}
.form-messages.mb-0 * {
  margin-bottom: 0;
}
.form-messages.success {
  color: var(--success-color);
  display: block;
}
.form-messages.error {
  color: var(--error-color);
  display: block;
}

/* Large devices */
@media (max-width: 1199px) {
  .form-select,
.form-control {
    padding: 0 20px;
  }

  .form-select {
    background-position: right 20px top 20px !important;
  }
}
/* Extra small devices */
@media (max-width: 575px) {
  .form-select,
.form-control {
    padding-right: 30px;
    padding-left: 15px;
  }
}
/*------------------- 2.4. Slick Slider -------------------*/
.slick-track {
  min-width: 100%;
}

.slick-slide img {
  display: inline-block;
}

.slick-dots {
  list-style-type: none;
  padding: 2px 0;
  margin: 30px 0 30px 0;
  line-height: 0;
  text-align: center;
  height: max-content;
}
.slick-dots li {
  display: inline-block;
  margin-right: 10px;
}
.slick-dots li:last-child {
  margin-right: 0;
}
.slick-dots button {
  font-size: 0;
  padding: 0;
  background-color: transparent;
  width: 12px;
  height: 12px;
  line-height: 0;
  border-radius: 9999px;
  border: 3px solid #7a7a7a;
  transition: all ease 0.4s;
}
.slick-dots button:hover {
  border-color: var(--theme-color);
}
.slick-dots .slick-active button {
  transform: scale(1.5);
  border-color: var(--theme-color);
}

.slick-arrow {
  display: inline-block;
  padding: 0;
  background-color: #1ae2f4;
  color: var(--theme-color);
  border: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 50px;
  margin-top: calc(var(--icon-size, 60px) / -2);
  left: var(--pos-x, -170px);
  width: var(--icon-size, 60px);
  height: var(--icon-size, 60px);
  border-radius: 50%;
  z-index: 2;
  transition: 0.4s;
}
.slick-arrow.default {
  position: relative;
  --pos-x: 0;
  margin-top: 0;
}
.slick-arrow .long-arrow {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -1px 0 0 -3px;
  display: inline-block;
  width: 82px;
  height: 2px;
  background-color: var(--theme-color);
  transition: all ease 0.4s;
}
.slick-arrow .long-arrow:before {
  content: "";
  position: absolute;
  left: -1px;
  top: -6px;
  display: inline-block;
  border-right: 7px solid var(--title-color);
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  transition: all ease 0.4s;
}
.slick-arrow:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.slick-arrow:hover .long-arrow {
  background-color: var(--theme-color);
  animation: arrow-left ease 1s alternate;
}
.slick-arrow:hover .long-arrow:before {
  border-right-color: var(--theme-color);
}
.slick-arrow.slick-next {
  margin-right: 0;
  margin-left: 50px;
  right: var(--pos-x, -170px);
  left: auto;
}
.slick-arrow.slick-next .long-arrow {
  left: auto;
  right: 50%;
  margin-left: 0;
  margin-right: -3px;
}
.slick-arrow.slick-next .long-arrow:before {
  left: auto;
  right: -1px;
  border-right: none;
  border-left: 7px solid var(--title-color);
}
.slick-arrow.slick-next:hover .long-arrow {
  animation: arrow-right ease 1s alternate;
}
.slick-arrow.slick-next:hover .long-arrow:before {
  border-right-color: transparent;
  border-left-color: var(--theme-color);
}

.arrow-wrap .slick-arrow {
  opacity: 0;
  visibility: hidden;
  left: var(--pos-x, -220px);
}
.arrow-wrap .slick-arrow.slick-next {
  right: var(--pos-x, -220px);
  left: auto;
}
.arrow-wrap:hover .slick-arrow {
  opacity: 1;
  visibility: visible;
  left: var(--pos-x, -170px);
}
.arrow-wrap:hover .slick-arrow.slick-next {
  right: var(--pos-x, -170px);
  left: auto;
}

/* Extra large devices */
@media (max-width: 1500px) {
  .slick-arrow {
    --arrow-horizontal: -20px;
  }

  .arrow-wrap .slick-arrow {
    left: var(--pos-x, -120px);
  }

  .arrow-wrap .slick-arrow.slick-next {
    right: var(--pos-x, -120px);
    left: auto;
  }

  .arrow-wrap:hover .slick-arrow {
    left: var(--pos-x, -60px);
  }

  .arrow-wrap:hover .slick-arrow.slick-next {
    right: var(--pos-x, -60px);
    left: auto;
  }
}
/* Medium Large devices */
@media (max-width: 1399px) {
  .slick-arrow {
    --arrow-horizontal: 40px;
  }

  .arrow-wrap:hover .slick-arrow {
    left: var(--pos-x, -15px);
  }

  .arrow-wrap:hover .slick-arrow.slick-next {
    right: var(--pos-x, -15px);
    left: auto;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .slick-arrow {
    --icon-size: 40px;
    margin-right: 40px;
  }
  .slick-arrow .long-arrow {
    width: 62px;
    height: 2px;
  }
  .slick-arrow.slick-next {
    margin-right: 0;
    margin-left: 40px;
  }
}
/*------------------- 2.5. Mobile Menu -------------------*/
.vs-menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999999;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.8s;
}
.vs-menu-wrapper .mobile-logo {
  padding-bottom: 40px;
  padding-top: 37px;
  display: block;
  background-color: var(--theme-overlay);
}
.vs-menu-wrapper .vs-menu-toggle {
  border: none;
  font-size: 22px;
  position: absolute;
  right: 0;
  top: 10px;
  padding: 0;
  line-height: 1;
  width: 33px;
  height: 33px;
  line-height: 33px;
  font-size: 18px;
  z-index: 1;
  color: var(--white-color);
  background-color: var(--theme-color);
  border-radius: 50%;
  transform: translateX(50%);
}
.vs-menu-wrapper .vs-menu-toggle:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}
.vs-menu-wrapper .vs-menu-area {
  width: 100%;
  max-width: 310px;
  background-color: #fff;
  border-right: 3px solid var(--theme-color);
  height: 100%;
  position: relative;
  left: -1000px;
  opacity: 0;
  visibility: hidden;
  transition: all ease 1s;
  z-index: 1;
}
.vs-menu-wrapper.vs-body-visible {
  opacity: 1;
  visibility: visible;
  transition: all ease 0.3s;
}
.vs-menu-wrapper.vs-body-visible .vs-menu-area {
  left: 0;
  opacity: 1;
  visibility: visible;
  transition: all ease 1s;
}

.vs-mobile-menu {
  overflow-y: scroll;
  max-height: calc(100vh - 200px);
  padding-bottom: 40px;
  margin-top: 33px;
  text-align: left;
}
.vs-mobile-menu ul {
  margin: 0;
  padding: 0 0;
}
.vs-mobile-menu ul li {
  border-bottom: 1px solid #fdedf1;
  list-style-type: none;
}
.vs-mobile-menu ul li li:first-child {
  border-top: 1px solid #fdedf1;
}
.vs-mobile-menu ul li a {
  display: block;
  position: relative;
  padding: 12px 0;
  line-height: 1;
  font-size: 16px;
  text-transform: capitalize;
  color: var(--title-color);
}
.vs-mobile-menu ul li a:before {
  content: "\f105";
  font-family: var(--icon-font);
  position: relative;
  left: 0;
  top: 0;
  margin-right: 10px;
  display: inline-block;
}
.vs-mobile-menu ul li.vs-active > a {
  color: var(--theme-color);
}
.vs-mobile-menu ul li.vs-active > a:before {
  transform: rotate(90deg);
}
.vs-mobile-menu ul li ul li {
  padding-left: 20px;
}
.vs-mobile-menu ul li ul li:last-child {
  border-bottom: none;
}
.vs-mobile-menu ul .vs-item-has-children > a:after {
  content: "\f067";
  font-family: var(--icon-font);
  position: absolute;
  right: 0;
  top: 50%;
  font-weight: 400;
  font-size: 12px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  margin-top: -12.5px;
  display: inline-block;
  text-align: center;
  background-color: var(--smoke-color);
  color: var(--title-color);
  box-shadow: 0 0 20px -8px rgba(var(--theme-color), 0.3);
  border-radius: 50%;
}
.vs-mobile-menu ul .vs-item-has-children.vs-active > a:after {
  content: "\f068";
}
.vs-mobile-menu > ul {
  padding: 0 40px;
}
.vs-mobile-menu > ul > li:last-child {
  border-bottom: none;
}

.mobile-menu-form {
  width: 100%;
  max-width: 230px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
}
.mobile-menu-form input {
  height: 35px;
  border: none;
  background-color: var(--white-color);
  border-radius: 5px;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.09);
  padding-left: 20px;
  padding-right: 40px;
}
.mobile-menu-form input:focus {
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.09);
}
.mobile-menu-form input::-moz-placeholder {
  color: var(--smoke-color);
  color: var(--theme-color);
}
.mobile-menu-form input::-webkit-input-placeholder {
  color: var(--smoke-color);
  color: var(--theme-color);
}
.mobile-menu-form input:-ms-input-placeholder {
  color: var(--smoke-color);
  color: var(--theme-color);
}
.mobile-menu-form input::placeholder {
  color: var(--smoke-color);
  color: var(--theme-color);
}
.mobile-menu-form button {
  border: none;
  background-color: transparent;
  color: var(--theme-color);
  position: absolute;
  right: 13px;
  top: 3px;
}

.vs-menu-toggle:not(.hamburger-btn) {
  width: 50px;
  height: 50px;
  padding: 0;
  font-size: 24px;
  border: none;
  background-color: var(--theme-color);
  color: var(--white-color);
  display: inline-block;
  border-radius: 50%;
}

@media (max-width: 400px) {
  .vs-menu-wrapper .vs-menu-area {
    width: 100%;
    max-width: 270px;
  }

  .vs-mobile-menu > ul {
    padding: 0 20px;
  }
}
/*=================================
    03. Utilities
==================================*/
/*------------------- 3.1. Preloader -------------------*/
.preloader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  background-color: var(--white-color);
}
.preloader .vs-btn {
  padding: 15px 20px;
  border-radius: 0;
  font-size: 14px;
}

.preloader-inner {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.preloader-inner img {
  display: block;
  margin: 0 auto 10px auto;
}

.preload-logo {
  position: relative;
  height: 100px;
  width: 100px;
  z-index: 2;
}

.preload-bg {
  position: absolute;
  top: 0;
  left: 0;
  animation: preloadAni 5s linear infinite;
  -webkit-animation: preloadAni 5s linear infinite;
  -moz-animation: preloadAni 5s linear infinite;
  transform: translate(-50%, -50%);
}

.preload-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes preloadAni {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes preloadAni {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}
/*------------------- 3.2. Buttons -------------------*/
.vs-btn {
  border: none;
  display: inline-block;
  text-transform: capitalize;
  line-height: 1;
  font-size: 16px;
  font-weight: 600;
  padding: 22px 33px;
  background-color: var(--theme-color);
  color: var(--white-color);
  border-radius: 7px;
  text-align: center;
  overflow: hidden;
  position: relative;
  z-index: 1;
  vertical-align: middle;
  /* Extra small devices */
}
.vs-btn:after {
  content: "";
  position: absolute;
  z-index: -1;
  background-color: var(--title-color);
  left: auto;
  right: 0;
  top: 0;
  height: 100%;
  width: 0;
  border-radius: inherit;
  transition: all ease 0.4s;
}
.vs-btn i {
  margin-left: 4px;
}
.vs-btn:hover {
  color: var(--white-color);
}
.vs-btn:hover:after {
  width: 101%;
  right: auto;
  left: 0;
}
.vs-btn.btn-noradius {
  border-radius: 0;
}
@media (max-width: 575px) {
  .vs-btn {
    font-size: 14px;
    padding: 17px 26px;
  }
}
.vs-btn.outline-white {
  border: 2px solid var(--white-color);
  background-color: transparent;
}
.vs-btn.outline-white:after {
  display: none;
}
.vs-btn.outline-white:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
  border-color: var(--theme-color);
}
.vs-btn.style-white {
  background-color: var(--white-color);
  color: var(--title-color);
}
.vs-btn.style-white:after {
  background-color: var(--title-color);
}
.vs-btn.style-white:hover {
  color: var(--white-color);
}
.vs-btn.style-white.active {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.vs-btn.style-white-2 {
  background-color: var(--white-color);
  color: var(--theme-color);
}
.vs-btn.style-white-2:after {
  background-color: var(--theme-color);
}
.vs-btn.style-white-2:hover {
  color: var(--white-color);
}
.vs-btn.style-black {
  background-color: var(--black-color);
  color: var(--white-color);
}
.vs-btn.style-black:hover {
  color: var(--white-color);
}
.vs-btn.style-black:after {
  background-color: var(--theme-color);
}
.vs-btn.style-white-hover:after {
  background-color: var(--white-color);
}
.vs-btn.style-white-hover:hover {
  color: var(--title-color);
}
.vs-btn.vs-btn-flex {
  min-width: 192px;
  width: 192px;
  max-width: 100%;
}
.vs-btn.btn-center {
  margin: 0 auto;
}
.vs-btn.btn-fw {
  width: 100%;
  display: block;
}
.vs-btn.btn-fhw {
  width: 100%;
  height: 100%;
}
.vs-btn.btn-sm {
  padding: 15px 25px;
}
.vs-btn.style-outline {
  --border-color: rgb(105, 98, 98);
  border: 2px solid var(--border-color);
  background-color: transparent;
  padding: 20px 33px;
}
.vs-btn.style-outline:after {
  display: none;
}
.vs-btn.style-outline:hover {
  border-color: inherit;
}
.vs-btn.style-phone {
  margin-left: calc(var(--icon-shape) / 2);
}
.vs-btn.style-phone:after {
  display: none;
}
.vs-btn.style-phone:before {
  content: "";
  position: absolute;
  left: calc(var(--icon-shape) / -2);
  top: 50%;
  width: var(--icon-shape);
  height: var(--icon-shape);
  margin-top: calc(var(--icon-shape) / -2);
  background-color: var(--white-color);
  border-radius: 50%;
  box-shadow: inset 0px 5px 5px 0px rgba(246, 60, 37, 0.3);
  -webkit-transition: inherit;
  transition: inherit;
}
.vs-btn.style-phone i {
  position: absolute;
  left: calc(var(--icon-size) / -2);
  top: 50%;
  width: var(--icon-size);
  height: var(--icon-size);
  line-height: var(--icon-size);
  margin-top: calc(var(--icon-size) / -2);
  border-radius: 50%;
  background-color: var(--theme-color);
  font-size: 14px;
  -webkit-transition: inherit;
  transition: inherit;
  margin-left: 0;
}
.vs-btn.style-phone:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.vs-btn.style-phone:hover i {
  -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

.icon-btn {
  --btn-size: 50px;
  display: inline-block;
  width: var(--btn-size);
  height: var(--btn-size);
  line-height: var(--btn-size);
  background-color: var(--icon-bg, #f3f3f3);
  color: var(--title-color);
  text-align: center;
  border-radius: 50%;
}
.icon-btn:hover, .icon-btn.active {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.icon-btn.btn-noradius {
  border-radius: 0;
}
.icon-btn.style-outline {
  background-color: transparent;
  border: 2px solid var(--white-color);
  color: var(--white-color);
}
.icon-btn.style-outline:hover {
  background-color: var(--title-color);
  color: var(--white-color);
  border-color: transparent;
  transition-delay: 0s;
}
.icon-btn.style2, .icon-btn.style3, .icon-btn.style5 {
  --btn-size: 60px;
  border-radius: 0;
  line-height: 54px;
  border: 2px solid rgba(255, 255, 255, 0.25);
  background-color: transparent;
  color: var(--white-color);
  /* Extra small devices */
}
.icon-btn.style2 i, .icon-btn.style3 i, .icon-btn.style5 i {
  color: inherit;
}
.icon-btn.style2:hover, .icon-btn.style3:hover, .icon-btn.style5:hover {
  border-color: #ffffff;
}
@media (max-width: 575px) {
  .icon-btn.style2, .icon-btn.style3, .icon-btn.style5 {
    --btn-size: 48px;
    line-height: 44px !important;
  }
}
.icon-btn.style3, .icon-btn.style5 {
  border-radius: 7px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  color: var(--theme-color);
}
.icon-btn.style3 i, .icon-btn.style5 i {
  color: inherit;
}
.icon-btn.style3:hover, .icon-btn.style5:hover {
  border-color: var(--theme-color);
}
.icon-btn.style4 {
  --btn-size: 45px;
  border: none;
  border-radius: 5px;
  color: var(--body-color);
  background-color: var(--white-color);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.03);
}
.icon-btn.style4:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.icon-btn.style5 {
  color: var(--white-color);
  border: 2px solid rgba(255, 255, 255, 0.4);
}
.icon-btn.style5:hover {
  border-color: var(--white-color);
}
.icon-btn.style6 {
  --btn-size: 36px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 37px;
}

[class*=vs-arrow] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  line-height: 47px;
  background-color: var(--smoke-color) !important;
}

.play-btn {
  position: relative;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  color: var(--white-color);
  border: 2px solid;
  width: 100px;
  height: 100px;
}
.play-btn > i {
  color: var(--white-color);
  position: relative;
  z-index: 2;
  line-height: 100px;
  text-align: center;
  font-size: 18px;
  border-radius: inherit;
  transition: all ease 0.4s;
}
.play-btn.style-1 {
  /* Extra small devices */
}
.play-btn.style-1::before {
  content: "";
  position: absolute;
  border: 2px solid rgba(255, 255, 255, 0.4);
  width: 150px;
  height: 150px;
  top: -25px;
  left: -25px;
  z-index: 1;
  border-radius: 50%;
  /* Extra small devices */
}
@media (max-width: 575px) {
  .play-btn.style-1::before {
    width: 70px;
    height: 70px;
    left: -12px;
    top: -12px;
  }
}
.play-btn.style-1::after {
  content: "";
  position: absolute;
  border: 2px solid rgba(255, 255, 255, 0.4);
  width: 195px;
  height: 195px;
  top: -46px;
  left: -46px;
  z-index: 1;
  border-radius: 50%;
  /* Extra small devices */
}
@media (max-width: 575px) {
  .play-btn.style-1::after {
    width: 90px;
    height: 90px;
    left: -22px;
    top: -22px;
  }
}
.play-btn.style-1:hover {
  color: var(--theme-color);
  background-color: transparent;
}
.play-btn.style-1:hover i {
  color: inherit;
}
@media (max-width: 575px) {
  .play-btn.style-1 {
    width: 50px;
    height: 50px;
  }
  .play-btn.style-1 i {
    font-size: 14px;
    line-height: 47px;
  }
}

.ripple-icon {
  display: inline-block;
  position: relative;
  z-index: 1;
}
.ripple-icon:after, .ripple-icon:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--theme-color);
  -webkit-animation: ripple-sm 4s infinite ease-in-out;
  animation: ripple-sm 4s infinite ease-in-out;
  z-index: -1;
  border-radius: 50%;
}
.ripple-icon:after {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}
.ripple-icon i {
  width: 60px;
  height: 60px;
  line-height: 60px;
  background-color: var(--theme-color);
  color: var(--white-color);
  border: 4px solid rgba(var(--theme-color), 0.3);
  display: inline-block;
  text-align: center;
  font-size: 1.3em;
  border-radius: 50%;
}

.scroll-btn {
  --btn-size: 60px;
  --extra-shape: -6px;
  position: fixed;
  bottom: 300px;
  right: 30px;
  z-index: 94;
  opacity: 0;
  visibility: hidden;
  display: inline-block;
  border-radius: 5px;
  /* Small devices */
}
.scroll-btn i {
  display: inline-block;
  background-color: var(--theme-color);
  color: var(--white-color);
  text-align: center;
  font-size: 16px;
  width: var(--btn-size);
  height: var(--btn-size);
  line-height: var(--btn-size);
  z-index: 2;
  border-radius: inherit;
  position: relative;
  transition: all ease 0.8s;
}
.scroll-btn:focus i, .scroll-btn:hover i {
  background-color: var(--title-color);
  color: var(--white-color);
}
.scroll-btn.show {
  bottom: 120px;
  opacity: 1;
  visibility: visible;
}
@media (max-width: 767px) {
  .scroll-btn {
    --btn-size: 40px;
    --extra-shape: -4px;
    right: 15px;
    bottom: 50px;
  }
  .scroll-btn.show {
    bottom: 15px;
  }
}

.scrollToTop {
  position: fixed;
  right: 60px;
  bottom: 500px;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.4s;
  z-index: 96;
}
.scrollToTop.show {
  bottom: 60px;
  opacity: 1;
  visibility: visible;
}

.cart-icon {
  color: var(--title-color);
  line-height: 1;
  position: relative;
  vertical-align: middle;
}
.cart-icon .badge {
  right: -13px;
  background-color: var(--black-color);
  top: -7px;
  padding: 0.3em 0.45em 0.2em 0.4em;
}

.simple-link {
  font-weight: 600;
}
.simple-link i {
  position: relative;
  top: 1px;
  margin-right: 8px;
}
.simple-link:hover {
  color: var(--theme-color) !important;
}

/* Small devices */
@media (max-width: 767px) {
  .scrollToTop {
    right: 20px;
  }
  .scrollToTop.show {
    bottom: 20px;
  }
}
.hamburger-btn {
  padding: 7px;
  display: inline-block;
  overflow: hidden;
  background-color: transparent;
  border: none;
  z-index: 5;
  transition: all ease 0.4s;
  line-height: 1;
}
.hamburger-btn .bar {
  width: 22px;
  display: inline-block;
  height: 18px;
  position: relative;
}
.hamburger-btn .inner,
.hamburger-btn .hidden {
  display: inline-block;
  opacity: 0;
  width: 100%;
  height: 3px;
  background-color: var(--white-color);
  position: absolute;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.4s;
}
.hamburger-btn .inner:after, .hamburger-btn .inner:before,
.hamburger-btn .hidden:after,
.hamburger-btn .hidden:before {
  content: "";
  width: 100%;
  height: 3px;
  background-color: var(--white-color);
  border-radius: 4px;
  position: absolute;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.4s;
}
.hamburger-btn .inner:before,
.hamburger-btn .hidden:before {
  top: -10px;
}
.hamburger-btn .inner:after,
.hamburger-btn .hidden:after {
  top: 10px;
}
.hamburger-btn .inner {
  top: 50%;
  opacity: 1;
  transform: translate(-25px, -50%);
}
.hamburger-btn .inner:after, .hamburger-btn .inner:before {
  opacity: 1;
  transform: translate(-5px, 0);
}
.hamburger-btn .hidden {
  top: 50%;
  background-color: var(--theme-color);
  transform: translate(51px, -50%);
}
.hamburger-btn .hidden:after, .hamburger-btn .hidden:before {
  background-color: var(--theme-color);
  transform: translate(102px, 0);
}
.hamburger-btn:hover .inner {
  transform: translate(-51px, 50%);
  opacity: 0;
}
.hamburger-btn:hover .inner:after, .hamburger-btn:hover .inner:before {
  transform: translate(102px, 0);
  opacity: 0;
}
.hamburger-btn:hover .hidden {
  opacity: 1;
  transform: translate(-14px, -50%);
}
.hamburger-btn:hover .hidden:after, .hamburger-btn:hover .hidden:before {
  opacity: 1;
  transform: translate(-11px, 0);
}

/* Medium devices */
@media (max-width: 991px) {
  .play-btn {
    --icon-size: 100px;
  }
}
/* Extra small devices */
@media (max-width: 575px) {
  .icon-btn.style2, .icon-btn.style3, .icon-btn.style5 {
    width: var(--btn-size, 48px);
    height: var(--btn-size, 48px);
    line-height: var(--btn-size, 43px);
  }

  .vs-btn.style-outline {
    padding: 15px 26px;
  }
}
/*------------------- 3.3. Titles -------------------*/
.title-area {
  line-height: 1;
}

.sub-title {
  line-height: 1;
  display: inline-block;
  font-size: 18px;
  color: var(--theme-color);
  font-family: var(--subtitle-font);
  font-weight: 500;
  margin-bottom: 27px;
  position: relative;
}
.sub-title:before, .sub-title:after {
  content: "";
  height: 2px;
  width: 25px;
  position: absolute;
  background-color: var(--theme-color);
  top: 8px;
}
.sub-title:before {
  right: calc(100% + 20px);
  background-image: linear-gradient(to left, var(--theme-color), transparent 130%);
  background-color: transparent;
}
.sub-title:after {
  left: calc(100% + 20px);
  background-image: linear-gradient(to right, var(--theme-color), transparent 130%);
  background-color: transparent;
}
.sub-title.style-2 {
  margin-bottom: 15px;
}
.sub-title.style-3 {
  margin-bottom: 15px;
  margin-left: 47px;
  margin-top: -4px;
  display: block;
}
.sub-title.style-3:after {
  display: none;
}
.sub-title.style-3:before {
  background-color: var(--theme-color);
}

.sec-title {
  font-weight: 600;
  margin: -0.25em 0 27px 0;
}

.sec-title2 {
  font-size: 60px;
}

.sec-desc2 {
  font-size: 18px;
  font-weight: 500;
}

.title-area {
  margin-bottom: calc(var(--section-title-space) - 13px);
}

.sec-icon {
  margin-bottom: 36px;
  line-height: 1;
}
.sec-icon img {
  margin-top: -0.2em;
}

.inner-title {
  font-size: 40px;
  margin-top: -0.25em;
}

.border-title {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 30px;
  margin-top: -0.2em;
}
.border-title:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 3px;
  background-color: var(--theme-color);
}
.border-title.style2, .border-title.icon-btn.style3, .border-title.icon-btn.style5 {
  padding-bottom: 10px;
  margin-bottom: 20px;
}

h3.border-title {
  margin-bottom: 50px;
}

/* Large devices */
@media (max-width: 1199px) {
  .sec-title {
    font-size: 36px;
  }

  .sub-title {
    font-size: 16px;
  }
  .sub-title:before, .sub-title:after {
    top: 7px;
  }

  .inner-title {
    font-size: 28px;
  }

  .sec-title2 {
    font-size: 44px;
  }

  .sec-desc2 {
    font-size: 16px;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .sec-title {
    font-size: 30px;
  }

  .sub-title {
    font-size: 14px;
  }
  .sub-title:before, .sub-title:after {
    top: 6px;
  }

  .sec-title2 {
    font-size: 36px;
  }

  .sec-desc2 {
    font-size: 14px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .sec-title {
    font-size: 26px;
  }

  .sec-title2 {
    font-size: 30px;
  }

  .sub-title:before {
    right: calc(100% + 20px);
  }
  .sub-title.style-3 {
    margin-left: 37px;
  }
  .sub-title.style-3:before {
    right: calc(100% + 10px);
  }
}
/*------------------- 3.4. Common -------------------*/
.z-index-step1 {
  position: relative;
  z-index: 4 !important;
}

.z-index-common {
  position: relative;
  z-index: 3;
}

.z-index-n1 {
  z-index: -1;
}

.media-body {
  flex: 1;
}

.badge {
  position: absolute;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-block;
  text-align: center;
  background-color: var(--theme-color);
  color: var(--white-color);
  padding: 0.35em 0.55em;
  border-radius: 50%;
}

.vs-surface {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.outline-social {
  --icon-size: 50px;
  --border-size: 2px;
}
.outline-social ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.outline-social li {
  display: inline-block;
  margin-right: 5px;
}
.outline-social li:last-child {
  margin-right: 0;
}
.outline-social a {
  width: var(--icon-size);
  height: var(--icon-size);
  line-height: calc(var(--icon-size) - var(--border-size));
  border: var(--border-size) solid var(--white-color);
  color: var(--white-color);
  font-size: 16px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
}
.outline-social a:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
  border-color: transparent;
}

.checked-list ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.checked-list li {
  padding-left: 25px;
  font-size: 16px;
  margin-bottom: 3px;
}
.checked-list li:last-child {
  margin-bottom: 0;
}
.checked-list li:before {
  content: "\f00c";
  font-family: var(--icon-font);
  position: absolute;
  left: 0;
}

/*------------------- 3.6. Font -------------------*/
.font-icon {
  font-family: var(--icon-font);
}

.font-title {
  font-family: var(--title-font);
}

.font-body {
  font-family: var(--body-font);
}

.font-subtitle {
  font-family: var(--subtitle-font);
}

.fw-light {
  font-weight: 300;
}

.fw-normal {
  font-weight: 400;
}

.fw-medium {
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600;
}

.fw-bold {
  font-weight: 700;
}

.fw-extrabold {
  font-weight: 800;
}

.fs-md {
  font-size: 18px;
}

.fs-xs {
  font-size: 14px;
}

.fs-22 {
  font-size: 22px;
}

.fs-20 {
  font-size: 20px;
}

.fs-16 {
  font-size: 16px;
}

.fs-16 {
  font-size: 18px;
}

.fs-24 {
  font-size: 24px;
}

.fs-26 {
  font-size: 26px;
}

.fs-30 {
  font-size: 30px;
}

.f-18-600 {
  font-size: 18px;
  font-weight: 600;
}

.fs-48 {
  font-size: 48px;
}

.fw-200 {
  font-size: 200px;
  font-weight: 900;
  margin-top: -3rem;
}

/* Large devices */
@media (max-width: 1199px) {
  .fs-24 {
    font-size: 22px;
  }

  .fs-26 {
    font-size: 24px;
  }

  .fs-30 {
    font-size: 26px;
  }

  .fs-48 {
    font-size: 40px;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .fs-24 {
    font-size: 20px;
  }

  .fs-26 {
    font-size: 20px;
  }

  .fs-30 {
    font-size: 24px;
  }

  .fs-48 {
    font-size: 36px;
  }

  .fw-200 {
    font-size: 150px;
    margin-top: -2.3rem;
  }
}
/* Extra small devices */
@media (max-width: 575px) {
  .fs-24 {
    font-size: 16px;
  }

  .fs-26 {
    font-size: 18px;
  }

  .fs-30 {
    font-size: 20px;
  }

  .fs-48 {
    font-size: 26px;
  }

  .fw-200 {
    font-size: 100px;
    margin-top: -1.5rem;
  }
}
/*------------------- 3.7. Background -------------------*/
.bg-theme {
  background-color: var(--theme-color) !important;
}

.bg-smoke {
  background-color: var(--smoke-color) !important;
}

.bg-smokedark {
  background-color: var(--smoke-dark) !important;
}

.bg-white {
  background-color: var(--white-color) !important;
}

.bg-black {
  background-color: var(--black-color) !important;
}

.bg-title {
  background-color: var(--title-color) !important;
}

.bg-dblue {
  background-color: #1ae2f4;
}

.background-image,
[data-bg-src] {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.mask-cover {
  mask-position: center center;
  mask-repeat: no-repeat;
  mask-size: 100%;
}

.bg-fluid {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}

.bg-auto {
  background-size: auto auto;
}

.bg-contain {
  background-size: contain;
}

.bg-top-right {
  background-position: top right;
  background-size: auto;
}

.bg-top-left {
  background-position: left top;
}

.bg-b-left {
  background-position: bottom left;
}

.bg-fixed {
  background-attachment: fixed;
}

/*------------------- 3.8. Text Color -------------------*/
.text-theme {
  color: var(--theme-color) !important;
}

.text-title {
  color: var(--title-color) !important;
}

.text-body {
  color: var(--body-color) !important;
}

.text-light {
  color: var(--light-color) !important;
}

.text-white {
  color: var(--white-color) !important;
}

.text-yellow {
  color: var(--yellow-color) !important;
}

.text-success {
  color: var(--success-color) !important;
}

.text-error {
  color: var(--error-color) !important;
}

.text-inherit {
  color: inherit;
}
.text-inherit:hover {
  color: var(--theme-color);
}

a.text-title:hover {
  color: var(--theme-color) !important;
}

.hover-title a:hover,
a.hover-title:hover {
  color: var(--title-color);
}

a.text-theme:hover,
.text-reset:hover {
  text-decoration: underline;
}

.text-white-light {
  color: #dadada;
}

/*------------------- 3.9. Overlay -------------------*/
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.position-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

[data-overlay] {
  position: relative;
}
[data-overlay] [class^=col-],
[data-overlay] [class*=col-] {
  z-index: 1;
}

[data-overlay]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

[data-overlay=theme]:before {
  background-color: var(--theme-color);
}

[data-overlay=title]:before {
  background-color: var(--title-color);
}

[data-overlay=white]:before {
  background-color: var(--white-color);
}

[data-overlay=black]:before {
  background-color: var(--black-color);
}

[data-opacity="1"]:before {
  opacity: 0.1;
}

[data-opacity="2"]:before {
  opacity: 0.2;
}

[data-opacity="3"]:before {
  opacity: 0.3;
}

[data-opacity="4"]:before {
  opacity: 0.4;
}

[data-opacity="5"]:before {
  opacity: 0.5;
}

[data-opacity="6"]:before {
  opacity: 0.6;
}

[data-opacity="7"]:before {
  opacity: 0.7;
}

[data-opacity="8"]:before {
  opacity: 0.8;
}

[data-opacity="9"]:before {
  opacity: 0.9;
}

[data-opacity="10"]:before {
  opacity: 1;
}

/*------------------- 3.10. Animation -------------------*/
.slideinup.vs-animated {
  --animation-name: slideinup;
}

.slideindown.vs-animated {
  --animation-name: slideindown;
}

.slideindown,
.slideinup {
  opacity: 0;
}

.vs-animated {
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-duration: 1s;
  animation-delay: 0.3s;
  opacity: 1;
  animation-name: var(--animation-name);
}

.ripple-animation, .play-btn.style-1::before, .play-btn.style-1::after {
  animation-duration: var(--ripple-ani-duration);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-name: ripple;
}

.ani-moving-x,
.ani-moving-y,
.ani-moving {
  animation-duration: var(--moving-ani-duration);
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.ani-moving {
  animation-name: moving;
}

.ani-moving-x {
  animation-name: moving-x;
}

.ani-moving-y {
  animation-name: moving-y;
}

.mega-hover {
  position: relative;
  overflow: hidden;
}
.mega-hover img {
  transition: all 2s ease;
  transform: scale(1);
}
.mega-hover:after, .mega-hover:before {
  content: "";
  position: absolute;
  pointer-events: none;
  opacity: 1;
  z-index: 3;
  transform: rotate(5deg);
}
.mega-hover:before {
  top: 0;
  right: 51%;
  bottom: 0;
  left: 50%;
  background: rgba(255, 255, 255, 0.3);
}
.mega-hover:after {
  top: 50%;
  right: 0;
  bottom: 50%;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
}
.mega-hover:hover:before {
  left: 0;
  right: 0;
  opacity: 0;
  transition: all 900ms linear;
}
.mega-hover:hover:after {
  top: 0;
  bottom: 0;
  opacity: 0;
  transition: all 900ms linear;
}
.mega-hover:hover img {
  transform: scale(1.05);
}

.thumb_swap .img_swap {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease 0s;
  background-color: var(--white-color);
}
.thumb_swap:hover .img_swap {
  opacity: 1;
  visibility: visible;
}
.thumb_swap .flash-img,
.thumb_swap .product-img {
  overflow: hidden;
  position: relative;
}

/* Medium devices */
@media (max-width: 991px) {
  .ani-md-none {
    animation: none;
  }
}
@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  30% {
    opacity: 0.4;
  }
  100% {
    transform: scale(1.7);
    opacity: 0;
  }
}
@-webkit-keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  30% {
    opacity: 0.4;
  }
  100% {
    transform: scale(1.7);
    opacity: 0;
  }
}
@keyframes moving {
  0% {
    transform: translate(0px, 0px);
  }
  20% {
    transform: translate(0px, -60px);
  }
  50% {
    transform: translate(-60px, -60px);
  }
  70% {
    transform: translate(-60px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes moving-y {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0, -60px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes moving-x {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(-60px, 0);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
/* pulseBig */
@keyframes pulseBig {
  0% {
    box-shadow: 0 0 0 0 #fff;
  }
  50% {
    box-shadow: 0 0 0 30px rgba(1, 93, 199, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(1, 93, 199, 0);
  }
}
@keyframes float-bob-y {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-25px);
  }
  100% {
    transform: translateY(0px);
  }
}
@-webkit-keyframes float-bob-y {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-25px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes slideinup {
  0% {
    opacity: 0;
    transform: translateY(70px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideindown {
  0% {
    opacity: 0;
    transform: translateY(-70px);
  }
  100% {
    transform: translateY(0);
  }
}
.fa-phone::before {
  content: "\f095";
}

@keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
    transform: translate3d(4px, 0, 0);
  }
}
@-webkit-keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
    transform: translate3d(4px, 0, 0);
  }
}
@keyframes ripple-sm {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  30% {
    opacity: 0.2;
  }
  100% {
    -webkit-transform: scale(1.9);
    transform: scale(1.9);
    opacity: 0;
  }
}
@-webkit-keyframes ripple-sm {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
  30% {
    opacity: 0.2;
  }
  100% {
    -webkit-transform: scale(1.9);
    transform: scale(1.9);
    opacity: 0;
  }
}
/*=================================
    04. Spacing
==================================*/
/*-- Padding Left And Right --*/
.px-5 {
  padding-right: 5px;
  padding-left: 5px;
}

.px-10 {
  padding-right: 10px;
  padding-left: 10px;
}

.px-15 {
  padding-right: 15px;
  padding-left: 15px;
}

.px-20 {
  padding-right: 20px;
  padding-left: 20px;
}

.px-25 {
  padding-right: 25px;
  padding-left: 25px;
}

.px-30 {
  padding-right: 30px;
  padding-left: 30px;
}

.px-35 {
  padding-right: 35px;
  padding-left: 35px;
}

.px-40 {
  padding-right: 40px;
  padding-left: 40px;
}

.px-45 {
  padding-right: 45px;
  padding-left: 45px;
}

.px-50 {
  padding-right: 50px;
  padding-left: 50px;
}

/*-- Padding Top And Bottom --*/
.py-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.py-35 {
  padding-top: 35px;
  padding-bottom: 35px;
}

.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.py-45 {
  padding-top: 45px;
  padding-bottom: 45px;
}

.py-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

/*-- Padding Top --*/
.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

/*-- Padding Bottom --*/
.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

/*-- Padding Left --*/
.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

/*-- Padding Right --*/
.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-45 {
  padding-right: 45px;
}

.pr-50 {
  padding-right: 50px;
}

.pt-80 {
  padding-top: 80px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pt-lg-30 {
  padding-top: 30px;
}

/* Medium devices */
@media (max-width: 991px) {
  .no-pl-md {
    padding-left: 0;
  }

  .pt-lg-30 {
    padding-top: 0;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .no-pt-sm {
    padding-top: 0;
  }
}
/*-- margin Left And Right --*/
.mx-5 {
  margin-right: 5px;
  margin-left: 5px;
}

.mx-10 {
  margin-right: 10px;
  margin-left: 10px;
}

.mx-15 {
  margin-right: 15px;
  margin-left: 15px;
}

.mx-20 {
  margin-right: 20px;
  margin-left: 20px;
}

.mx-25 {
  margin-right: 25px;
  margin-left: 25px;
}

.mx-30 {
  margin-right: 30px;
  margin-left: 30px;
}

.mx-35 {
  margin-right: 35px;
  margin-left: 35px;
}

.mx-40 {
  margin-right: 40px;
  margin-left: 40px;
}

.mx-45 {
  margin-right: 45px;
  margin-left: 45px;
}

.mx-50 {
  margin-right: 50px;
  margin-left: 50px;
}

/*-- margin Top And Bottom --*/
.my-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.my-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.my-35 {
  margin-top: 35px;
  margin-bottom: 35px;
}

.my-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.my-45 {
  margin-top: 45px;
  margin-bottom: 45px;
}

.my-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

/*-- margin Top --*/
.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

/*-- margin Bottom --*/
.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

/*-- margin Left --*/
.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

/*-- margin Right --*/
.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mt-n1 {
  margin-top: -0.25rem;
}

.mt-n2 {
  margin-top: -0.5rem;
}

.mt-n3 {
  margin-top: -1rem;
}

.mt-n4 {
  margin-top: -1.5rem;
}

.mt-n5 {
  margin-top: -3rem;
}

.mb-n1 {
  margin-bottom: -0.25rem;
}

.mb-n2 {
  margin-bottom: -0.5rem;
}

.mb-n3 {
  margin-bottom: -1rem;
}

.mb-n4 {
  margin-bottom: -1.5rem;
}

.mb-n5 {
  margin-bottom: -3rem;
}

.mb-6 {
  margin-bottom: 6px;
}

.mr-135 {
  margin-right: 135px;
}

.mt-70 {
  margin-top: 70px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mt-60 {
  margin-top: 60px;
}

.space-minus-60 {
  --section-space: calc(150px - 60px);
}

.space-plus-60 {
  --section-space: calc(150px + 60px);
}

.space-original {
  --section-space: 150px;
}

.space,
.space-top {
  padding-top: var(--section-space);
}

.space,
.space-bottom {
  padding-bottom: var(--section-space);
}

.space-bottom-double {
  padding-bottom: calc(var(--section-space) + var(--section-space));
}

.space-negative,
.space-negative-top {
  padding-top: calc(var(--section-space) - 50px);
}

.space-negative,
.space-negative-bottom {
  padding-bottom: calc(var(--section-space) - 50px);
}

.full-negative-space {
  margin-top: calc(0px - var(--section-space));
  position: relative;
  z-index: 2;
}

.bottom-minus-60 {
  padding-bottom: 90px;
}

.trb-60 {
  transform: translateY(60px);
}

.top-negative-90 {
  margin-top: -85px;
  padding-top: 85px;
}

.mt-65 {
  margin-top: 65px;
}

/* Medium devices */
@media (max-width: 991px) {
  .space,
.space-top {
    padding-top: var(--section-space-mobile);
  }

  .space,
.space-bottom {
    padding-bottom: var(--section-space-mobile);
  }

  .space-negative,
.space-negative-top {
    padding-top: var(--section-space-mobile);
  }

  .space-negative,
.space-negative-bottom {
    padding-bottom: var(--section-space-mobile);
  }

  .space-top-md-none {
    padding-top: 0;
  }

  .full-negative-space {
    margin-top: calc(0px - var(--section-space-mobile));
  }

  .space-bottom-double {
    padding-bottom: calc(var(--section-space-mobile) + var(--section-space-mobile));
  }

  .space-plus-60 {
    --section-space-mobile: calc(80px + 60px);
  }

  .space-minus-60 {
    --section-space-mobile: calc(80px - 60px);
  }

  .space-original {
    --section-space-mobile: 80px;
  }

  .mb-md-30 {
    margin-bottom: 30px;
  }
}
/*=================================
    05. Template Style
==================================*/
/*------------------- 5.1. Widget  -------------------*/
.widget_nav_menu ul,
.widget_meta ul,
.widget_pages ul,
.widget_archive ul,
.widget_categories ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.widget_nav_menu a,
.widget_meta a,
.widget_pages a,
.widget_archive a,
.widget_categories a {
  display: block;
  color: var(--body-color);
  font-weight: 500;
}
.widget_nav_menu a:hover,
.widget_meta a:hover,
.widget_pages a:hover,
.widget_archive a:hover,
.widget_categories a:hover {
  color: var(--theme-color);
}
.widget_nav_menu li,
.widget_meta li,
.widget_pages li,
.widget_archive li,
.widget_categories li {
  display: block;
  position: relative;
  margin-bottom: 7px;
}
.widget_nav_menu li:last-child,
.widget_meta li:last-child,
.widget_pages li:last-child,
.widget_archive li:last-child,
.widget_categories li:last-child {
  margin-bottom: 0;
}
.widget_nav_menu li > span,
.widget_meta li > span,
.widget_pages li > span,
.widget_archive li > span,
.widget_categories li > span {
  text-align: center;
  position: absolute;
  right: 22px;
  top: 6px;
  transition: all ease 0.4s;
}
.widget_nav_menu li:hover > span,
.widget_meta li:hover > span,
.widget_pages li:hover > span,
.widget_archive li:hover > span,
.widget_categories li:hover > span {
  color: var(--theme-color);
}
.widget_nav_menu .children,
.widget_meta .children,
.widget_pages .children,
.widget_archive .children,
.widget_categories .children {
  margin-left: 10px;
}

.widget_nav_menu a,
.widget_meta a,
.widget_pages a {
  padding-right: 20px;
}

.widget_nav_menu .sub-menu {
  margin-left: 10px;
}

.wp-block-archives {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}
.wp-block-archives a:not(:hover) {
  color: inherit;
}

.vs-blog ul.wp-block-archives li {
  margin: 5px 0;
}

.widget select,
.widget input {
  color: var(--body-color);
  padding-left: 30px;
  font-size: 16px;
  background-color: var(--white-color);
  border: none;
  height: 60px;
  border-radius: 0;
  font-weight: 500;
  width: 100%;
}
.widget select::-moz-placeholder,
.widget input::-moz-placeholder {
  color: var(--body-color);
}
.widget select::-webkit-input-placeholder,
.widget input::-webkit-input-placeholder {
  color: var(--body-color);
}
.widget select:-ms-input-placeholder,
.widget input:-ms-input-placeholder {
  color: var(--body-color);
}
.widget select::placeholder,
.widget input::placeholder {
  color: var(--body-color);
}

.widget_title {
  font-size: 20px;
  line-height: 1em;
  margin-bottom: 30px;
  margin-top: -0.15em;
  padding-bottom: 10px;
  font-weight: 600;
}

.widget .search-form {
  position: relative;
}
.widget .search-form input {
  padding-right: 50px;
  background-color: var(--white-color);
  border-radius: 5px;
}
.widget .search-form button {
  text-align: center;
  padding: 0;
  color: var(--theme-color);
  background-color: transparent;
  border: none;
  position: absolute;
  right: 30px;
  line-height: 1;
  top: 50%;
  transform: translateY(-50%);
}
.widget .search-form button:hover {
  border-color: var(--theme-color);
  background-color: var(--white-color);
  color: var(--theme-color);
}

.sidebar-area .widget {
  --smoke-color: rgba(247, 247, 247, 1);
  margin-bottom: 40px;
  padding: 40px;
  background-color: var(--smoke-color);
}
.sidebar-area .widget.widget_search ul, .sidebar-area .widget.widget_popular_post ul, .sidebar-area .widget.widget_tag_cloud ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.sidebar-area .widget .widget_title {
  color: var(--title-color);
  margin-bottom: 5px;
  padding-bottom: 20px;
  position: relative;
}
.sidebar-area .widget .recent-post {
  padding-bottom: 15px;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 15px;
}
.sidebar-area .widget .recent-post:last-child {
  margin-bottom: 0;
  padding: 0;
  border-bottom: none;
}
.sidebar-area .widget .recent-post .recent-post .media-img {
  margin-right: 20px;
}
.sidebar-area .widget .recent-post .post-title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 9px;
  line-height: 20px;
}

.widget.widget_categories ul {
  margin: 0;
  padding: 0;
}
.widget.widget_categories ul li {
  padding: 19px 25px;
  margin-bottom: 10px;
  display: block;
  position: relative;
  background-color: var(--white-color);
  border-radius: 5px;
  transition: 0.3s;
  transition: 0.3s;
}
.widget.widget_categories ul li:last-child {
  margin-bottom: 0;
}
.widget.widget_categories ul li a {
  font-size: 16px;
  color: var(--body-color);
  font-weight: 400;
}
.widget.widget_categories ul li a:before {
  content: "\f138";
  font-family: var(--icon-font);
  font-weight: 900;
  margin-right: 10px;
  color: var(--theme-color);
}
.widget.widget_categories ul li span {
  position: absolute;
  top: 18px;
  right: 25px;
  color: var(--white-color);
  background-color: var(--theme-color);
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 50%;
  line-height: 30px;
  font-size: 10px;
}
.widget.widget_categories ul li:hover {
  background-color: var(--theme-color);
}
.widget.widget_categories ul li:hover a {
  color: var(--white-color) !important;
}
.widget.widget_categories ul li:hover a:before {
  color: var(--white-color);
}
.widget.widget_categories ul li:hover span {
  color: var(--theme-color);
  background-color: var(--white-color);
}

.wp-block-tag-cloud a,
.tagcloud a {
  display: inline-block;
  border: none;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 1;
  padding: 10.5px 23px;
  margin-right: 5px;
  margin-bottom: 10px;
  color: var(--body-color);
  background-color: var(--smoke-color);
  border-radius: 8px;
}
.wp-block-tag-cloud a:hover,
.tagcloud a:hover {
  background-color: var(--theme-color);
  color: var(--white-color) !important;
}

.tagcloud {
  margin-right: -5px;
  margin-bottom: -10px;
}

.widget .tagcloud a:not(:hover) {
  background-color: var(--white-color);
}

.recent-post {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.recent-post:last-child {
  margin-bottom: 0;
}
.recent-post .post-title {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 5px;
  line-height: 24px;
}
.recent-post .media-img {
  margin-right: 25px;
  width: 90px;
  width: 100px;
  border-radius: 5px;
  overflow: hidden;
}
.recent-post .recent-post-meta {
  line-height: 1;
  margin-bottom: 7px;
}
.recent-post .recent-post-meta a {
  margin-right: 15px;
  font-size: 14px;
  font-weight: 400 !important;
  color: var(--body-color);
}
.recent-post .recent-post-meta a:hover {
  color: var(--theme-color);
}
.recent-post .recent-post-meta a i {
  margin-right: 4px;
  font-size: inherit;
}
.recent-post .recent-post-meta a:last-child {
  margin-right: 0;
}

.vs-video-widget .video-thumb {
  position: relative;
}
.vs-video-widget .play-btn {
  --icon-size: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.vs-video-widget .play-btn i {
  font-size: 16px;
}
.vs-video-widget .video-thumb-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  margin-top: 10px;
  margin-bottom: -0.3em;
}

.sidebar-gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.sidebar-gallery .gallery-thumb .icon-thumb {
  font-size: 30px;
}

/* Large devices */
@media (max-width: 1199px) {
  .sidebar-area .widget {
    margin-bottom: 30px;
    padding: 30px;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .sidebar-gallery {
    grid-template-columns: repeat(3, 1fr);
  }

  .wp-block-tag-cloud a,
.tagcloud a {
    padding: 10.5px 18px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .sidebar-area .widget {
    padding: 20px;
  }
}
.footer-widget,
.footer-widget .widget {
  padding: 0;
  border: none;
  padding-bottom: 0;
  background-color: transparent;
}
.footer-widget p {
  color: var(--white-color);
  line-height: 1.6;
}
.footer-widget .widget_title {
  font-size: 24px;
  color: var(--white-color);
  position: relative;
  padding: 0;
  margin-bottom: 32px;
}
.footer-widget .footer-table {
  margin-top: -7px;
}
.footer-widget .menu {
  margin-top: -7px;
}
.footer-widget .vs-social a {
  background-color: rgba(39, 71, 125, 0.3);
  margin-right: 8px;
}
.footer-widget .vs-social a:hover {
  background-color: var(--theme-color);
}
.footer-widget.widget_nav_menu a, .footer-widget.widget_meta a, .footer-widget.widget_pages a, .footer-widget.widget_archive a, .footer-widget.widget_categories a, .footer-widget a {
  color: var(--white-color);
  font-weight: 600;
}
.footer-widget.widget_nav_menu a:hover, .footer-widget.widget_meta a:hover, .footer-widget.widget_pages a:hover, .footer-widget.widget_archive a:hover, .footer-widget.widget_categories a:hover, .footer-widget a:hover {
  color: var(--theme-color);
}
.footer-widget.widget_nav_menu li a, .footer-widget.widget_meta li a, .footer-widget.widget_pages li a, .footer-widget.widget_archive li a, .footer-widget.widget_categories li a, .footer-widget li a {
  font-weight: 500;
}
.footer-widget.widget_nav_menu li a:before, .footer-widget.widget_meta li a:before, .footer-widget.widget_pages li a:before, .footer-widget.widget_archive li a:before, .footer-widget.widget_categories li a:before, .footer-widget li a:before {
  content: "\f30b";
  font-family: var(--icon-font);
  margin-right: 10px;
}
.footer-widget.widget_nav_menu li a:hover, .footer-widget.widget_meta li a:hover, .footer-widget.widget_pages li a:hover, .footer-widget.widget_archive li a:hover, .footer-widget.widget_categories li a:hover, .footer-widget li a:hover {
  transform: translateX(5px);
}
.footer-widget .vs-gallery-widget {
  max-width: 340px;
}
.footer-widget .footer-location {
  font-weight: 600;
}
.footer-widget .footer-location i {
  width: 25px;
  line-height: 1.6;
  float: left;
  display: block;
  overflow: hidden;
  height: 50px;
  padding-left: 2px;
}
.footer-widget .menu-two-collumn ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
.footer-widget .menu-two-collumn ul li {
  margin-bottom: 0;
}

.footer-table {
  font-weight: 600;
  color: var(--white-color);
}
.footer-table tr td {
  border-bottom: 1px solid;
  border-color: var(--border-white);
  border-top: none;
  padding-top: 10px;
  padding-bottom: 10px;
}
.footer-table tr td:nth-child(2) {
  text-align: right;
}
.footer-table tr:last-child td {
  padding-bottom: 10px !important;
}

.footer-widget.style-2 .widget_title {
  font-size: 30px;
  margin-bottom: 35px;
  color: var(--title-color);
}
.footer-widget.style-2 p {
  color: var(--body-color);
  font-size: 18px;
  font-weight: 400;
  margin-top: -7px;
}
.footer-widget.style-2 .widget_nav_menu a, .footer-widget.style-2 .widget_meta a, .footer-widget.style-2 .widget_pages a, .footer-widget.style-2 .widget_archive a, .footer-widget.style-2 .widget_categories a, .footer-widget.style-2 a {
  color: var(--body-color);
  font-weight: 600 !important;
}
.footer-widget.style-2 .widget_nav_menu a:hover, .footer-widget.style-2 .widget_meta a:hover, .footer-widget.style-2 .widget_pages a:hover, .footer-widget.style-2 .widget_archive a:hover, .footer-widget.style-2 .widget_categories a:hover, .footer-widget.style-2 a:hover {
  color: var(--theme-color);
}
.footer-widget.style-2 .post-title {
  margin-bottom: 0;
}
.footer-widget.style-2 .post-title a {
  font-weight: inherit !important;
  color: inherit;
}
.footer-widget.style-2 .post-title a:hover {
  color: var(--theme-color);
}
.footer-widget.style-2 .vs-social a {
  background-color: transparent;
}
.footer-widget.style-2 .vs-social a:hover {
  background-color: var(--theme-color);
}

/* Large devices */
@media (max-width: 1199px) {
  .widget-area.space-negative, .widget-area.space-negative-bottom {
    padding-bottom: calc(var(--section-space) - 90px);
  }

  .footer-widget, .footer-widget .widget {
    margin-bottom: 40px;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .widget-area.space-negative, .widget-area.space-negative-bottom {
    padding-bottom: calc(var(--section-space-mobile) - 40px);
  }

  .footer-widget .widget_title {
    margin-bottom: 25px;
  }
}
/* Extra small devices */
@media (max-width: 575px) {
  .footer-widget.style-2 p {
    font-size: 16px;
  }
}
.widget_shopping_cart .widget_title {
  margin-bottom: 10px;
}
.widget_shopping_cart ul {
  margin: 0;
  padding: 0;
}
.widget_shopping_cart ul li {
  list-style-type: none;
}
.widget_shopping_cart .mini_cart_item {
  position: relative;
  padding: 30px 10px 30px 90px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
  text-align: left;
}
.widget_shopping_cart .mini_cart_item:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.widget_shopping_cart .cart_list a:not(.remove) {
  display: block;
  color: var(--body-color);
  font-size: 16px;
  font-weight: 500;
  font-family: var(--title-font);
  font-weight: 600;
  color: var(--title-color);
}
.widget_shopping_cart .cart_list a:not(.remove):hover {
  color: var(--theme-color);
}
.widget_shopping_cart .cart_list a.remove {
  position: absolute;
  top: 50%;
  left: 95%;
  transform: translateY(-50%);
  color: var(--body-color);
}
.widget_shopping_cart .cart_list a.remove:hover {
  color: var(--theme-color);
}
.widget_shopping_cart .cart_list img {
  width: 75px;
  height: 75px;
  position: absolute;
  left: 0;
  top: 20px;
  border: 1px solid var(--border-color);
}
.widget_shopping_cart .quantity {
  display: inline-flex;
  white-space: nowrap;
  vertical-align: top;
  margin-right: 20px;
  font-size: 14px;
  color: var(--theme-color);
}
.widget_shopping_cart .total {
  margin-top: 20px;
  font-size: 18px;
  color: var(--title-color);
}
.widget_shopping_cart .amount {
  padding-left: 5px;
  font-weight: 600;
  font-family: var(--title-font);
}
.widget_shopping_cart .vs-btn {
  margin-right: 15px;
}
.widget_shopping_cart .vs-btn:last-child {
  margin-right: 0;
}

.sidebar-area .widget_shopping_cart .vs-btn {
  margin-right: 10px;
  padding: 8px 22px;
  font-size: 14px;
}

/*------------------- 5.2. Header  -------------------*/
.header-wrapper {
  position: relative;
  z-index: 2;
}

.tr-20::before {
  content: "";
  height: 20px;
  width: 100%;
  background-color: var(--theme-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.header-top-area {
  background-color: var(--theme-color);
  color: var(--white-color);
  padding: 16px 0 16px 0;
  position: relative;
  z-index: 3;
}
.header-top-area a {
  color: var(--white-color);
}
.header-top-area a:hover {
  color: var(--title-color);
}
.header-top-area .dropdown-menu {
  top: 18px !important;
}
.header-top-area .dropdown-menu:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 12px 12px 12px;
  border-color: transparent transparent var(--white-color) transparent;
  position: absolute;
  top: -12px;
  left: 65px;
}
.header-top-area .dropdown-menu li a {
  color: var(--title-color);
}
.header-top-area .dropdown-menu li a:hover {
  color: var(--theme-color);
}
.header-top-area span {
  line-height: 1;
}
.header-top-area.style2, .header-top-area.icon-btn.style5, .header-top-area.style3 {
  padding: 16px 0;
}
.header-top-area.style3, .header-top-area.icon-btn.style5 {
  background-color: var(--smoke-color);
}

.header-top-shape {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--theme-color);
  height: 100%;
  width: 43.5%;
  z-index: -1;
  clip-path: polygon(0px 0px, 100% 0%, 96% 100%, 0% 100%);
}

.head-top-widget {
  display: inline-block;
}

.radio-icon {
  height: 12px;
  width: 12px;
  display: inline-block;
  background-color: var(--white-color);
  border: 1px solid var(--white-color);
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px var(--theme-color);
}

.border-right {
  border-right: 1px solid #1ae2f4;
}

.vs-social-group a {
  margin-left: 25px;
}

.menu-icons {
  display: flex;
}

.cart-button {
  border: none;
}

.cart-btn-wrapper {
  position: relative;
}

.shopping-cart-wrapper {
  position: absolute;
  top: calc(100% + 27px);
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  min-width: 340px;
  text-align: left;
  visibility: hidden;
  opacity: 0;
  z-index: 10;
}
.shopping-cart-wrapper:before {
  content: "";
  height: 0;
  width: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent var(--white-color) transparent;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.shopping-cart-wrapper.show {
  visibility: visible;
  opacity: 1;
}

.woocommerce-mini-cart__buttons {
  margin-bottom: 0;
}

.logo-wrapper {
  background-color: var(--white-color);
  text-align: center;
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
  position: relative;
  height: 100%;
  flex-direction: column;
}
.logo-wrapper .support-link {
  font-size: 14px;
  background-color: var(--theme-overlay);
  padding: 7px 33px;
  border-radius: 0 0 25px 25px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.logo-wrapper .header-logo {
  padding-top: 40px;
}

.header-contact {
  background-color: #fff;
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
  padding: 50px 0;
  position: relative;
  z-index: 2;
}

.dropdown-toggle::after {
  content: "\f107";
  font-family: var(--icon-font);
  border: none;
  vertical-align: 0;
  font-size: 14px;
}

.header-shape {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
}
.header-shape .before, .header-shape .after {
  width: 80px;
  height: 80px;
  background-color: var(--theme-overlay);
  position: absolute;
  border-radius: 50%;
  top: -35px;
  left: -35px;
  overflow: hidden;
  border: 1px solid var(--theme-overlay);
  box-shadow: inset 0 0 0 10px var(--white-color);
}
.header-shape .after {
  bottom: -35px;
  right: -35px;
  left: unset;
  top: unset;
}
.header-shape.style-2 .before {
  left: -45px;
  top: -45px;
}
.header-shape.style-2 .after {
  bottom: -45px;
  right: -45px;
}

.contact-box {
  display: flex;
  align-items: center;
}
.contact-box .icon-box {
  margin-right: 14px;
  float: left;
  color: var(--theme-color);
  font-size: var(--icon-size, 36px);
}
.contact-box .contact-content {
  overflow: hidden;
}
.contact-box .contact-content p {
  color: var(--white-color);
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 3px;
}
.contact-box .contact-content .contact-title {
  font-weight: 500;
  margin-bottom: 0;
  /* Medium Large devices */
}
.contact-box .contact-content .contact-title a {
  color: inherit;
}
.contact-box .contact-content .contact-title a:hover {
  color: var(--theme-color);
}
@media (max-width: 1399px) {
  .contact-box .contact-content .contact-title {
    font-size: 16px;
  }
}
.contact-box .contact-content .text-light {
  font-size: 14px;
  margin-bottom: 4px;
  display: block;
}

.mr-6 {
  margin-right: 6px;
}

.main-menu ul {
  padding-left: 0;
  margin-bottom: 0;
}
.main-menu ul li {
  display: inline-block;
  list-style-type: none;
  margin-right: 32px;
  position: relative;
}
.main-menu ul li a {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  color: var(--title-color);
  padding: 20px 0;
}
.main-menu ul li.menu-item-has-children > a::after {
  content: "\f107";
  position: relative;
  font-family: var(--icon-font);
  margin-left: 5px;
  top: 1px;
  font-size: 1rem;
  font-weight: 500;
}
.main-menu ul li:hover > ul.sub-menu,
.main-menu ul li:hover > ul.mega-menu {
  visibility: visible;
  opacity: 1;
  margin-top: 0;
  z-index: 20;
}
.main-menu ul li:hover > a {
  color: var(--theme-color);
}
.main-menu ul.sub-menu, .main-menu ul.mega-menu {
  position: absolute;
  text-align: left;
  top: 100%;
  left: 0;
  background-color: var(--white-color);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  visibility: hidden;
  min-width: 190px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 7px;
  margin-top: 50px;
  opacity: 0;
  z-index: -1;
  border-bottom: 3px solid var(--theme-color);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.09), 0px 3px 0px 0px rgba(231, 13, 60, 0.004);
  -webkit-transform-origin: top center;
  -ms-transform-origin: top center;
  transform-origin: top center;
  -webkit-transition: margin-top 0.4s ease-in-out 0s, visibility 0.4s ease-in-out 0s, opacity 0.4s ease-in-out 0s, z-index 0s;
  transition: margin-top 0.4s ease-in-out 0s, visibility 0.4s ease-in-out 0s, opacity 0.4s ease-in-out 0s, z-index 0s;
}
.main-menu ul.sub-menu li, .main-menu ul.mega-menu li {
  display: block;
}
.main-menu ul.sub-menu li a, .main-menu ul.mega-menu li a {
  position: relative;
  padding: 0;
  line-height: 28px;
}
.main-menu ul.sub-menu {
  padding: 18px 15px;
}
.main-menu ul.sub-menu::before {
  content: "";
  position: absolute;
  left: 29px;
  top: 30px;
  width: 1px;
  background-color: #ededed;
  height: calc(100% - 60px);
}
.main-menu ul.sub-menu li {
  padding: 3px 9px;
}
.main-menu ul.sub-menu li a {
  position: relative;
  padding-left: 20px;
}
.main-menu ul.sub-menu li a:before {
  content: "\f111";
  position: absolute;
  top: 2.8em;
  left: 0;
  font-family: var(--icon-font);
  width: 11px;
  height: 11px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  font-size: 0.2em;
  line-height: 12px;
  color: var(--theme-color);
  font-weight: 700;
  background-color: var(--white-color);
  box-shadow: inset 0px 2px 4px 0px rgba(255, 51, 51, 0.4);
}
.main-menu ul.mega-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  max-width: var(--main-container);
  padding: 20px 15px 23px 15px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.main-menu ul.mega-menu > li {
  display: block;
  width: 100%;
  padding: 0 15px;
}
.main-menu ul.mega-menu > li > a {
  display: block;
  padding: 0;
  font-size: 16px;
  padding-bottom: 8px;
  margin-bottom: 10px;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-weight: 700;
  color: var(--title-color);
  border-bottom: 1px dashed;
  border-color: var(--theme-color);
}
.main-menu ul.mega-menu > li li {
  padding: 4px 0;
}
.main-menu.menu-style2 > ul > li {
  margin-right: 32px;
}
.main-menu.menu-style2 > ul > li > a {
  padding: 25px 0;
}
.main-menu .mega-menu-wrap {
  position: static;
}

.top-widget .icon {
  color: var(--title-color);
  font-size: 14px;
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.07);
  background-color: var(--white-color);
  width: 40px;
  height: 40px;
  line-height: 44px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  position: relative;
}
.top-widget .icon:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}
.top-widget .icon:hover .badge {
  background-color: var(--theme-color);
}
.top-widget .badge {
  color: var(--white-color);
  font-size: 10px;
  font-weight: 300;
  background-color: var(--title-color);
  width: fit-content;
  padding: 0.35em 0.46em;
  line-height: 8px;
  top: 0;
  right: 0;
}

.sticky-header-wrap {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  transition: all ease 0.4s;
  transform: translateY(-110%);
  transform-origin: top;
  opacity: 0;
  visibility: hidden;
}
.sticky-header-wrap.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.sticky-header-wrap .logo {
  width: 120px;
  /* Medium devices */
}
@media (max-width: 991px) {
  .sticky-header-wrap .logo {
    transform: translateY(0);
  }
}

.menu-sticky1 > ul > li {
  margin: 0;
  margin-right: 0;
  margin-left: 34px;
}
.menu-sticky1 > ul > li > a {
  padding: 27px 0;
}

.header-wrapper2 .logo-wrapper {
  text-align: left;
  background-color: transparent;
  box-shadow: none;
}
.header-wrapper2 .logo-wrapper .header-logo {
  padding-top: 0;
}
.header-wrapper2 .header-contact {
  background-color: transparent;
  box-shadow: none;
  padding: 40px 0;
}
.header-wrapper2 .menu-wrapper {
  padding: 0 60px;
  box-shadow: 0px 15px 100px 0px rgba(0, 0, 0, 0.08);
  position: relative;
}
.header-wrapper2 .btn-fhw {
  padding: 30px 33px;
}

.head-top-widget2.style3 span, .head-top-widget2.icon-btn.style5 span,
.head-top-widget2.style3 a,
.head-top-widget2.icon-btn.style5 a {
  color: var(--body-color);
}
.head-top-widget2.style3 a:hover, .head-top-widget2.icon-btn.style5 a:hover {
  color: var(--theme-color);
}
.head-top-widget2.style3 .border-right, .head-top-widget2.icon-btn.style5 .border-right {
  border-right: 1px solid #ccc;
}

.menu-wrapper3 {
  height: 130px;
}

.main-menu.menu-style3 > ul > li > a {
  padding: 51px 0;
}

.icon-box .fa-phone-volume {
  transform: rotate(-45deg) translate(7px, 8px);
  margin-right: 4px;
}

.header-absolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.main-menu.link-white > ul > li > a {
  color: var(--white-color);
}
.main-menu.link-white > ul > li > a:hover {
  color: var(--theme-color);
}

.head-top-widget2.style4 span,
.head-top-widget2.style4 a {
  color: var(--white-color);
}
.head-top-widget2.style4 a:hover {
  color: var(--theme-color);
}
.head-top-widget2.style4 .border-right {
  border-right: 1px solid #ccc;
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .header-contact .px-45 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .header-wrapper2 .header-contact {
    padding-left: 40px;
  }

  .shopping-cart-wrapper {
    left: unset;
    right: 0;
    transform: translateX(0%);
  }
  .shopping-cart-wrapper:before {
    bottom: 100%;
    left: calc(100% - 35px);
    transform: translateX(0);
  }
}
/* Large devices */
@media (max-width: 1199px) {
  .header-logo {
    max-width: 80%;
    margin: 0 auto;
  }

  .header-wrapper2 .header-logo {
    margin: 0;
  }

  .logo-wrapper .support-link {
    width: 100%;
  }

  .main-menu ul.mega-menu {
    max-width: calc(var(--main-container) - var(--container-gutters));
    width: calc(100% - var(--container-gutters));
  }

  .header-wrapper2 .menu-wrapper {
    padding: 0 40px;
  }

  .main-menu.menu-style2 > ul > li {
    margin-right: 20px;
  }

  .main-menu.menu-style2 ul.mega-menu {
    max-width: 100%;
    width: 100%;
  }
  .main-menu.menu-style2 ul.mega-menu li {
    margin-right: 10px;
  }

  .main-menu.menu-style3 > ul > li:last-child {
    margin-right: 0;
  }

  .logo-wrapper3 .header-logo {
    width: 100%;
    max-width: 100%;
  }

  .header-top-shape {
    width: 50%;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .header-wrapper {
    background-color: var(--white-color);
    box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
  }
  .header-wrapper .row {
    align-items: center;
  }
  .header-wrapper.header-wrapper4 {
    background-color: transparent;
  }

  .tr-20::before {
    display: none;
  }

  .header-logo {
    max-width: 170px;
    padding: 10px 0;
  }

  .top-widget {
    position: absolute;
    right: 100px;
    top: 25px;
  }

  .top-widget.style-2 {
    top: -64px;
  }

  .menu-wrapper3 {
    height: 100px;
  }

  .shopping-cart-wrapper {
    top: calc(100% + 35px);
  }
}
/* Small devices */
@media (max-width: 767px) {
  .header-logo {
    margin: 0;
  }
}
/* Extra small devices */
@media (max-width: 575px) {
  .header-logo {
    margin: 0;
  }

  .d-xs-none {
    display: none;
  }
}
/*------------------- 5.3. Footer  -------------------*/
.footer-table td {
  padding: 4px 0;
  text-transform: uppercase;
}
.footer-table tr:first-child td {
  padding-top: 0;
}
.footer-table tr:last-child td {
  padding-bottom: 0;
}

.copyright-wrap {
  padding: 22px 40px;
}

.footer-wrapper2.bg-smoke {
  --smoke-color: rgb(247, 247, 247);
}

.copyright-wrap.bg-smoke.style-2 {
  --smoke-color: rgb(238, 238, 238);
}
.copyright-wrap.bg-smoke.style-2 a {
  font-weight: 600;
}

.footer-subscribe {
  position: relative;
}
.footer-subscribe input {
  width: 100%;
  background-color: transparent;
  border: 2px solid var(--white-color);
  padding-left: 30px;
  padding-right: 50px;
  border-radius: 9999px;
  color: var(--white-color);
  font-size: 16px;
  font-weight: 500;
}
.footer-subscribe input::-moz-placeholder {
  color: var(--white-color);
}
.footer-subscribe input::-webkit-input-placeholder {
  color: var(--white-color);
}
.footer-subscribe input:-ms-input-placeholder {
  color: var(--white-color);
}
.footer-subscribe input::placeholder {
  color: var(--white-color);
}
.footer-subscribe input:focus {
  background-color: transparent;
}
.footer-subscribe button {
  border: none;
  background-color: transparent;
  color: var(--white-color);
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  border-left: 1px solid var(--white-color);
  padding: 0 0 0 20px;
  height: 30px;
  overflow: hidden;
}
.footer-subscribe button .hidden {
  position: absolute;
  margin-left: 100px;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.4s;
}
.footer-subscribe button .default {
  opacity: 1;
  visibility: visible;
  transition: all ease 0.3s;
}
.footer-subscribe.focus {
  margin-right: 0;
}
.footer-subscribe.focus button .hidden {
  margin-left: 0;
  opacity: 1;
  visibility: visible;
}
.footer-subscribe.focus button .default {
  opacity: 0;
  visibility: hidden;
}

.footer-links ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.footer-links li {
  position: relative;
  display: inline-block;
  margin-left: 24px;
}
.footer-links li:last-child {
  margin-right: 0;
  padding-right: 0;
}
.footer-links a {
  color: var(--white-color);
  font-weight: 500;
}
.footer-links a:hover {
  text-decoration: underline;
}

/* Medium devices */
@media (max-width: 991px) {
  .footer-wrapper.space-negative, .space-negative-top {
    padding-top: var(--section-space-mobile) !important;
  }

  .recent-post .post-title {
    font-size: 16px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .footer-links li {
    margin-left: 15px;
  }
}
/* Extra small devices */
@media (max-width: 575px) {
  .footer-subscribe input {
    padding-left: 25px;
  }
  .footer-subscribe button {
    width: 45px;
    height: 45px;
    background-color: var(--theme-color);
    color: var(--white-color);
    text-align: center;
    padding-left: 0;
    border: none;
    border-radius: 50%;
    right: 8px;
  }
  .footer-subscribe button:hover {
    background-color: var(--white-color);
    color: var(--white-color);
  }
}
/*------------------- 5.4. Breadcumb  -------------------*/
.breadcumb-wrapper {
  padding-top: 190px;
  padding-bottom: 190px;
  background-color: var(--black-color);
}

.breadcumb-title {
  font-size: 60px;
  font-weight: 600;
  margin-top: 0;
  color: var(--white-color);
  margin-bottom: 0;
  max-width: 850px;
  line-height: 1.15;
}

.breadcumb-menu {
  max-width: 100%;
  margin: 0 0 0 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}
.breadcumb-menu li {
  display: inline-block;
  margin-right: 3px;
  padding-right: 8px;
  list-style: none;
  position: relative;
}
.breadcumb-menu li:after {
  content: "/";
  margin-left: 13px;
}
.breadcumb-menu li:last-child {
  padding-right: 0;
  margin-right: 0;
}
.breadcumb-menu li:last-child:after {
  display: none;
}
.breadcumb-menu li,
.breadcumb-menu a,
.breadcumb-menu span {
  white-space: normal;
  color: inherit;
  word-break: break-word;
  font-weight: 500;
  font-size: 18px;
  color: var(--white-color);
}
.breadcumb-menu li i,
.breadcumb-menu a i,
.breadcumb-menu span i {
  margin-right: 5px;
  font-size: 0.8rem;
  position: relative;
  top: -1px;
}
.breadcumb-menu li a:hover,
.breadcumb-menu a a:hover,
.breadcumb-menu span a:hover {
  color: var(--theme-color);
}

/* Extra large devices */
@media (max-width: 1500px) {
  .breadcumb-title {
    font-size: 60px;
  }
}
/* Medium Large devices */
@media (max-width: 1399px) {
  .breadcumb-title {
    font-size: 48px;
  }
}
/* Large devices */
@media (max-width: 1199px) {
  .breadcumb-wrapper {
    padding-top: 130px;
    padding-bottom: 130px;
  }

  .breadcumb-title {
    font-size: 42px;
    max-width: 650px;
  }

  .breadcumb-menu li,
.breadcumb-menu a,
.breadcumb-menu span {
    font-size: 17px;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .breadcumb-wrapper {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .breadcumb-menu li {
    padding-right: 5px;
    --icon-size: auto;
  }
  .breadcumb-menu li:first-child:before {
    background-color: transparent;
    font-size: 14px;
    margin-right: 10px;
  }

  .breadcumb-menu li:after {
    margin-left: 11px;
  }

  .breadcumb-title {
    font-size: 36px;
    max-width: 450px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .breadcumb-wrapper {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .breadcumb-title {
    font-size: 28px;
    max-width: 300px;
  }
}
/* Extra small devices */
@media (max-width: 575px) {
  .breadcumb-title {
    margin-bottom: 20px;
  }
}
/*------------------- 5.5. Blog  -------------------*/
.vs-blog {
  background: var(--white-color);
  transition: 0.3s;
}
.vs-blog .vs-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.vs-blog .blog-audio {
  line-height: 0;
}
.vs-blog .blog-img {
  position: relative;
}

.blog-big {
  --border-color: rgb(245, 245, 245);
}
.blog-big .blog-title {
  font-size: 30px;
  line-height: 1.3;
}
.blog-big .blog-content {
  padding: 50px 60px 50px 60px;
  border: 2px solid var(--border-color);
  border-top: none;
}
.blog-big .blog-meta a {
  color: var(--body-color);
  margin-right: 24px;
  position: relative;
  font-size: 14px;
}
.blog-big .blog-meta a > i {
  margin-right: 7px;
  color: var(--theme-color);
}
.blog-big .blog-meta a:hover {
  color: var(--theme-color);
}
.blog-big .read-btn {
  font-weight: 500;
  font-size: 18px;
  position: relative;
}
.blog-big .read-btn:after {
  content: "";
  height: 1px;
  width: 0;
  background-color: var(--title-color);
  position: absolute;
  bottom: 0;
  left: 0;
  transition: 0.4s ease-in-out;
}
.blog-big .read-btn i {
  transition: 0.3s;
}
.blog-big .read-btn:hover:after {
  width: calc(100% - 20px);
}
.blog-big .read-btn:hover i {
  margin-left: 2px;
}

blockquote {
  --smoke-color: rgba(246, 246, 246, 1);
  font-size: 20px;
  line-height: 30px;
  padding: 50px 60px 50px 60px;
  display: block;
  position: relative;
  text-align: center;
  background-color: var(--smoke-color);
  overflow: hidden;
  margin: 35px 0;
  color: var(--title-color);
}
blockquote:before {
  content: "\f10e";
  font-family: var(--icon-font);
  position: absolute;
  right: 40px;
  bottom: 30px;
  font-size: 11rem;
  font-weight: 600;
  line-height: 1;
  color: var(--white-color);
}
blockquote p {
  font-family: inherit;
  margin-bottom: 0 !important;
  color: inherit;
  line-height: inherit;
  width: 100%;
  position: relative;
  max-width: 590px;
  margin: 0 auto;
  z-index: 3;
  font-weight: 500;
}
blockquote cite {
  display: inline-block;
  font-size: 18px;
  position: relative;
  border-color: inherit;
  line-height: 1;
  font-weight: 500;
  margin-top: 22px;
  font-style: normal;
  color: var(--theme-color);
}

.blog-single {
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 28px;
}
.blog-single .blog-content {
  padding-top: 35px;
}
.blog-single .share-links {
  border-top: 1px solid var(--border-color);
  padding-top: 28px;
  margin-top: 25px;
}
.blog-single .blog-meta a {
  color: var(--body-color);
  margin-right: 24px;
  position: relative;
  font-size: 14px;
}
.blog-single .blog-meta a > i {
  margin-right: 7px;
  color: var(--theme-color);
}
.blog-single .blog-meta a:hover {
  color: var(--theme-color);
}

.share-links-title {
  font-size: 18px;
  font-weight: 600;
  color: var(--title-color);
  margin-right: 20px;
}

.blog-social {
  --icon-size: 34px;
  --facebook: #3b5998;
  --twitter: #00acee;
  --linkedin: #0e76a8;
  --instagram: #d63084;
  padding-left: 0;
  margin: 0;
}
.blog-social li {
  display: inline-block;
  list-style-type: none;
}
.blog-social li:last-child i {
  margin-right: 0;
}
.blog-social a {
  display: inline-block;
}
.blog-social i {
  width: var(--icon-size);
  height: var(--icon-size);
  line-height: var(--icon-size);
  background-color: var(--theme-color);
  color: var(--white-color);
  text-align: center;
  border-radius: 50%;
  margin: 0 3px;
}
.blog-social i[class*=facebook] {
  background-color: var(--facebook);
}
.blog-social i[class*=twitter] {
  background-color: var(--twitter);
}
.blog-social i[class*=linkedin] {
  background-color: var(--linkedin);
}
.blog-social i[class*=instagram] {
  background-color: var(--instagram);
}
.blog-social i:hover {
  background-color: var(--title-color);
}

.blog-details .blog-author {
  margin-top: 80px;
  padding: 55px 60px;
  border-right: 10px solid var(--theme-color);
}
.blog-details .blog-author .author-text {
  line-height: 26px;
}

.blog-card {
  position: relative;
}
.blog-card .blog-date {
  font-size: 12px;
  line-height: 14px;
  padding: 12px 10px 19px 10px;
  border-radius: 0 0 5px 5px;
  color: var(--title-color);
  position: absolute;
  top: 30px;
  right: 30px;
  display: inline-block;
  background-color: var(--white-color);
  width: 58px;
  border-top: 2px solid var(--theme-color);
  text-align: center;
}
.blog-card .blog-date::first-line {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
}
.blog-card .blog-date:hover {
  color: var(--theme-color);
}
.blog-card .blog-content {
  padding: 40px;
  background-color: white;
  border: 2px solid whitesmoke;
  border-top: none;
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.04);
  transition: 0.4s;
}
.blog-card .blog-category {
  color: var(--body-color);
  font-size: 14px;
  margin-bottom: 5px;
}
.blog-card .blog-category i {
  margin-right: 4px;
}
.blog-card .blog-category a {
  color: inherit;
  font-size: inherit;
}
.blog-card .blog-category a:hover {
  color: var(--theme-color);
}
.blog-card .blog-title {
  padding-right: 10px;
  margin-bottom: 18px;
  line-height: 1.4;
}
.blog-card .blog-author {
  display: flex;
  align-items: center;
}
.blog-card .blog-author .author-img {
  width: 45px;
  height: 45px;
  border-radius: 5px;
  overflow: hidden;
  float: left;
  margin-right: 15px;
}
.blog-card .blog-author .author-info span {
  font-size: 14px;
  line-height: 1;
}
.blog-card .blog-author .author-info .author-name {
  margin-bottom: 0;
}
.blog-card:hover .blog-content {
  border-color: var(--white-color);
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.06);
}

.img-scale {
  overflow: hidden;
}
.img-scale img {
  transition: 0.4s;
}
.img-scale:hover img {
  transform: scale(1.14);
}

#bbox1 .slick-list.draggable {
  margin-bottom: -50px;
  padding-bottom: 50px;
}

.vs-pagination {
  text-align: center;
}
.vs-pagination ul {
  margin: 0;
  padding: 0;
}
.vs-pagination li {
  display: inline-block;
  margin: 0 5px;
  list-style-type: none;
}
.vs-pagination li:first-child {
  margin-left: 0;
}
.vs-pagination span,
.vs-pagination a {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 48px;
  color: var(--body-color);
  text-align: center;
  position: relative;
  border: 2px solid #f2f2f2;
  z-index: 1;
  font-weight: 700;
  border-radius: 10px;
}
.vs-pagination span.active, .vs-pagination span:hover,
.vs-pagination a.active,
.vs-pagination a:hover {
  color: var(--white-color);
  background-color: var(--theme-color);
  border-color: transparent;
}

/* Large devices */
@media (max-width: 1199px) {
  .vs-blog-wrapper .gx-60, .vs-blog-wrapper .g-60 {
    --bs-gutter-x: 30px;
  }

  .blog-card .blog-content {
    padding: 20px;
  }

  .blog-big .blog-title {
    font-size: 24px;
  }
  .blog-big .blog-content {
    padding: 40px;
  }

  blockquote {
    padding: 40px;
    font-size: 18px;
  }

  .blog-details .blog-author {
    padding: 30px;
  }

  .tags-margin {
    margin-bottom: 30px;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .blog-big .blog-title {
    font-size: 22px;
  }

  .tags-margin {
    margin-bottom: 0;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .blog-big .blog-title {
    font-size: 20px;
  }
  .blog-big .blog-content {
    padding: 25px 20px;
  }

  .blog-details .blog-author {
    margin-top: 40px;
    padding: 20px;
  }

  blockquote {
    padding: 30px;
  }

  blockquote::before {
    font-size: 5rem;
  }

  .tags-margin {
    margin-bottom: 30px;
  }
}
/*------------------- 5.6. Comments  -------------------*/
.blog-details .vs-comments-layout1 {
  margin-top: 70px;
}

ul.comment-list,
.children {
  list-style: none;
  margin: 0;
  padding: 0;
}

.vs-post-comment {
  --border-color: rgba(0,0,0,0.05);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 35px 35px 30px 35px;
  border: 1px solid var(--border-color);
  margin-bottom: 30px;
  transition: 0.3s;
}
.vs-post-comment .author-img {
  width: 90px;
  height: 90px;
  margin-right: 30px;
  overflow: hidden;
}
.vs-post-comment .comment-content {
  width: calc(100% - 120px);
  position: relative;
  line-height: 1;
}
.vs-post-comment .name {
  margin-bottom: 8px;
  margin-top: -5px;
}
.vs-post-comment .commented-on {
  font-size: 14px;
  display: block;
  font-weight: 400;
  color: var(--theme-color);
  margin-bottom: 16px;
}
.vs-post-comment .reply_and_edit,
.vs-post-comment .rating {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
}
.vs-post-comment .reply-btn {
  background-color: var(--smoke-color);
  color: var(--title-color);
  min-width: 112px;
  padding: 0 20px;
  height: 40px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  border-radius: 50px;
  font-weight: 600;
  font-size: 14px;
}
.vs-post-comment .reply-btn:before {
  content: "\f3e5";
  font-family: var(--icon-font);
  font-weight: 400;
  margin-right: 5px;
}
.vs-post-comment p {
  margin-bottom: 0;
}
.vs-post-comment:hover {
  background-color: var(--smoke-color);
  border-color: var(--smoke-color);
}
.vs-post-comment:hover .reply-btn {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.vs-post-comment:hover .reply-btn:hover {
  background-color: var(--title-color);
}

.children {
  margin-left: 70px;
}

.vs-comment-form {
  background-color: var(--smoke-color);
  padding: 60px;
  margin-top: 80px;
}
.vs-comment-form h3 {
  margin-top: -0.2em;
}
.vs-comment-form .form-group > i {
  right: 40px;
  top: 22px;
  color: var(--body-color);
}
.vs-comment-form .form-group input:focus ~ i,
.vs-comment-form .form-group textarea:focus ~ i {
  color: var(--title-color);
}

/* Large devices */
@media (max-width: 1199px) {
  .vs-comment-form {
    padding: 30px;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .vs-comment-form {
    margin-bottom: 30px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .blog-details .vs-comments-layout1 {
    margin-top: 40px;
  }

  .vs-post-comment {
    padding: 20px;
    margin-bottom: 20px;
  }

  .vs-post-comment .author-img {
    margin-right: 20px;
  }

  .children {
    margin-left: 40px;
  }

  .vs-comment-form {
    margin-top: 40px;
    padding: 20px;
  }
  .vs-comment-form .mb-40 {
    margin-bottom: 20px;
  }

  .form-title p {
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
  }
}
/* Extra small devices */
@media (max-width: 575px) {
  .vs-post-comment {
    flex-direction: column;
  }
  .vs-post-comment .author-img {
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .vs-post-comment .comment-content {
    width: 100%;
  }
}
/*------------------- 5.7. Hero Area  -------------------*/
.vs-hero-bg {
  padding: 240px 0 250px 0;
  position: relative;
  z-index: 2;
}

.hero-subtitle {
  border-bottom: 1px solid;
  padding-bottom: 8px;
  margin-bottom: 25px;
  font-size: 24px;
  display: inline-block;
}

.hero-slider1 .hero-subtitle {
  border-bottom: 1px solid var(--smoke-color);
}

.hero-desc {
  max-width: 630px;
  font-size: 18px;
}
.hero-desc.style-2 {
  max-width: 615px;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.4;
}

.hero-slider .slick-dots,
.hero-slider3 .slick-dots {
  position: absolute;
  right: 175px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.hero-slider .slick-dots li,
.hero-slider3 .slick-dots li {
  display: block;
  margin-right: 0;
}

.number-nav .slick-dots li button {
  border: none;
  text-indent: 0;
  color: var(--theme-color);
  font-size: 18px;
  font-weight: 500;
  background-color: var(--white-color);
  width: 58px;
  height: 58px;
  line-height: 55px;
  padding: 0;
  border-radius: 10px;
  margin-bottom: 21px;
  transition: 0.3s;
}
.number-nav .slick-dots li.slick-active button {
  background-color: var(--theme-color);
  color: var(--white-color);
  transform: scale(1.4);
  margin-bottom: 31px;
  margin-top: 11px;
}
.number-nav.style-2 .slick-dots li button {
  border: 2px solid;
  border-color: rgba(255, 255, 255, 0.36);
  color: var(--white-color);
  font-size: 18px;
  font-weight: 500;
  background-color: transparent;
  border-radius: 50%;
  margin-bottom: 21px;
  transition: 0.3s;
}
.number-nav.style-2 .slick-dots li button:hover {
  border-color: #fff;
}
.number-nav.style-2 .slick-dots li.slick-active button {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
  color: var(--white-color);
  transform: scale(1);
  margin-bottom: 21px;
  margin-top: unset;
}

.hero-slider2 .vs-hero-bg,
.hero-slider4 .vs-hero-bg {
  padding: 240px 0 383px 0;
  position: relative;
  z-index: 2;
}
.hero-slider2 .hero-subtitle,
.hero-slider4 .hero-subtitle {
  display: inline-block;
  padding-bottom: 5px;
  margin-bottom: 10px;
}
.hero-slider2 .hero-title,
.hero-slider4 .hero-title {
  font-size: 96px;
}

.hero-text {
  font-size: 40px;
  color: rgba(255, 255, 255, 0.26);
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 9px;
  transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  position: absolute;
  right: -75px;
  top: 42%;
  text-align: center;
}

.hero-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.hero-slider4 .vs-hero-bg {
  padding: 340px 0 300px 0;
}

.overlay-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: var(--title-color);
  background-image: url("../img/bg/overlay-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  opacity: 0.8;
}

.bmi-wrapper {
  filter: drop-shadow(0px 15px 50px rgba(0, 0, 0, 0.05));
  position: relative;
}
.bmi-wrapper:after {
  content: "";
  height: 100%;
  width: 100%;
  background-color: var(--white-color);
  position: absolute;
  top: 0;
  left: calc(100% - 1px);
}

.bmi-negative {
  transform: translateY(-135px);
  margin-bottom: -135px;
}

.bmi-inner {
  padding: 92px 0 86px 120px;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 30px 100%);
}

.bmi-wrapper.plain-style:after {
  display: none;
}
.bmi-wrapper.plain-style .bmi-inner {
  padding: 92px 100px 86px 100px;
  clip-path: none;
}

.home4-bmi .bmi-negative {
  transform: translateY(-115px);
  margin-bottom: -115px;
}

.sm-border-bottom {
  position: relative;
}
.sm-border-bottom:before, .sm-border-bottom:after {
  content: "";
  height: 3px;
  width: 30px;
  background-color: var(--theme-color);
  position: absolute;
  bottom: -22px;
  left: 0;
}
.sm-border-bottom:after {
  width: 10px;
  left: 35px;
}

.bmi-form {
  max-width: 640px;
}
.bmi-form label {
  margin-top: 0;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-size: 12px;
}
.bmi-form select, .bmi-form .form-select, .bmi-form .form-control {
  display: inline-block;
  border-radius: 0;
  height: 60px;
  width: 130px;
  color: var(--title-color);
  font-weight: 600;
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .vs-hero-wrapper .vs-hero-bg {
    padding: 140px 0 150px 0;
  }

  .hero-slider4 .vs-hero-bg {
    padding: 280px 0 240px 0;
  }

  .hero-slider .slick-dots,
.hero-slider3 .slick-dots {
    right: 100px;
  }

  .number-nav .slick-dots li.slick-active button {
    transform: scale(1.1);
    margin-bottom: 20px;
    margin-top: 0;
  }

  .hero-slider2 .vs-hero-bg {
    padding: 140px 0 295px 0;
  }

  .hero-text {
    font-size: 30px;
  }
}
/* Large devices */
@media (max-width: 1199px) {
  .hero-slider .slick-dots,
.hero-slider3 .slick-dots {
    right: 60px;
  }

  .hero-slider2 .vs-hero-bg {
    padding: 140px 0 140px 0;
  }

  .hero-slider2 .hero-title, .hero-slider3 .hero-title, .hero-slider4 .hero-title {
    font-size: 70px;
  }

  .hero-text {
    font-size: 24px;
    letter-spacing: 5px;
    top: 46%;
  }

  .bmi-scetion {
    background-color: var(--white-color);
    padding: 72px 0 66px 0;
    filter: drop-shadow(0px 15px 50px rgba(0, 0, 0, 0.05));
  }

  .bmi-negative {
    transform: translateY(0);
    margin-bottom: 0;
  }

  .bmi-inner {
    padding: 0;
    clip-path: none;
  }

  .bmi-wrapper {
    filter: none;
  }

  .hero-slider4 .vs-hero-bg {
    padding: 290px 0 140px 0;
  }

  .home4-bmi .bmi-negative {
    transform: translateY(0);
    margin-bottom: 0;
  }

  .bmi-wrapper.plain-style .bmi-inner {
    padding: 0;
    clip-path: none;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .hero-slider2 .hero-title, .hero-slider3 .hero-title, .hero-slider4 .hero-title {
    font-size: 50px;
  }

  .hero-desc.style-2 {
    font-size: 18px;
    font-weight: 400;
  }

  .hero-slider4 .vs-hero-bg {
    padding: 220px 0 140px 0;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .vs-hero-wrapper .vs-hero-bg {
    padding: 80px 0 80px 0;
  }

  .hero-subtitle {
    padding-bottom: 1px;
    margin-bottom: 20px;
    font-size: 20px;
  }

  .hero-slider2 .hero-subtitle, .hero-slider4 .hero-subtitle {
    padding-bottom: 1px;
  }

  .hero-slider2 .vs-hero-bg {
    padding: 80px 0 80px 0;
  }

  .hero-slider2 .hero-desc {
    font-size: 18px;
  }

  .hero-text {
    display: none;
  }

  .bmi-form select, .bmi-form .form-select, .bmi-form .form-control {
    margin-bottom: 15px;
  }

  .bmi-form label {
    margin-bottom: 0;
  }

  .hero-slider4 .vs-hero-bg {
    padding: 180px 0 80px 0;
  }
}
/* Extra small devices */
@media (max-width: 575px) {
  .bmi-form select, .bmi-form .form-select, .bmi-form .form-control {
    width: 100%;
    height: 48px;
  }

  .hero-shape {
    display: none;
  }

  .hero-slider2 .hero-title, .hero-slider3 .hero-title, .hero-slider4 .hero-title {
    font-size: 40px;
  }

  .hero-desc {
    line-height: 1.6;
    font-size: 16px;
  }
}
/*------------------- 5.8. Service  -------------------*/
.service-section {
  position: relative;
}
.service-section .section-dots-shape {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

#sbox3 .slick-list {
  padding-bottom: 40px;
  margin-bottom: -40px;
}

.vs-service-card {
  padding: 60px 40px 40px 40px;
  position: relative;
  overflow: hidden;
}
.vs-service-card:after {
  content: "";
  border-style: solid;
  border-width: 0 30px 30px 0;
  border-color: transparent var(--theme-color) transparent transparent;
  position: absolute;
  top: 30px;
  right: 30px;
  transition: 0.4s;
}
.vs-service-card .service-icon img {
  height: 80px;
  width: auto;
}
.vs-service-card .service-desc {
  margin-bottom: 0;
  line-height: 1.7;
}
.vs-service-card:hover:after {
  transform: scale(1.7);
  top: 10px;
  right: 10px;
}

.sr-content-box {
  max-width: 460px;
}

.vs-service-card2 {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  transition: 0.4s;
}
.vs-service-card2 .service-thumb {
  position: relative;
}
.vs-service-card2 .service-thumb img {
  width: 100%;
}
.vs-service-card2 .service-content {
  width: 100%;
  height: 0;
  visibility: hidden;
  opacity: 0;
  line-height: 1;
  padding: 50px;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 50%, transparent);
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: 0.4s;
}
.vs-service-card2 .service-title {
  margin-bottom: 5px;
}
.vs-service-card2:hover .service-content {
  height: 100%;
  visibility: visible;
  opacity: 1;
}

#sbox2 .slick-slide.slick-current + .slick-slide .vs-service-card2 .service-content {
  height: 100%;
  visibility: visible;
  opacity: 1;
}

.vs-service-card3 {
  --service-space: 20px;
  --icon-size: 80px;
  --border-color: rgb(241, 241, 241);
  transition: 0.4s;
}
.vs-service-card3 .service-thumb {
  width: calc(100% - var(--service-space) * 2);
  margin: 0 var(--service-space);
  position: relative;
  z-index: 3;
}
.vs-service-card3 .service-thumb img {
  border-radius: 10px;
  width: 100%;
  transition: 0.3s;
}
.vs-service-card3 .s-icon {
  position: absolute;
  bottom: calc(var(--icon-size) / -2);
  right: 30px;
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.04);
  background-color: #fff;
  width: var(--icon-size);
  height: var(--icon-size);
  text-align: center;
  line-height: 78px;
  border-radius: 50%;
  transition: 0.3s;
}
.vs-service-card3 .s-icon img {
  max-width: 40px;
  transition: 0.3s;
}
.vs-service-card3 .service-content {
  padding: 130px 40px 30px 40px;
  border: 2px solid var(--border-color);
  background-color: var(--white-color);
  border-radius: 10px;
  margin-top: -90px;
  position: relative;
  z-index: 1;
  transition: 0.4s;
}
.vs-service-card3:hover .s-icon {
  background-color: var(--theme-color);
}
.vs-service-card3:hover .s-icon img {
  filter: brightness(0) invert(1);
}
.vs-service-card3:hover .service-content {
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.04);
}

.service-bar {
  background-color: var(--white-color);
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.04);
  padding: 60px 80px;
  margin-top: -90px;
  margin-bottom: 60px;
}
.service-bar .bar-subtitle {
  display: block;
  margin-bottom: 3px;
}

.service-content p:last-child {
  margin-bottom: 0;
}

/*
// Active Style 
.slick-current {
    .vs-service-card2 {
        height: calc(100% + 50px);
        .service-content {
            height: 100%;
            visibility: visible;
            opacity: 1;
        }
    }
}
*/
/* Large devices */
@media (max-width: 1199px) {
  .service-bar {
    padding: 40px 40px;
    margin-top: -70px;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .service-bar {
    margin-top: 40px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  #sbox2 {
    margin-bottom: 30px;
  }

  .vs-service-card3 {
    --icon-size: 60px;
  }
  .vs-service-card3 .s-icon {
    line-height: 60px;
  }
  .vs-service-card3 .s-icon img {
    max-width: 30px;
  }
}
/*------------------- 5.9. Products  -------------------*/
#pbox1 .slick-list {
  padding-bottom: 40px;
  margin-bottom: -40px;
}

.vs-product-box {
  --smoke-dark: rgb(241, 241, 241);
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 20px 22px 0px rgba(0, 0, 0, 0.03);
}
.vs-product-box .product-img {
  position: relative;
}
.vs-product-box .price {
  color: var(--theme-color);
}
.vs-product-box .product-tag {
  --icon-size: 50px;
  --tag-gap: 20px;
  width: var(--icon-size);
  height: var(--icon-size);
  line-height: 51px;
  background-color: var(--theme-color);
  border-radius: 50%;
  position: absolute;
  top: var(--tag-gap);
  right: var(--tag-gap);
  color: var(--white-color);
  font-size: 14px;
}
.vs-product-box .product-title {
  margin-bottom: 10px;
}
.vs-product-box .product-content {
  padding: 35px 20px 31px 20px;
  border: 2px solid var(--smoke-dark);
  border-top: none;
  border-radius: 0 0 10px 10px;
}
.vs-product-box .actions-btn {
  position: absolute;
  top: 48%;
  left: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  text-align: center;
  transition: 0.4s ease;
}
.vs-product-box .actions-btn .icon-btn {
  margin: 5px;
}
.vs-product-box:hover .actions-btn {
  margin-top: -20px;
  visibility: visible;
  opacity: 1;
}

.product-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  text-align: left;
}
.product-list .icon-btn.style4 {
  --btn-size: 38px;
  font-size: 12px;
  margin: 3px;
}
.product-list .product-img {
  width: 100%;
  max-width: 180px;
}
.product-list .product-content {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-top: 2px solid var(--smoke-dark);
  border-radius: 0 10px 10px 0;
  border-left: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.shop-wrap {
  padding: 80px;
}

.product-big-img {
  position: relative;
  --pos-gap: 30px;
}
.product-big-img .slick-dots {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 25px;
  margin-bottom: 0;
}
.product-big-img .price {
  font-size: 30px;
  font-weight: 700;
  color: var(--theme-color);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  font-family: var(--title-font);
  position: absolute;
  right: var(--pos-gap);
  top: var(--pos-gap);
  line-height: 1;
}
.product-big-img .price del {
  margin-right: 20px;
  font-size: 0.7em;
  color: var(--body-color);
  line-height: 1.1;
}
.product-big-img .add_to_wishlist {
  position: absolute;
  right: var(--pos-gap);
  bottom: var(--pos-gap);
  font-weight: 400;
  color: var(--title-color);
  line-height: 1;
}
.product-big-img .add_to_wishlist i {
  margin-right: 5px;
  color: var(--theme-color);
}
.product-big-img .add_to_wishlist:hover {
  color: var(--theme-color);
}

.product-thumb-img .slick-current .thumb {
  border-color: var(--theme-color);
}
.product-thumb-img .slick-arrow {
  position: absolute;
  left: -60px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
  width: auto;
  height: auto;
  padding: 0;
  line-height: 1;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
}
.product-thumb-img .slick-arrow.slick-next {
  left: auto;
  right: -60px;
}
.product-thumb-img .slick-dots {
  margin-top: 20px;
}

.product-thumb {
  width: 150px;
  max-width: 100%;
  transition: all ease 0.4s;
  cursor: pointer;
}
.product-thumb.slick-current img {
  border: 1px solid var(--theme-color);
}

.product-big-img .slick-arrow,
.product-thumb-img .slick-arrow {
  position: absolute;
  left: -60px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
  width: auto;
  height: auto;
  padding: 0;
  line-height: 1;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  z-index: 3;
  margin: 0;
}
.product-big-img .slick-arrow.slick-next,
.product-thumb-img .slick-arrow.slick-next {
  left: auto;
  right: -60px;
}

.product-big-img .slick-arrow {
  left: 30px;
}
.product-big-img .slick-arrow.slick-next {
  left: auto;
  right: 30px;
}

.container .row:hover .product-big-img .slick-arrow,
.container .row:hover .product-thumb-img .slick-arrow {
  opacity: 1;
  visibility: visible;
}

.product-details .woocommerce-product-rating {
  margin-bottom: 7px;
}
.product-details .woocommerce-product-rating .star-rating {
  display: inline-block;
  vertical-align: middle;
}
.product-details .woocommerce-product-rating .woocommerce-review-link {
  color: var(--body-color);
  margin-left: 10px;
  font-size: 14px;
}
.product-details .woocommerce-product-rating .woocommerce-review-link:hover {
  color: var(--theme-color);
}
.product-details .product-title {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--border-color);
}
.product-details .vs-input-group {
  display: flex;
  margin-top: 10px;
  margin-bottom: 40px;
}
.product-details .actions {
  margin-bottom: 35px;
}

.size-select input[type=radio] ~ label {
  font-size: 12px;
  padding: 8px 14px;
  background-color: var(--white-color);
  border-radius: 999px;
  text-transform: none;
  font-weight: 400;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}
.size-select input[type=radio] ~ label:before {
  display: none;
}
.size-select input[type=radio]:checked ~ label {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.quantity {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.quantity input {
  width: 75px;
  height: 60px;
  text-align: center;
  border: 1px solid var(--smoke-color);
  font-family: var(--body-font);
  color: var(--body-color);
  font-weight: 600;
  font-size: 18px;
  padding-left: 0;
  padding-right: 30px;
  /* Firefox */
}
.quantity input::-webkit-outer-spin-button, .quantity input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.quantity input[type=number] {
  -moz-appearance: textfield;
}
.quantity .qut-btn {
  position: absolute;
  top: 11px;
  right: 10px;
  padding: 0;
  line-height: 1;
  color: var(--body-color);
  font-size: 14px;
  border: none;
  background-color: transparent;
}
.quantity .qut-btn.quantity-minus {
  top: 30px;
}
.quantity .qut-btn:hover {
  color: var(--theme-color);
}

.product_meta {
  font-weight: 400;
  font-size: 18px;
  text-transform: capitalize;
  font-family: var(--body-font);
}
.product_meta > span {
  display: block;
  margin-bottom: 3px;
  color: var(--title-color);
}
.product_meta > span:last-child {
  margin-bottom: 0;
}
.product_meta > span a {
  color: inherit;
}
.product_meta > span a:hover {
  color: var(--theme-color);
}
.product_meta > span > a,
.product_meta > span > span {
  position: relative;
  color: var(--body-color);
}
.product_meta > span > a:after,
.product_meta > span > span:after {
  content: ",";
  margin-right: 5px;
}
.product_meta > span > a:last-child:after,
.product_meta > span > span:last-child:after {
  display: none;
}
.product_meta > span > a:first-child,
.product_meta > span > span:first-child {
  margin-left: 7px;
}

.product-info-list {
  list-style-type: none;
  margin: 0 0 30px 0;
  padding: 0;
}
.product-info-list li {
  font-size: 18px;
  position: relative;
  color: var(--body-color);
  margin-bottom: 13px;
  padding-left: 43px;
}
.product-info-list li:before {
  content: "\f00c";
  font-family: var(--icon-font);
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50%;
  font-size: 14px;
  background-color: var(--white-color);
  color: var(--theme-color);
  box-shadow: 0px 10px 42.75px 2.25px rgba(0, 0, 0, 0.05);
  text-align: center;
}
.product-info-list li span {
  color: var(--title-color);
  margin-right: 3px;
}

.vs-text-box {
  border-left: 8px solid var(--theme-color);
  font-style: italic;
  font-size: 18px;
  line-height: 1.6;
  padding: 48px 50px;
  margin-bottom: 30px;
}

.product-tab {
  position: relative;
  border-bottom: 1px solid var(--border-color);
  margin-top: -0.2em;
}
.product-tab a {
  color: var(--title-color);
  font-size: 18px;
  font-weight: 700;
  padding: 0 20px 20px 20px;
}
.product-tab a:hover, .product-tab a.active {
  color: var(--theme-color);
}
.product-tab .indicator {
  position: absolute;
  left: 0;
  top: auto !important;
  height: 0 !important;
  bottom: -1px;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  border-bottom: 1px solid var(--theme-color);
}
.product-tab .indicator:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 12px;
  height: 12px;
  border-top: 1px solid var(--theme-color);
  border-left: 1px solid var(--theme-color);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  margin: -6px 0 0 -6px;
  background-color: var(--white-color);
}

.product-tab-style1 {
  border-bottom: 1px solid #e4e4e4;
}
.product-tab-style1 a {
  color: var(--title-color);
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  display: inline-block;
  position: relative;
  line-height: 1;
  padding: 0 20px 10px 20px;
  font-family: var(--title-font);
}
.product-tab-style1 a:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  height: 1px;
  width: 0;
  background-color: var(--theme-color);
  transition: all ease 0.4s;
}
.product-tab-style1 a.active:before {
  width: 100%;
}

.product-inner-list > ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.product-inner-list li {
  position: relative;
  padding-left: 15px;
}
.product-inner-list li:before {
  content: "-";
  position: absolute;
  left: 0;
}

.product-about .price {
  margin-top: -0.2em;
  font-size: 30px;
  font-weight: 600;
  color: var(--theme-color);
  display: block;
  margin-bottom: 15px;
}
.product-about .price del {
  color: var(--body-color);
  font-weight: 400;
  font-size: 0.8em;
}
.product-about .actions {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.product-about .actions .vs-btn {
  font-size: 16px;
  padding: 17px 28px;
}

.product-details .vs-comments-wrap {
  margin-top: 0;
}

.discount-tagged {
  display: inline-block;
  width: 80px;
  height: 80px;
  position: absolute;
  right: 22px;
  top: 22px;
  background-color: #ffe400;
  color: #222222;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding: 15px 0;
  line-height: 1.2;
  border-radius: 50%;
  z-index: 3;
}

.product-tagged {
  position: absolute;
  left: 27px;
  top: 27px;
  z-index: 1;
}
.product-tagged a {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #4adf3b;
  color: var(--white-color);
  display: block;
  width: max-content;
  min-width: 83px;
  text-align: center;
  line-height: 1;
  padding: 6px 8px;
  margin-bottom: 4px;
}
.product-tagged a.offers {
  background-color: #ed0c0c;
}
.product-tagged a:last-child {
  margin-bottom: 0;
  position: relative;
}
.product-tagged a:last-child:before {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  border-left: 10px solid #4adf3b;
  border-bottom: 10px solid transparent;
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .discount-tagged {
    width: 70px;
    height: 70px;
    font-size: 16px;
    padding: 14px 0;
  }
}
/* Large devices */
@media (max-width: 1199px) {
  .shop-wrap {
    padding: 40px;
  }
  .shop-wrap .gx-60, .shop-wrap .g-60 {
    --bs-gutter-x: 30px;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .shop-wrap {
    padding: 40px 20px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .vs-text-box {
    padding: 30px 30px;
  }
}
/* Extra small devices */
@media (max-width: 575px) {
  .product-about .actions {
    display: block;
  }
  .product-about .actions .quantity {
    display: block;
    width: max-content;
    margin-bottom: 20px;
  }
}
/*------------------- 5.10. Project  -------------------*/
.vs-project-card {
  position: relative;
  --project-space: 30px;
}
.vs-project-card .project-content {
  padding: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  box-shadow: 0px 15px 42.75px 2.25px rgba(39, 71, 125, 0.07);
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-end;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
}
.vs-project-card .icon-btn {
  background-color: var(--theme-color);
  color: var(--white-color);
  box-shadow: 0px 10px 30px 0px rgba(246, 60, 37, 0.25);
  margin-left: auto;
}
.vs-project-card .icon-btn:hover {
  background-color: var(--title-color);
}
.vs-project-card:hover .project-content {
  width: calc(100% - var(--project-space) * 2);
  bottom: var(--project-space);
  left: var(--project-space);
  opacity: 1;
  visibility: visible;
}

.project-menu button.vs-btn {
  color: var(--body-color);
}

/* Extra large devices */
@media (max-width: 1500px) {
  .vs-project-card {
    --project-space: 20px;
  }
}
/* Medium Large devices */
@media (max-width: 1399px) {
  .vs-project-card .project-content {
    padding: 20px;
  }
  .vs-project-card .project-content .project-title {
    font-size: 20px;
  }
}
/* Extra small devices */
@media (max-width: 575px) {
  .project-menu button {
    margin-bottom: 10px;
  }

  .project-section .container-fluid.px-30 {
    padding-left: 15px;
    padding-right: 15px;
  }
}
/*------------------- 5.11. Appointment  -------------------*/
.appointment-section {
  position: relative;
}

.appoint-shape img {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.vs-img-box2 {
  position: relative;
  height: 100%;
}
.vs-img-box2 .big-img {
  max-width: calc(100% - 70px);
}
.vs-img-box2 .small-img {
  position: absolute;
  bottom: 0;
  right: 0;
  border: 10px solid var(--white-color);
  max-width: 284px;
  box-shadow: 0px 5px 120px 0px rgba(14, 28, 52, 0.15);
}

.appoint-form-area {
  padding: 58px;
  box-shadow: 0px 5px 120px 0px rgba(14, 28, 52, 0.1);
}

/* After Before */
.comparison-wrapper {
  min-height: 500px;
}

.twentytwenty-handle {
  border: 3px solid white;
  background-color: var(--white-color);
}

.twentytwenty-left-arrow {
  border-right: 6px solid var(--theme-color);
  margin-left: -15px;
}

.twentytwenty-right-arrow {
  border-left: 6px solid var(--theme-color);
  margin-right: -15px;
}

/* Large devices */
@media (max-width: 1199px) {
  .comparison-wrapper {
    min-height: 383px;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .vs-img-box2 {
    margin-bottom: 30px;
  }

  .comparison-wrapper {
    min-height: 285px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .form-sub-title.mb-40 {
    margin-bottom: 20px;
  }

  .comparison-wrapper {
    min-height: 211px;
  }
  .comparison-wrapper img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  .appoint-form-area {
    padding: 40px;
  }
}
/* Extra small devices */
@media (max-width: 575px) {
  .appoint-form-area {
    padding: 40px 20px;
  }

  .vs-img-box2 .small-img {
    max-width: 200px;
  }
}
/*------------------- 5.12. Brand  -------------------*/
.brand {
  text-align: center;
}

.brand-section.bg-smokedark {
  --smoke-dark: rgba(244, 244, 244, 1);
}

.brand-img img {
  opacity: 0.4;
  transition: 0.4s;
}
.brand-img:hover img {
  opacity: 1;
}

/* Medium devices */
@media (max-width: 991px) {
  .brand-row.px-100 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .brand-row.pt-50 {
    padding-top: 30px;
  }

  .brand-img {
    width: 150px;
    margin: 0 auto;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .brand-row.px-100 {
    padding-left: 0;
    padding-right: 0;
  }
}
/* Extra small devices */
@media (max-width: 414px) {
  .brand-img {
    width: 120px;
  }
}
/*------------------- 5.13. Components  -------------------*/
.vs-content-box2 p {
  line-height: 1.625;
  margin: 0 0 27px 0;
}

.tik-list {
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  width: 370px;
}
.tik-list li {
  font-weight: 600;
}
.tik-list li:before {
  content: "\f00c";
  font-family: var(--icon-font);
  display: inline-block;
  background-color: var(--white-color);
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.08);
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin-right: 20px;
  font-weight: 300;
}

.vs-img-box4 {
  position: relative;
}
.vs-img-box4 .vs-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.img-box5 {
  position: relative;
}
.img-box5 .content {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #fff;
  padding: 40px;
  border-radius: 0 50px 0 0;
}

.vs-feature-card {
  padding-top: 55px;
}
.vs-feature-card .fr-number {
  margin-bottom: 22px;
}
.vs-feature-card .fr-name {
  display: block;
  background-color: var(--theme-color);
  color: var(--white-color);
  font-size: 18px;
  padding: 17px;
  font-weight: 500;
}

.vs-icon-box {
  --icon-szie: 60px;
}
.vs-icon-box [class*=arrow] {
  width: var(--icon-szie);
  height: var(--icon-szie);
  line-height: var(--icon-szie);
  background-color: var(--theme-color);
  color: var(--white-color);
  text-align: center;
  border-radius: 10px;
  border: none;
}
.vs-icon-box [class*=arrow]:hover {
  background-color: var(--white-color);
  color: var(--theme-color);
  box-shadow: 0px 20px 40px 0px rgba(34, 34, 34, 0.08);
}
.vs-icon-box .arrow-left {
  margin-right: 20px;
}

.two-btns .vs-btn:first-child {
  margin-right: 20px;
}

.accordion-item {
  margin-bottom: 20px;
  --smoke-dark: rgba(238, 238, 238, 1);
}
.accordion-item:last-child {
  margin-bottom: 0;
}

.accordion-button {
  border: none;
  background-color: var(--smoke-dark);
  color: var(--title-color);
  font-size: 20px;
  padding: 20px 40px;
  border-radius: 10px !important;
  margin-bottom: 0;
  text-align: left;
}
.accordion-button:focus {
  box-shadow: none;
}
.accordion-button:after {
  content: "+";
  background-image: none;
  font-weight: 400;
  line-height: 1;
  width: auto;
}
.accordion-button:not(.collapsed) {
  background-color: var(--smoke-dark);
  color: var(--title-color);
  border-radius: 10px 10px 0 0 !important;
  padding: 35px 40px 6px 40px;
}
.accordion-button:not(.collapsed):after {
  content: "-";
  background-image: none;
}

.accordion-collapse {
  border: none;
}

.accordion-body {
  background-color: var(--smoke-dark);
  border: none;
  border-radius: 0 0 10px 10px;
  padding: 5px 40px 12px 40px;
}

.vs-accordion {
  --smoke-color: rgba(245, 245, 245, 1);
  border-bottom: 1px solid var(--border-color);
}
.vs-accordion .accordion-item {
  margin-bottom: 0;
}
.vs-accordion .accordion-button {
  border: none;
  background-color: var(--white-color);
  color: var(--title-color);
  font-size: 18px;
  font-weight: 500;
  padding: 25px 0;
  border-radius: 0 !important;
  margin-bottom: 0;
  border-top: 1px solid var(--border-color);
  text-align: left;
  line-height: 1.556;
}
.vs-accordion .accordion-button:before {
  content: "+";
  background-image: none;
  font-weight: 400;
  background-color: var(--smoke-color);
  color: var(--title-color);
  text-align: center;
  width: 30px;
  height: 30px;
  line-height: 30px;
  min-width: 30px;
  border-radius: 50%;
  margin-right: 30px;
}
.vs-accordion .accordion-button:after {
  display: none;
}
.vs-accordion .accordion-button:not(.collapsed) {
  border-radius: 0 !important;
  padding-bottom: 18px;
}
.vs-accordion .accordion-button:not(.collapsed):before {
  content: "-";
  background-color: var(--theme-color);
  background-image: none;
  color: var(--white-color);
}
.vs-accordion .accordion-body {
  background-color: var(--white-color);
  border: none;
  border-radius: 0;
  padding: 0 0 10px 60px;
}

.vs-num-card {
  padding: 20px 60px 20px 60px;
  height: 168px;
  border: 2px solid #f1f1f1;
  background-color: var(--white-color);
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vs-num-card .icon-box {
  width: 55px;
  margin-right: 35px;
}
.vs-num-card .number {
  margin-bottom: 0;
}
.vs-num-card .num-title {
  white-space: nowrap;
}

.vs-session-card {
  background-color: var(--smoke-color);
  padding: 40px 60px 45px 60px;
  border: 2px solid var(--smoke-color);
  transition: 0.4s;
}
.vs-session-card .sn-title {
  font-weight: 500;
}
.vs-session-card .sn-time {
  margin-bottom: 17px;
}
.vs-session-card .sn-btn {
  color: var(--title-color);
  font-weight: 600;
  border-bottom: 2px solid var(--body-color);
}
.vs-session-card .sn-btn i {
  transition: 0.3s;
}
.vs-session-card .sn-btn:hover {
  color: var(--theme-color);
  border-color: var(--theme-color);
}
.vs-session-card .sn-btn:hover i {
  margin-left: 3px;
}
.vs-session-card:hover {
  border-color: #f1f1f1;
  background-color: var(--white-color);
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.08);
}

.cta-desc.fs-24 {
  max-width: 700px;
  line-height: 1.47;
}

/* Medium devices */
@media (max-width: 991px) {
  .vs-session-card {
    padding: 30px 40px 35px 40px;
  }

  .vs-num-card {
    margin-bottom: 30px;
  }

  .col-lg-4.col-md-6:last-child .vs-num-card {
    margin-bottom: 0;
  }

  .vs-img-box3 {
    margin-left: -45px;
  }

  .img-box5 {
    margin-bottom: 40px;
  }

  .vs-content-box2.pl-50 {
    padding-left: 0;
  }

  .md-center {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .mb-unset {
    margin-bottom: unset !important;
    padding-bottom: var(--section-space-mobile) !important;
  }

  .pt-unset {
    padding-top: unset !important;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .vs-img-box3 {
    margin-left: -35px;
  }

  .vs-num-card .number {
    font-size: 36px;
  }

  .accordion-button {
    padding: 15px 20px;
  }
  .accordion-button:not(.collapsed) {
    background-color: var(--smoke-dark);
    color: var(--title-color);
    border-radius: 10px 10px 0 0 !important;
    padding: 15px 20px 10px 20px;
  }
  .accordion-button:not(.collapsed):after {
    content: "-";
    background-image: none;
  }

  .accordion-body {
    border-radius: 0 0 10px 10px;
    padding: 0 20px 5px 20px;
  }
  .accordion-body p {
    margin-bottom: 10px;
  }

  .img-box5 .content {
    border-radius: 0 40px 0 0;
  }

  .vs-num-card {
    padding: 20px 40px 20px 40px;
    height: 130px;
  }
}
/* Extra small devices */
@media (max-width: 575px) {
  .vs-img-box3 {
    margin-left: -25px;
  }

  .vs-accordion .accordion-button::before {
    min-width: 22px;
    width: 22px;
    height: 22px;
    line-height: 22px;
    margin-right: 20px;
  }

  .vs-accordion .accordion-button {
    font-size: 16px;
  }

  .vs-accordion .accordion-body {
    padding: 0 0 10px 40px;
  }
}
/* Extra small devices */
@media (max-width: 414px) {
  .tik-list {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    margin-bottom: 50px;
  }

  .two-btns .vs-btn {
    max-width: 200px;
    display: block;
  }
  .two-btns .vs-btn:first-child {
    margin-bottom: 20px;
  }

  .text-center .two-btns .vs-btn {
    margin-left: auto;
    margin-right: auto;
  }

  .vs-session-card {
    padding: 30px 30px 35px 30px;
  }
}
/*------------------- 5.14. Schedule  -------------------*/
.schedule-table {
  --smoke-color: rgb(247, 247, 247);
  text-align: center;
  border-collapse: unset;
  margin-bottom: 0;
}
.schedule-table th {
  background-color: var(--theme-color);
  padding: 20px;
  color: var(--white-color);
  border-right: 1px solid var(--border-white);
  margin-bottom: 0;
}
.schedule-table td {
  position: relative;
  padding: 65px 10px 32px 10px;
  border-top: none;
  min-width: 156px;
}
.schedule-table tr:last-child td {
  border-bottom: 1px solid var(--smoke-color);
}
.schedule-table tr td:first-child {
  border-left: 1px solid var(--smoke-color);
}
.schedule-table tr td:last-child {
  border-right: 1px solid var(--smoke-color);
}
.schedule-table .open {
  background-color: var(--smoke-color);
}
.schedule-table .closed {
  background-color: var(--white-color);
  height: 100%;
}
.schedule-table .schedule-time {
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  padding: 7px 33px;
  border-radius: 0 0 20px 20px;
  color: var(--theme-color);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--white-color);
  box-shadow: 0px 13px 30px 0px rgba(98, 98, 98, 0.04);
}
.schedule-table img {
  margin-bottom: 10px;
}
.schedule-table .work-title {
  margin-bottom: 3px;
}

/* Medium devices */
@media (max-width: 991px) {
  .table-wrapper {
    overflow-x: scroll;
  }
}
/*------------------- 5.15. Process  -------------------*/
.vs-process {
  text-align: center;
  padding: 0 20px;
  --border-color: rgb(236, 236, 236);
}
.vs-process .icon-box {
  --box-size: 148px;
  --box-gap: 17px;
  position: relative;
  width: var(--box-size);
  height: var(--box-size);
  line-height: var(--box-size);
  margin: 0 auto;
  margin-bottom: 35px;
  z-index: 2;
}
.vs-process .icon-box:before {
  content: "";
  width: 100%;
  height: 100%;
  border: 2px solid var(--border-color);
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: 0.4s;
}
.vs-process .icon-box:after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--theme-color);
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: 0.4s;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}
.vs-process .icon-box .process-num {
  border-radius: 50%;
  background-color: var(--white-color);
  box-shadow: 0px 15px 40px 0px rgba(0, 0, 0, 0.09);
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  position: absolute;
  top: 0;
  right: 0;
}
.vs-process .icon-box img {
  max-width: 50px;
}
.vs-process .icon-box .process-desc {
  margin-bottom: 0;
}
.vs-process .icon-box:hover:after {
  width: calc(100% - var(--box-gap) * 2);
  height: calc(100% - var(--box-gap) * 2);
  top: var(--box-gap);
  left: var(--box-gap);
  opacity: 1;
  visibility: visible;
}
.vs-process .icon-box:hover img {
  filter: brightness(0) invert(1);
}
.vs-process .process-desc {
  margin-bottom: 0;
}

/* Large devices */
@media (max-width: 1199px) {
  .vs-process {
    padding: 0;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .vs-process {
    margin-bottom: 30px;
  }

  .col-lg-3.col-sm-6:last-child .vs-process {
    margin-bottom: 0;
  }
}
/* Extra small devices */
@media (max-width: 575px) {
  .vs-process {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}
/*------------------- 5.16. Gallery  -------------------*/
.vs-gallery-box {
  position: relative;
  overflow: hidden;
}
.vs-gallery-box .gallery-img {
  overflow: hidden;
  position: relative;
}
.vs-gallery-box .gallery-img img {
  transition: all ease 0.4s;
  transform: scale(1) rotate(0);
}
.vs-gallery-box .gallery-img:after, .vs-gallery-box .gallery-img:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 50%;
  background-color: rgba(173, 136, 88, 0.9);
  z-index: 1;
  transition: all ease 0.4s;
  transform: scale(0);
  transform-origin: top left;
}
.vs-gallery-box .gallery-img:after {
  top: auto;
  left: auto;
  right: 0;
  bottom: 0;
  transform-origin: bottom right;
}
.vs-gallery-box .icon-btn {
  --btn-size: 55px;
  line-height: 50px;
}
.vs-gallery-box .gallery-content {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
}
.vs-gallery-box .gallery-title {
  font-size: 28px;
  font-weight: 600;
  color: var(--white-color);
  margin-bottom: 0;
}
.vs-gallery-box .icon-btn,
.vs-gallery-box .gallery-title {
  transition: all ease 0.4s;
  opacity: 0;
  visibility: hidden;
  transform: translateY(40px);
}
.vs-gallery-box:hover .gallery-img img {
  transform: scale(1.2) rotate(5deg);
}
.vs-gallery-box:hover .gallery-img:after, .vs-gallery-box:hover .gallery-img:before {
  transform: scale(1);
}
.vs-gallery-box:hover .icon-btn,
.vs-gallery-box:hover .gallery-title {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition-delay: 0.3s;
}
.vs-gallery-box:hover .icon-btn {
  transition-delay: 0.4s;
}

.gallery-thumb {
  position: relative;
  overflow: hidden;
}
.gallery-thumb img {
  transition: all ease 0.4s;
  transform: scale(1);
}
.gallery-thumb:after, .gallery-thumb:before {
  content: "";
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  background-color: rgba(255, 51, 51, 0.8);
  transition: all ease 0.6s;
  z-index: 1;
}
.gallery-thumb:before {
  left: 100%;
}
.gallery-thumb:after {
  right: 100%;
}
.gallery-thumb .icon-thumb {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 4;
  color: var(--white-color);
  font-size: 24px;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.4s;
  transition-delay: 0s;
  transform: translate(-50%, -50%);
}
.gallery-thumb:hover img {
  transform: scale(1.2);
}
.gallery-thumb:hover::before {
  left: 0;
}
.gallery-thumb:hover:after {
  right: 0;
}
.gallery-thumb:hover .icon-thumb {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.4s;
}

.gallery-cutted.gallery-thumb .icon-thumb, .gallery-cutted.gallery-thumb:after, .gallery-cutted.gallery-thumb:before {
  opacity: 0;
  visibility: hidden;
}

.slick-center .gallery-cutted.gallery-thumb:after, .slick-center .gallery-cutted.gallery-thumb:before {
  opacity: 1;
  visibility: visible;
}
.slick-center .gallery-cutted.gallery-thumb:hover .icon-thumb {
  opacity: 1;
  visibility: visible;
}

/* Medium devices */
@media (max-width: 991px) {
  .vs-gallery-box .gallery-content {
    padding: 20px 25px;
  }
  .vs-gallery-box .gallery-title {
    font-size: 18px;
  }
  .vs-gallery-box .icon-btn {
    --btn-size: 45px;
  }
}
/*------------------- 5.17. About  -------------------*/
.vs-content-box1 {
  min-height: 283px;
  padding: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: 0.4s;
  position: relative;
}
.vs-content-box1:before {
  content: "";
  width: 0;
  height: 0;
  border-width: 30px 30px 0 0;
  border-color: var(--white-color) transparent transparent transparent;
  border-style: solid;
  position: absolute;
  left: 20px;
  top: 20px;
  transition: 0.4s;
}
.vs-content-box1 .about-number {
  font-size: 60px;
  margin-bottom: 0;
}
.vs-content-box1:hover:before {
  transform: scale(1.4);
  left: 6px;
  top: 6px;
}

.top-round {
  border-radius: 70% 0 0 0;
  overflow: hidden;
}

.bottom-round {
  border-radius: 0 0 70% 0;
  overflow: hidden;
}

.about-contents {
  height: inherit;
  box-shadow: 0px 20px 73px 0px rgba(87, 87, 87, 0.06);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 70px;
}

/* Large devices */
@media (max-width: 1199px) {
  .vs-content-box1 {
    min-height: unset;
    padding: 80px 40px 40px 40px;
  }
  .vs-content-box1 .about-number {
    font-size: 40px;
  }

  .about-contents {
    padding: 20px 30px;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .about-col-1,
.about-col-2 {
    display: flex;
  }

  .about-col-2 {
    margin-top: 30px;
  }

  .vs-img-box1,
.vs-content-box1 {
    min-height: calc(100% - 30px);
    width: 100%;
    margin-bottom: 30px;
  }
  .vs-img-box1 img,
.vs-content-box1 img {
    width: 100%;
  }
  .vs-img-box1:first-child,
.vs-content-box1:first-child {
    margin-right: 30px;
  }

  .about-col-2 .vs-img-box1 {
    margin-bottom: 0;
  }

  .about-contents {
    padding: 40px;
  }

  .vs-content-box1 .about-number {
    font-size: 36px;
  }
}
/* Extra small devices */
@media (max-width: 575px) {
  .top-round {
    border-radius: 0 0 0 0;
  }

  .bottom-round {
    border-radius: 0 0 0 0;
  }
}
/* Extra small devices */
@media (max-width: 414px) {
  .about-col-1,
.about-col-2 {
    display: block;
  }

  .about-col-2 .vs-img-box1:nth-child(1) {
    margin-bottom: 30px;
  }

  .about-contents {
    padding: 20px;
  }
}
/*------------------- 5.18. Price Plan  -------------------*/
#priceBox1 .slick-list {
  padding-bottom: 60px;
  margin-bottom: -60px;
}

.package-card {
  --border-white: rgba(255, 255, 255, 0.3);
  --package-space: 60px;
  background-color: var(--white-color);
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.04);
  transition: 0.4s;
}
.package-card .package-content {
  padding: var(--package-space);
  padding-top: 0;
  position: relative;
  z-index: 2;
}
.package-card .package-content:before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--white-color);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.4s;
}
.package-card:hover .package-content:before {
  visibility: hidden;
  opacity: 0;
}
.package-card .package-name {
  font-size: 24px;
  font-weight: 600;
  padding: 17px;
  background-color: var(--theme-color);
  color: var(--white-color);
  display: block;
}
.package-card .package-price {
  border-bottom: 1px solid var(--border-color);
  padding: 30px 0;
  margin-bottom: 30px;
}
.package-card .package-price.yearly {
  display: none;
}
.package-card ul {
  padding: 0;
  list-style: none;
  margin-bottom: 30px;
}
.package-card ul li {
  margin: 14px;
}
.package-card:hover {
  box-shadow: none;
}
.package-card:hover ul li,
.package-card:hover .package-price {
  color: var(--white-color);
}
.package-card:hover .package-price {
  border-bottom: 1px solid var(--border-white);
}
.package-card:hover .package-content:before {
  left: 100%;
}

.slideToggle {
  --icon-size: 80px;
  --toggle-size: 240px;
  --item-size: calc(var(--toggle-size) - var(--icon-size));
  display: flex;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
  width: var(--toggle-size);
  height: var(--icon-size);
  color: var(--title-color);
  font-size: 18px;
  font-weight: 500;
  position: relative;
}
.slideToggle i {
  margin: 0 15px;
}
.slideToggle .beforeinputs {
  display: none;
}
.slideToggle .afterinput,
.slideToggle .beforeinputs {
  text-align: center;
  width: var(--item-size);
}
.slideToggle .afterinput {
  margin-left: var(--icon-size);
}

.form-switch {
  align-items: center;
  display: flex;
  cursor: pointer;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.form-switch i {
  font-size: 20px;
  color: var(--white-color);
  background-color: var(--theme-color);
  position: relative;
  display: inline-block;
  width: var(--icon-size);
  height: var(--icon-size);
  line-height: var(--icon-size);
  text-align: center;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.4s ease-in-out;
}
.form-switch input {
  display: none;
  transition: 0.4s;
}
.form-switch input:checked + i {
  left: var(--item-size);
}
.form-switch input:checked + i:before {
  content: "\f060";
}

/* Large devices */
@media (max-width: 1199px) {
  .package-card {
    --package-space: 50px 30px;
  }
}
/*------------------- 5.19. Subscribe  -------------------*/
.subscribe-box {
  position: relative;
  --btn-size: 60px;
}
.subscribe-box input {
  border: none;
  background-color: var(--white-color);
  height: 90px;
  border-radius: 0;
  padding-left: 49px;
}
.subscribe-box input::-moz-placeholder {
  color: var(--title-color);
}
.subscribe-box input::-webkit-input-placeholder {
  color: var(--title-color);
}
.subscribe-box input:-ms-input-placeholder {
  color: var(--title-color);
}
.subscribe-box input::placeholder {
  color: var(--title-color);
}
.subscribe-box input:focus {
  background-color: var(--white-color);
}
.subscribe-box button {
  position: absolute;
  right: 30px;
  top: 50%;
  width: var(--btn-size);
  height: var(--btn-size);
  transform: translateY(-50%);
  font-size: 22px;
  color: var(--theme-color);
  border: 2px solid var(--theme-color);
  background-color: transparent;
  overflow: hidden;
}
.subscribe-box button .default {
  opacity: 1;
  visibility: visible;
  transition: all ease 0.4s;
}
.subscribe-box button .hidden {
  position: absolute;
  left: 140%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.4s;
  z-index: 2;
}
.subscribe-box.focus button .hidden {
  left: 50%;
  opacity: 1;
  visibility: visible;
}
.subscribe-box.focus button .default {
  opacity: 0;
  visibility: hidden;
}

.subscribe-shape {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  max-width: 100%;
  width: 1345px;
  height: 564px;
  max-height: 100%;
}

/* Extra large devices */
@media (max-width: 1500px) {
  .subscribe-shape {
    width: 1000px;
  }
}
/* Large devices */
@media (max-width: 1199px) {
  .subscribe-box.ms-xl-1.mt-40 {
    margin-top: 0;
  }

  .subscribe-shape {
    width: 800px;
    height: 508px;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .subscribe-shape {
    width: 100%;
    height: 100%;
  }

  .subscribe-layout1 {
    padding-bottom: 80px;
  }

  .subscribe-box {
    --btn-size: 50px;
  }
  .subscribe-box input {
    height: 70px;
    padding-left: 30px;
  }
  .subscribe-box button {
    right: 12px;
    font-size: 16px;
  }
}
/*------------------- 5.20. Team  -------------------*/
.team-card {
  position: relative;
  margin-bottom: 120px;
  --team-space: 40px;
  --icon-size: 45px;
  transition: 0.4s;
}
.team-card .team-content {
  padding-top: 35px;
  width: calc(100% - var(--team-space) * 2);
  margin-left: var(--team-space);
  transition: 0.4s;
  box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.02);
  position: absolute;
  top: 100%;
  left: 0;
}
.team-card .team-desig {
  margin-bottom: 15px;
  display: block;
}
.team-card .team-social {
  height: 20px;
  visibility: hidden;
  opacity: 0;
  transition: 0.4s;
}
.team-card .team-social a i {
  width: var(--icon-size);
  height: var(--icon-size);
  line-height: var(--icon-size);
  text-align: center;
  background-color: var(--smoke-color);
  color: var(--body-color);
  margin: 0 5px;
  transition: 0.3s;
}
.team-card .team-social a i:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.team-card:hover .team-content {
  transform: translateY(-55px);
}
.team-card:hover .team-social {
  height: var(--icon-size);
  visibility: visible;
  opacity: 1;
}

.vs-team-card2 {
  --team-space: 40px;
  position: relative;
}
.vs-team-card2 .team-content {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: var(--team-space);
  display: flex;
  align-items: flex-end;
}
.vs-team-card2 .team-desig {
  display: inline-block;
  color: var(--white-color);
  background-color: var(--theme-color);
  padding: 7px 30px;
}
.vs-team-card2 .team-name {
  padding: 15px 30px;
  background-color: var(--white-color);
  margin-bottom: 0;
  min-width: 214px;
}

.team-schedule {
  padding: 60px 60px 58px 60px;
  position: relative;
  margin-bottom: 70px;
}
.team-schedule .vs-btn {
  box-shadow: 0px 15px 65px 0px rgba(39, 71, 125, 0.06);
  background-color: var(--white-color);
  color: var(--theme-color);
  font-size: 30px;
  --icon-shape: 60px;
  --icon-size: 44px;
  padding: 0 50px;
  line-height: 80px;
  height: 80px;
  margin-bottom: -40px;
  position: absolute;
  left: calc(50% - 15px);
  bottom: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  display: inline-block;
  white-space: nowrap;
  border-radius: 100px;
  overflow: visible;
}
.team-schedule .vs-btn i {
  color: var(--white-color);
  font-size: 1.1rem;
}

.team-schedule-table {
  margin-top: -0.5em;
}
.team-schedule-table td {
  padding-left: 0;
  border-top: none;
  border-bottom: 1px dashed #dfdfe0;
}
.team-schedule-table td:last-child {
  text-align: right;
}
.team-schedule-table tr:first-child td {
  padding-top: 0;
}

.member-table {
  margin-top: 60px;
  margin-bottom: 60px;
}
.member-table th {
  color: var(--title-color);
}
.member-table th, .member-table td {
  font-size: 14px;
  border: none;
  padding: 20px 30px;
  vertical-align: top;
}
.member-table tr:nth-child(2n+1) th,
.member-table tr:nth-child(2n+1) td {
  background-color: var(--smoke-color);
}
.member-table .member-info-list {
  padding-left: 0;
  margin-bottom: 0;
}
.member-table .member-info-list li {
  list-style: none;
  position: relative;
  padding-left: 25px;
  margin-bottom: 5px;
}
.member-table .member-info-list li:last-child {
  margin-bottom: 0;
}
.member-table .member-info-list li:before {
  content: "\f00c";
  font-family: var(--icon-font);
  color: var(--theme-color);
  position: absolute;
  left: 0;
}

.form-title-box.bg-theme {
  padding: 60px;
}

.no-shadow {
  box-shadow: none;
}

/* Large devices */
@media (max-width: 1199px) {
  .team-card {
    --team-space: 30px;
  }

  .team-schedule .vs-btn {
    font-size: 24px;
    --icon-shape: 40px;
    --icon-size: 30px;
    padding: 0 30px;
    line-height: 60px;
    height: 60px;
    margin-bottom: -30px;
  }

  .team-schedule {
    padding: 40px 40px 40px 40px;
    margin-bottom: 65px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  #team-section {
    background-position: bottom right;
  }

  .team-schedule {
    padding: 40px 20px 40px 20px;
  }

  .form-title-box.bg-theme {
    padding: 30px 40px;
  }
}
/* Extra small devices */
@media (max-width: 575px) {
  .team-schedule .vs-btn {
    font-size: 18px;
    padding: 0 26px;
    line-height: 50px;
    height: 50px;
  }

  .form-title-box.bg-theme {
    padding: 20px;
  }

  .member-table th, .member-table td {
    padding: 20px 20px;
  }

  .team-card {
    --team-space: 0px;
    margin-bottom: 180px;
  }
  .team-card .team-content {
    padding-bottom: 35px;
  }
  .team-card .team-social {
    height: var(--icon-size);
    visibility: visible;
    opacity: 1;
  }
  .team-card .team-img img {
    width: 100%;
  }
  .team-card:hover .team-content {
    transform: translateY(0);
    padding-bottom: 35px;
  }
}
/*------------------- 5.21. Testimonial  -------------------*/
.testimonial-section {
  position: relative;
}

.testi-wrapper {
  position: relative;
}

.left-img-section {
  position: absolute;
  left: 0;
  top: 0;
  width: 43%;
  z-index: 1;
  height: 100%;
}
.left-img-section img {
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.testi-inner {
  padding-top: 92px;
  padding-left: 40px;
  padding-bottom: 92px;
}

.testi-content p {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.889;
  font-style: italic;
}

.rating i {
  color: var(--yellow-color);
}

.testi-thumb-wrapper {
  max-width: 550px;
}

.testi-thumb {
  --autho-size: 80px;
  opacity: 0.3;
  cursor: pointer;
  transition: 0.4s;
}
.testi-thumb .author-img {
  width: var(--autho-size);
  height: var(--autho-size);
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid;
  border-color: transparent;
  padding: 8px;
  margin-right: 20px;
}
.testi-thumb .author-img img {
  border-radius: 50%;
}
.testi-thumb a {
  color: var(--theme-color);
}
.testi-thumb a:hover {
  color: var(--title-color);
}
.testi-thumb.slick-current {
  opacity: 1;
}
.testi-thumb.slick-current .author-img {
  border-color: var(--theme-color);
}

.testi-thumb-text .testi-desig {
  line-height: 1.4;
  margin-top: 5px;
  font-size: 14px;
}
.testi-thumb-text .testi-desig a {
  color: inherit;
}
.testi-thumb-text .testi-desig a:hover {
  color: var(--theme-color);
}

.bg-smoke-gr {
  background-image: linear-gradient(to bottom, var(--smoke-color), transparent);
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .testi-inner {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .testi-inner {
    padding-top: 80px;
    padding-left: 0;
    padding-bottom: 80px;
  }

  .testi-content p {
    font-size: 16px;
    font-weight: 400;
  }
}
/*------------------- 5.22. Simple Sections  -------------------*/
.vs-info-table {
  border: 1px solid #e4e4e4;
  position: relative;
}
.vs-info-table::before {
  content: "";
  position: absolute;
  top: var(--border-gap, 4px);
  left: var(--border-gap, 4px);
  right: var(--border-gap, 4px);
  bottom: var(--border-gap, 4px);
  border: inherit;
}
.vs-info-table table {
  border: none;
  margin-bottom: 0;
  margin: var(--table-gap-y, 25px) var(--table-gap-x, 30px);
  width: auto;
}
.vs-info-table td {
  vertical-align: top;
  font-size: var(--font-size, 18px);
  font-weight: 500;
  color: var(--title-color);
  border: none;
  padding: var(--td-padding-y, 18px) var(--td-padding-x, 30px);
}
.vs-info-table td:first-child {
  width: 35%;
}
.vs-info-table td:last-child {
  width: 60%;
}
.vs-info-table tr:nth-child(even) {
  background-color: var(--td-bg, #f6f6f6);
}

.location-form {
  display: flex;
  align-items: center;
  background-color: var(--white-color);
  border-radius: 9999px;
  margin-right: 18px;
}
.location-form input {
  padding: 0 0 0 35px;
  border: none;
  font-weight: 500;
  height: 75px;
  flex: 1;
  font-size: 16px;
  color: var(--title-color);
  border-radius: 9999px 0 0 9999px;
}
.location-form input::-moz-placeholder {
  color: var(--title-color);
}
.location-form input::-webkit-input-placeholder {
  color: var(--title-color);
}
.location-form input:-ms-input-placeholder {
  color: var(--title-color);
}
.location-form input::placeholder {
  color: var(--title-color);
}
.location-form .vs-btn {
  height: 60px;
  line-height: 60px;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: -18px;
}
.location-form .vs-btn i {
  margin-left: 0;
  margin-right: 10px;
}

.text-shadow.fw-200 {
  text-shadow: 4px 4px 0px var(--white-color), -4px 0 0px var(--white-color), 7px 4px 0 var(--title-color);
}

.banner-box {
  position: relative;
  padding: 80px 0;
  height: 395px;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  text-align: center;
}
.banner-box .banner-content {
  position: relative;
  width: max-content;
  max-width: 340px;
  z-index: 2;
  margin: 0 60px;
}
.banner-box .banner-title {
  font-size: 34px;
  font-weight: 600;
  padding: 0 20px;
  margin-bottom: 10px;
  margin-top: -0.2em;
}
.banner-box .banner-text {
  margin-bottom: 22px;
  font-size: 16px;
}
.banner-box .banner-shape {
  position: absolute;
  top: -100px;
  left: -80px;
  width: 523px;
  height: 523px;
  animation-duration: 20s;
}

.shape-delay .banner-shape {
  animation-delay: 5s;
}

.price-touch {
  font-size: 14px;
  text-transform: uppercase;
  color: var(--title-color);
  font-weight: 600;
  line-height: 1;
  margin-bottom: 20px;
}
.price-touch span {
  font-size: 28px;
  color: var(--theme-color);
  margin-left: 7px;
  vertical-align: middle;
  position: relative;
  top: -2px;
}

.naved-thumb {
  width: var(--thumb-size, 145px);
  height: var(--thumb-size, 145px);
  padding: 5px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  border: 2px dashed var(--white-color);
  position: relative;
  cursor: pointer;
}
.naved-thumb:before {
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  right: 5px;
  bottom: 5px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: inherit;
  transition: all ease 0.4s;
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
}
.naved-thumb img {
  width: 100%;
  border-radius: inherit;
}

.slick-current .naved-thumb::before {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.naved-thumb-slide {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 100px;
  right: 0;
  padding-left: 20px;
  max-width: 700px;
  width: 100%;
}

.right-choose-wrapper {
  background-size: 100% auto;
  background-position: center center;
}

.contact-form-wrapper {
  padding: 60px;
}

.contact-form-title {
  margin-top: -0.3em;
}

.contact-info-table td,
.contact-info-table th {
  vertical-align: top;
  font-size: 18px;
  font-weight: 400;
  padding: 6px 10px;
  border-bottom: 1px dashed #dfdfe0;
  border-top: none;
}
.contact-info-table td:first-child,
.contact-info-table th:first-child {
  padding-left: 0;
}
.contact-info-table td:last-child,
.contact-info-table th:last-child {
  padding-right: 0;
}
.contact-info-table td + td {
  padding-right: 0;
  text-align: right;
}

.awards-box {
  text-align: center;
  margin-bottom: 26px;
}
.awards-box .awards-img {
  overflow: hidden;
  margin-bottom: 17px;
  border-radius: 50%;
}
.awards-box .awards-title {
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 0;
}

.search-inline {
  position: relative;
}
.search-inline .form-control {
  border: none;
  border-radius: 9999px;
  height: 70px;
  padding-left: 35px;
}
.search-inline button {
  position: absolute;
  right: 33px;
  top: 50%;
  transform: translateY(-50%);
  line-height: 1;
  padding: 0;
  border: none;
  background-color: transparent;
  color: var(--title-color);
  transition: all ease 0.4s;
  border-radius: 50%;
}
.search-inline button:hover {
  color: var(--theme-color);
}

.vs-social {
  --icon-size: 46px;
  --space-x: 2px;
}
.vs-social ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.vs-social li {
  display: inline-block;
  margin-right: var(--space-x);
}
.vs-social li:last-child {
  margin-right: 0;
}
.vs-social a {
  color: var(--white-color);
  background-color: rgba(0, 0, 0, 0.2);
  width: var(--icon-size);
  height: var(--icon-size);
  line-height: var(--icon-size);
  display: inline-block;
  text-align: center;
  border-radius: 50%;
}
.vs-social a:hover {
  background-color: var(--black-color);
  color: var(--white-color);
}
.vs-social.square a {
  border-radius: 5px;
}
.vs-social.sborder a {
  background-color: transparent;
  border: 1px solid var(--border-color);
}
.vs-social.sborder a:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
  border: 1px solid var(--theme-color);
}

.social-links {
  margin: 0;
  padding: 0;
  list-style-type: none;
  line-height: 1;
}
.social-links li {
  display: inline-block;
  margin-right: 15px;
}
.social-links li:last-child {
  margin-right: 0;
}
.social-links a {
  color: var(--body-color);
}
.social-links a:hover {
  color: var(--theme-color);
}

iframe {
  border: none;
}

/* Extra large devices */
@media (max-width: 1500px) {
  .right-choose-wrapper {
    background-size: auto calc(100% - 50px);
  }
}
/* Medium Large devices */
@media (max-width: 1399px) {
  .naved-thumb-slide {
    padding-left: 0;
  }
}
/* Large devices */
@media (max-width: 1199px) {
  .right-choose-wrapper {
    padding-top: 80px;
    padding-bottom: 80px;
    background-size: cover;
  }

  .naved-thumb-slide {
    bottom: 40px;
    --thumb-size: 90px;
  }
}
/* Medium devices */
@media (max-width: 991px) {
  .banner-box {
    padding: 20px 0;
    height: 295px;
  }
  .banner-box .banner-title {
    font-size: 26px;
  }
  .banner-box .vs-btn {
    padding: 13px 31px;
  }
  .banner-box .banner-content {
    max-width: 340px;
    z-index: 2;
    margin: 0 30px;
  }

  .search-inline .form-control {
    height: 60px;
  }

  .vs-social.style-btn-white {
    --icon-size: 60px;
    --space-x: 10px;
  }

  .contact-map.mt-70 {
    margin-top: 40px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .banner-box .banner-shape {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: none;
    border-radius: 0;
  }
  .banner-box .banner-shape:before {
    animation: none;
  }

  .contact-form-wrapper {
    padding: 30px;
  }

  .contact-info-table td,
.contact-info-table th {
    font-size: 16px;
    padding: 2px 7px;
  }

  .vs-info-table {
    --table-gap-y: 2px;
    --table-gap-x: 2px;
    --font-size: 14px;
    --td-padding-y: 6px;
    --td-padding-x: 10px;
    --border-gap: 1px;
  }
}
/* Extra small devices */
@media (max-width: 575px) {
  .contact-form-wrapper {
    padding: 20px;
  }

  .text-shadow.fw-200 {
    text-shadow: 2px 2px 0px var(--white-color), -2px 0 0px var(--white-color), 3px 2px 0 var(--title-color);
  }

  .naved-thumb-slide {
    padding: 0 20px;
    bottom: 0;
    --thumb-size: 80px;
  }
}
/*------------------- 5.23. Counter  -------------------*/
.vs-counter .counter-number {
  font-size: 60px;
  font-weight: 600;
  color: var(--theme-color);
}
.vs-counter .counter-icon {
  margin-bottom: 30px;
}
.vs-counter .counter-text {
  color: var(--title-color);
  font-size: 18px;
  margin-top: 5px;
}

.counter-border {
  margin-bottom: 25px;
  /* Medium devices */
  /* Small devices */
  /* Extra small devices */
}
.counter-border .vs-counter {
  border-right: 1px solid #c5c5c5;
  border-left: 1px solid #c5c5c5;
  margin: 0 1px;
}
.counter-border:first-child .vs-counter {
  border-left: none;
}
.counter-border:last-child .vs-counter {
  border-right: none;
}
@media (max-width: 991px) {
  .counter-border.col-sm-6:nth-child(even) .vs-counter {
    border-right: none;
  }
  .counter-border.col-sm-6:nth-child(odd) .vs-counter {
    border-left: none;
  }
}
@media (max-width: 767px) {
  .counter-border.col-sm-6:nth-child(even) .vs-counter {
    border-right: none;
  }
  .counter-border.col-sm-6:nth-child(odd) .vs-counter {
    border-left: none;
  }
}
@media (max-width: 575px) {
  .counter-border {
    margin-bottom: 0;
  }
  .counter-border .vs-counter {
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #c5c5c5;
    border-top: 1px solid #c5c5c5;
    padding: 25px 0 20px 0;
    margin: 1px 0;
  }
  .counter-border .vs-counter .counter-icon {
    margin-bottom: 13px;
  }
  .counter-border:last-child {
    margin-bottom: 18px;
  }
  .counter-border:last-child .vs-counter {
    border-bottom: none;
    padding-bottom: 0;
  }
  .counter-border:first-child .vs-counter {
    border-top: none;
    padding-top: 0;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .vs-counter .counter-number {
    font-size: 44px;
  }
  .vs-counter .counter-text {
    font-size: 14px;
  }
}
/*------------------- 5.24. Woocommerce  -------------------*/
.form-select.sortby {
  border: 1px solid var(--border-color);
  height: 33px;
  border-radius: 7px;
  background-position: right 15px top 5px !important;
  padding: 6px 20px;
  min-width: 80px;
}
.form-select.sortby option {
  margin-right: 15px;
}

.vs-sort-bar label {
  font-size: 14px;
  margin-bottom: 0;
  margin-right: 10px;
  line-height: 1;
  color: var(--body-color);
  white-space: nowrap;
}

.woocommerce-message,
.woocommerce-info {
  position: relative;
  border: 1px solid #d8d8d8;
  padding: 11px 20px;
  background-color: var(--body-color);
  color: var(--white-color);
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 30px;
  border-radius: 5px;
}
.woocommerce-message a,
.woocommerce-info a {
  color: inherit;
  text-decoration: underline;
}
.woocommerce-message a:hover,
.woocommerce-info a:hover {
  text-decoration: underline;
}
.woocommerce-message:before,
.woocommerce-info:before {
  content: "\f06a";
  font-family: var(--icon-font);
  font-weight: 900;
  margin-right: 10px;
}

.woocommerce-notices-wrapper .woocommerce-message {
  background-color: var(--theme-color);
}
.woocommerce-notices-wrapper .woocommerce-message:before {
  content: "\f14a";
  font-weight: 300;
}

.woocommerce-form-login-toggle .woocommerce-info {
  background-color: var(--theme-color);
}

.login-tab {
  margin-bottom: 30px;
  justify-content: center;
}
.login-tab button.nav-link {
  background-color: var(--smoke-color);
  color: var(--title-color);
  padding: 11px 39px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 15px ​15px 0;
}
.login-tab button.nav-link.active {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.woocommerce-cart-form {
  text-align: center;
}

.cart_table {
  border: 1px solid #eaf0f2;
  margin-bottom: 45px;
}
.cart_table thead {
  background-color: #ecf0f1;
}
.cart_table td:before,
.cart_table th {
  font-family: var(--title-font);
  color: var(--title-color);
  font-weight: 600;
  border: none;
  padding: 27px 15px;
}
.cart_table td:before {
  content: attr(data-title);
  position: absolute;
  left: 15px;
  top: 50%;
  vertical-align: top;
  padding: 0;
  transform: translateY(-50%);
  display: none;
}
.cart_table td {
  border: none;
  border-bottom: 1px solid #f3f3f3;
  color: #8b8b8b;
  padding: 20px 10px;
  position: relative;
  vertical-align: middle;
}
.cart_table .product-quantity {
  color: var(--title-color);
}
.cart_table .product-quantity input {
  position: relative;
  top: -2px;
}
.cart_table .cart-productname {
  font-weight: 400;
  font-family: var(--body-font);
  color: var(--body-color);
}
.cart_table .cart-productimage {
  display: inline-block;
  border: 2px solid var(--smoke-color);
}
.cart_table .remove {
  color: #29af8a;
  font-size: 18px;
}
.cart_table .quantity {
  display: inline-flex;
  align-items: center;
}
.cart_table .qut-btn {
  border: 2px solid var(--smoke-color);
  background-color: transparent;
  color: #b8c6d0;
  padding: 0;
  width: 30px;
  height: 30px;
  line-height: 25px;
  font-size: 16px;
  border-radius: 4px;
}
.cart_table .qut-btn:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.cart_table .qty-input {
  vertical-align: middle;
  border: 2px solid var(--smoke-color);
  width: 70px;
  height: 30px;
  font-size: 14px;
  text-align: center;
  color: #2c3e50;
  font-weight: 700;
  margin: 0 10px;
  border-radius: 4px;
  /* Firefox */
}
.cart_table .qty-input::-webkit-outer-spin-button, .cart_table .qty-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.cart_table .qty-input[type=number] {
  -moz-appearance: textfield;
}
.cart_table .actions {
  text-align: right;
  vertical-align: middle;
}
.cart_table .actions > .vs-btn {
  font-size: 16px;
  padding: 17px 28px;
  margin-right: 15px;
}
.cart_table .actions > .vs-btn:last-child {
  margin-right: 0;
}
.cart_table .vs-cart-coupon {
  float: left;
  margin: 0;
  width: 455px;
  max-width: 100%;
  display: flex;
}
.cart_table .vs-cart-coupon input {
  height: 50px;
  width: calc(100% - 200px);
  margin-right: 10px;
}
.cart_table .vs-cart-coupon .vs-btn {
  font-size: 16px;
  padding: 17px 22px;
  width: max-content;
}

.cart_totals {
  border: 1px solid #ecf0f1;
}
.cart_totals th,
.cart_totals td {
  vertical-align: top;
  padding: 20px 20px;
  border: none;
  border-bottom: 1px solid #ecf0f1;
  font-size: 14px;
  color: var(--title-color);
  width: 55%;
}
.cart_totals th:first-child,
.cart_totals td:first-child {
  width: 45%;
  background-color: #f9fbfb;
  font-weight: 700;
  font-size: 14px;
  color: #333333;
}
.cart_totals .shipping-calculator-button {
  display: inline-block;
  border-bottom: 1px solid;
  color: var(--title-color);
  font-weight: 700;
}
.cart_totals .shipping-calculator-button:hover {
  color: var(--theme-color);
}
.cart_totals .woocommerce-shipping-destination {
  margin-bottom: 10px;
}
.cart_totals .woocommerce-shipping-methods {
  margin-bottom: 0;
}
.cart_totals .woocommerce-shipping-methods input[type=radio] ~ label::before {
  padding-right: 1.2px;
  padding-top: 0.3px;
}
.cart_totals .shipping-calculator-form {
  display: none;
}
.cart_totals .shipping-calculator-form p:first-child {
  margin-top: 20px;
}
.cart_totals .shipping-calculator-form p:last-child {
  margin-bottom: 0;
}
.cart_totals .shipping-calculator-form .vs-btn {
  padding: 5px 30px;
}
.cart_totals .amount {
  font-weight: 700;
}
.cart_totals .order-total .amount {
  color: #1abc9c;
}

.shipping-calculator-form .form-select,
.shipping-calculator-form .form-control {
  height: 40px;
  padding-left: 15px;
  font-size: 16px;
  border-radius: 0;
  background-position: right 13px center;
}
.shipping-calculator-form .vs-btn {
  font-size: 14px;
  padding: 0 20px;
  width: max-content;
  height: 40px;
}

.checkout-ordertable th,
.checkout-ordertable td {
  border: none;
  vertical-align: top;
  padding: 5px 0;
  font-size: 14px;
  font-weight: 700;
  color: #2c3e50;
}
.checkout-ordertable ul {
  margin: 0;
  padding: 0;
}
.checkout-ordertable .order-total .amount {
  color: #29af8a;
}
.checkout-ordertable input[type=hidden] ~ label {
  color: #29af8a;
}

.woocommerce-checkout .form-group input:not(:last-child) {
  margin-bottom: var(--bs-gutter-x);
}

.checkout-ordertable th,
.checkout-ordertable td {
  border: 1px solid #ededed;
  text-align: right;
  padding: 5px 20px;
}
.checkout-ordertable th {
  text-align: left;
}

.woocommerce-checkout-payment ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.woocommerce-checkout-payment ul li {
  padding-top: 10px;
  border-bottom: 1px solid #d8d8d8;
  border-radius: 4px;
  font-size: 16px;
}
.woocommerce-checkout-payment ul input[type=radio] ~ label {
  margin-bottom: 19px;
  color: var(--body-color);
}
.woocommerce-checkout-payment ul input[type=radio] ~ label img {
  margin-bottom: -2px;
  margin-left: 10px;
}
.woocommerce-checkout-payment .place-order {
  padding-top: 30px;
}
.woocommerce-checkout-payment .payment_box {
  color: #a1b1bc;
  background-color: #ecf0f1;
  border: 1px solid #d8d8d8;
  border-bottom: none;
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 4px;
  display: none;
}
.woocommerce-checkout-payment .payment_box p {
  margin: 0;
}

.star-rating {
  overflow: hidden;
  position: relative;
  width: 100px;
  height: 1.2em;
  line-height: 1.2em;
  display: block;
  font-family: var(--icon-font);
  font-weight: 700;
  font-size: 14px;
}
.star-rating:before {
  content: "\f005\f005\f005\f005\f005";
  color: #e1e1e1;
  float: left;
  top: 0;
  left: 0;
  position: absolute;
  letter-spacing: 3px;
}
.star-rating span {
  overflow: hidden;
  float: left;
  top: 0;
  left: 0;
  position: absolute;
  padding-top: 1.5em;
}
.star-rating span:before {
  content: "\f005\f005\f005\f005\f005";
  top: 0;
  position: absolute;
  left: 0;
  color: var(--yellow-color);
  letter-spacing: 3px;
}

.rating-select label {
  margin: 0;
  margin-right: 10px;
}
.rating-select p.stars {
  margin-bottom: 0;
  line-height: 1;
}
.rating-select p.stars a {
  position: relative;
  height: 14px;
  width: 18px;
  text-indent: -999em;
  display: inline-block;
  text-decoration: none;
}
.rating-select p.stars a::before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 14px;
  line-height: 1;
  font-family: var(--icon-font);
  content: "\f005";
  font-weight: 400;
  text-indent: 0;
  color: var(--yellow-color);
}
.rating-select p.stars a:hover ~ a::before {
  content: "\f005";
  font-weight: 400;
}
.rating-select p.stars:hover a::before {
  content: "\f005";
  font-weight: 700;
}
.rating-select p.stars.selected a.active::before {
  content: "\f005";
  font-weight: 700;
}
.rating-select p.stars.selected a.active ~ a::before {
  content: "\f005";
  font-weight: 400;
}
.rating-select p.stars.selected a:not(.active)::before {
  content: "\f005";
  font-weight: 700;
}

.woocommerce-error {
  background-color: var(--error-color);
  color: #fff;
  list-style: none;
  padding: 10px 26px;
  margin: 0 0 30px 0;
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
}

.vs-checkout-wrapper form.woocommerce-form {
  margin-bottom: 25px;
}

.tinv-wishlist .cart-empty {
  padding: 12px 25px;
  background-color: #eee;
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
}
.tinv-wishlist p.return-to-shop .button {
  display: inline-block;
  background-color: var(--theme-color);
  color: #fff;
  font-size: 14px;
  padding: 10px 25px;
  border-radius: 4px;
  margin-top: 10px;
  font-weight: 700;
}
.tinv-wishlist p.return-to-shop .button:Hover {
  background-color: var(--title-color);
  color: var(--white-color);
}
.tinv-wishlist table.tinvwl-table-manage-list {
  font-size: 14px;
}
.tinv-wishlist table.tinvwl-table-manage-list button {
  border-radius: 5px;
}
.tinv-wishlist .product-stock i {
  margin-right: 5px;
}
.tinv-wishlist .tinv-modal .icon_big_times {
  margin-bottom: 5px;
  color: var(--theme-color);
}
.tinv-wishlist button.button {
  border: none;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-weight: 700;
  background-color: var(--theme-color);
  color: #fff;
}
.tinv-wishlist button.button:hover {
  background-color: var(--title-color);
  color: #fff;
}
.tinv-wishlist button.button i {
  font-size: 1rem !important;
  margin-right: 3px !important;
}
.tinv-wishlist th,
.tinv-wishlist td.product-name {
  font-size: 16px;
  font-weight: 700;
  font-family: var(--title-font);
}
.tinv-wishlist td.product-name a {
  color: var(--title-color);
}
.tinv-wishlist td.product-name a:hover {
  color: var(--theme-color);
}
.tinv-wishlist td.product-price del {
  font-size: 0.9em;
}
.tinv-wishlist .social-buttons > span {
  font-weight: 700;
  margin-right: 10px;
  font-family: var(--title-font);
  color: var(--title-color);
}
.tinv-wishlist .social-buttons li a.social {
  background-color: var(--theme-color);
  color: #fff;
  width: 30px;
  height: 30px;
  font-size: 14px;
  line-height: 30px;
}
.tinv-wishlist .social-buttons li a.social i {
  line-height: inherit;
}
.tinv-wishlist .social-buttons li a.social:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}

nav.woocommerce-MyAccount-navigation li {
  border: 1px solid #ddd;
  margin: 0;
  border-top: none;
}
nav.woocommerce-MyAccount-navigation li:first-child {
  border-top: 1px solid #ddd;
}
nav.woocommerce-MyAccount-navigation li a {
  color: var(--title-color);
  font-weight: 700;
  padding: 7px 17px;
  display: block;
}
nav.woocommerce-MyAccount-navigation li.is-active a,
nav.woocommerce-MyAccount-navigation li a:hover {
  color: var(--white-color);
  background-color: var(--theme-color);
}

.woocommerce-MyAccount-content h3 {
  margin-top: -0.3em;
}
.woocommerce-MyAccount-content .btn {
  background-color: var(--theme-color);
  color: var(--white-color);
  font-size: 14px;
  padding: 10px 25px;
  font-weight: 700;
}
.woocommerce-MyAccount-content .btn:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}

table.variations,
.woocommerce-grouped-product-list.group_table {
  border-collapse: separate;
  border-spacing: 0 15px;
  margin-bottom: 5px;
}
table.variations td,
.woocommerce-grouped-product-list.group_table td {
  border: none;
  vertical-align: middle;
  padding: 0 5px;
}
table.variations td:first-child,
.woocommerce-grouped-product-list.group_table td:first-child {
  padding: 0;
}
table.variations label,
.woocommerce-grouped-product-list.group_table label {
  margin: 0;
  font-size: 14px;
  text-transform: capitalize;
}
table.variations label a,
.woocommerce-grouped-product-list.group_table label a {
  color: var(--title-color);
}
table.variations label a:hover,
.woocommerce-grouped-product-list.group_table label a:hover {
  color: var(--theme-color);
}

.woocommerce-grouped-product-list.group_table .woocommerce-Price-amount.amount {
  font-size: 16px;
  color: var(--title-color);
}
.woocommerce-grouped-product-list.group_table label {
  margin: 0 0 0 10px;
  margin: 0 0 0 10px;
  font-family: var(--title-font);
  font-size: 18px;
}

table.variations td {
  padding: 0;
}
table.variations select {
  width: max-content;
  font-weight: 400;
  line-height: 1.5;
  vertical-align: middle;
  margin: 0;
  padding-right: 35px;
}
table.variations .reset_variations {
  margin-left: 12px;
  display: inline-block;
}

.wooscp-table-items td.woocommerce-product-attributes-item__value {
  padding-left: 15px !important;
}
.wooscp-table-items a.added_to_cart.wc-forward {
  margin-left: 15px;
  text-decoration: underline;
}

#woosq-popup .product_title.entry-title {
  margin-bottom: 0;
}
#woosq-popup .fs-xs.my-4 {
  margin: 10px 0 0 0 !important;
}
#woosq-popup .single-product .product {
  margin-bottom: 0 !important;
}
#woosq-popup .slick-slider .slick-arrow {
  border: none;
  margin: 0 10px;
}
#woosq-popup .woocommerce-product-rating {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  font-size: 14px;
}

table.woocommerce-grouped-product-list.group_table {
  border-collapse: collapse;
  margin-bottom: 15px;
}
table.woocommerce-grouped-product-list.group_table .qty-input {
  border-color: #e3e6e9;
}
table.woocommerce-grouped-product-list.group_table tr {
  border-bottom: 1px solid #e3e6e9;
}
table.woocommerce-grouped-product-list.group_table tr:last-child {
  border-bottom: none;
}
table.woocommerce-grouped-product-list.group_table td {
  padding: 30px 5px;
}

/* Medium devices */
@media (max-width: 991px) {
  .cart_table th {
    padding: 23px 8px;
    font-size: 14px;
  }
  .cart_table .cart-productname {
    font-size: 14px;
  }
  .cart_table .vs-cart-coupon {
    width: 100%;
    margin-bottom: 20px;
  }
  .cart_table .actions {
    text-align: center;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .cart_table {
    text-align: left;
    min-width: auto;
    border-collapse: separate;
    border-spacing: 0 20px;
    border: none;
  }
  .cart_table thead {
    display: none;
  }
  .cart_table td {
    padding: 15px;
    display: block;
    width: 100%;
    padding-left: 25%;
    text-align: right;
    border: 1px solid #f3f3f3;
    border-bottom: none;
  }
  .cart_table td::before {
    display: block;
  }
  .cart_table td:last-child {
    border-bottom: 1px solid #f3f3f3;
  }
  .cart_table td.actions {
    padding-left: 15px;
    text-align: center;
  }
  .cart_table td.actions > .vs-btn {
    margin-top: 10px;
    margin-right: 0;
    display: block;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
  }
  .cart_table td.actions > .vs-btn:last-child {
    margin-right: auto;
  }
  .cart_table .vs-cart-coupon {
    width: 100%;
    text-align: center;
    float: none;
    justify-content: center;
    display: block;
    padding-bottom: 10px;
  }
  .cart_table .vs-cart-coupon input {
    width: 100%;
    margin-bottom: 10px;
  }

  tfoot.checkout-ordertable th {
    display: none;
  }

  .woocommerce-checkout-payment ul input[type=radio] ~ label img {
    max-width: 150px;
  }

  .woocommerce-message,
.woocommerce-info {
    font-size: 12px;
    padding: 5px 10px;
  }

  .cart_totals th,
.cart_totals td {
    padding: 15px 10px;
  }
  .cart_totals th:first-child,
.cart_totals td:first-child {
    width: 17%;
    line-height: 1.4;
  }
}
/*------------------- 5.24. Woocommerce  -------------------*/
.woocommerce-cart-form {
  text-align: center;
}

.cart_table {
  border: 1px solid #eaf0f2;
  margin-bottom: 45px;
}
.cart_table thead {
  background-color: #ecf0f1;
}
.cart_table thead th {
  border: none !important;
}
.cart_table td:before,
.cart_table th {
  font-family: var(--title-font);
  color: var(--title-color);
  font-weight: 600;
  border: none;
  padding: 27px 15px;
}
.cart_table td:before {
  content: attr(data-title);
  position: absolute;
  left: 15px;
  top: 50%;
  vertical-align: top;
  padding: 0;
  transform: translateY(-50%);
  display: none;
}
.cart_table td {
  border: none;
  border-bottom: 1px solid #f3f3f3;
  color: #8b8b8b;
  padding: 20px 10px;
  position: relative;
  vertical-align: middle;
}
.cart_table .product-quantity {
  color: var(--title-color);
}
.cart_table .product-quantity input {
  position: relative;
  top: -2px;
}
.cart_table .cart-productname {
  font-weight: 400;
  font-family: var(--body-font);
  color: var(--body-color);
}
.cart_table .cart-productimage {
  display: inline-block;
  border: 2px solid var(--smoke-color);
}
.cart_table .remove {
  color: #29af8a;
  font-size: 18px;
}
.cart_table .quantity {
  display: inline-flex;
  align-items: center;
}
.cart_table .qut-btn {
  border: 2px solid var(--smoke-color);
  background-color: transparent;
  color: #b8c6d0;
  padding: 0;
  width: 30px;
  height: 30px;
  line-height: 25px;
  font-size: 16px;
  border-radius: 4px;
  position: unset;
}
.cart_table .qut-btn:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}
.cart_table .qty-input {
  vertical-align: middle;
  border: 2px solid var(--smoke-color);
  width: 70px;
  height: 30px;
  font-size: 14px;
  text-align: center;
  color: #2c3e50;
  font-weight: 700;
  margin: 0 10px;
  border-radius: 4px;
  padding-right: 0;
  /* Firefox */
}
.cart_table .qty-input::-webkit-outer-spin-button, .cart_table .qty-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.cart_table .qty-input[type=number] {
  -moz-appearance: textfield;
}
.cart_table .actions {
  text-align: right;
  vertical-align: middle;
}
.cart_table .actions > .vs-btn {
  font-size: 16px;
  padding: 17px 28px;
  margin-right: 15px;
}
.cart_table .actions > .vs-btn:last-child {
  margin-right: 0;
}
.cart_table .vs-cart-coupon {
  float: left;
  margin: 0;
  width: 455px;
  max-width: 100%;
  display: flex;
}
.cart_table .vs-cart-coupon input {
  height: 50px;
  width: calc(100% - 200px);
  margin-right: 10px;
}
.cart_table .vs-cart-coupon .vs-btn {
  font-size: 16px;
  padding: 17px 22px;
  width: max-content;
}

.cart_totals {
  border: 1px solid #ecf0f1;
}
.cart_totals th,
.cart_totals td {
  vertical-align: top;
  padding: 20px 20px;
  border: none;
  border-bottom: 1px solid #ecf0f1;
  font-size: 14px;
  color: var(--title-color);
  width: 55%;
}
.cart_totals th:first-child,
.cart_totals td:first-child {
  width: 45%;
  background-color: #f9fbfb;
  font-weight: 700;
  font-size: 14px;
  color: #333333;
}
.cart_totals .shipping-calculator-button {
  display: inline-block;
  border-bottom: 1px solid;
  color: var(--title-color);
  font-weight: 700;
}
.cart_totals .shipping-calculator-button:hover {
  color: var(--theme-color);
}
.cart_totals .woocommerce-shipping-destination {
  margin-bottom: 10px;
}
.cart_totals .woocommerce-shipping-methods {
  margin-bottom: 0;
}
.cart_totals .woocommerce-shipping-methods input[type=radio] ~ label::before {
  padding-right: 1.2px;
  padding-top: 0.3px;
}
.cart_totals .shipping-calculator-form {
  display: none;
}
.cart_totals .shipping-calculator-form p:first-child {
  margin-top: 20px;
}
.cart_totals .shipping-calculator-form p:last-child {
  margin-bottom: 0;
}
.cart_totals .shipping-calculator-form .vs-btn {
  padding: 5px 30px;
}
.cart_totals .amount {
  font-weight: 700;
}
.cart_totals .order-total .amount {
  color: #1abc9c;
}

/* Medium devices */
@media (max-width: 991px) {
  .cart_table th {
    padding: 23px 8px;
    font-size: 14px;
  }
  .cart_table .cart-productname {
    font-size: 14px;
  }
  .cart_table .vs-cart-coupon {
    width: 100%;
    margin-bottom: 20px;
  }
  .cart_table .actions {
    text-align: center;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .cart_table {
    text-align: left;
    min-width: auto;
    border-collapse: separate;
    border-spacing: 0 20px;
    border: none;
  }
  .cart_table thead {
    display: none;
  }
  .cart_table td {
    padding: 15px;
    display: block;
    width: 100%;
    padding-left: 25%;
    text-align: right;
    border: 1px solid #f3f3f3;
    border-bottom: none;
  }
  .cart_table td::before {
    display: block;
  }
  .cart_table td:last-child {
    border-bottom: 1px solid #f3f3f3;
  }
  .cart_table td.actions {
    padding-left: 15px;
    text-align: center;
  }
  .cart_table td.actions > .vs-btn {
    margin-top: 10px;
    margin-right: 0;
    display: block;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
  }
  .cart_table td.actions > .vs-btn:last-child {
    margin-right: auto;
  }
  .cart_table .vs-cart-coupon {
    width: 100%;
    text-align: center;
    float: none;
    justify-content: center;
    display: block;
    padding-bottom: 10px;
  }
  .cart_table .vs-cart-coupon input {
    width: 100%;
    margin-bottom: 10px;
  }

  .cart_totals th,
.cart_totals td {
    padding: 15px 10px;
  }
  .cart_totals th:first-child,
.cart_totals td:first-child {
    width: 17%;
    line-height: 1.4;
  }
}
/*------------------- 5.24. Woocommerce  -------------------*/
.woocommerce-checkout .form-group {
  margin-bottom: var(--bs-gutter-x);
}
.woocommerce-checkout .select2-container--open .select2-dropdown--below {
  margin-top: 30px;
}
.woocommerce-checkout .select2-container--open .select2-dropdown--above {
  position: relative;
  bottom: -30px;
}
.woocommerce-checkout .select2-dropdown {
  border: 1px solid #e3e6e9;
  border-top: none;
}
.woocommerce-checkout .select2-container--default .select2-selection--single .select2-selection__rendered,
.woocommerce-checkout .form-control:focus {
  color: var(--body-color);
}

.select2-container--default .select2-selection--single {
  height: 60px;
  border: 1px solid #e3e6e9;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 60px;
  padding-left: 30px;
  padding-right: 25px;
}

.woocommerce-billing-fields .form-row {
  margin-bottom: 0;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b:before {
  content: "\f107";
  font-family: var(--icon-font);
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  margin: 0;
  border: none;
  top: 0;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 60px;
  line-height: 60px;
  margin-right: 18px;
}

span.select2-selection.select2-selection--single:focus {
  outline: none;
}

.shipping-calculator-form .form-select,
.shipping-calculator-form .form-control {
  height: 40px;
  padding-left: 15px;
  font-size: 16px;
  border-radius: 10px;
  background-position: right 13px top 7px !important;
}
.shipping-calculator-form .vs-btn {
  font-size: 14px;
  padding: 0 20px;
  width: max-content;
  height: 40px;
}

.woocommerce-form-login {
  display: none;
}
.woocommerce-form-login .form-group {
  margin-bottom: 20px;
}
.woocommerce-form-login label {
  margin-bottom: 0.3em;
}

.woocommerce-form-coupon {
  display: none;
}
.woocommerce-form-coupon .form-group {
  margin-bottom: 20px;
}

.woocommerce-checkout .shipping_address {
  display: none;
}

.checkout-ordertable th,
.checkout-ordertable td {
  border: none;
  vertical-align: top;
  padding: 5px 0;
  font-size: 14px;
  font-weight: 700;
  color: #2c3e50;
}
.checkout-ordertable ul {
  margin: 0;
  padding: 0;
}
.checkout-ordertable .order-total .amount {
  color: var(--theme-color);
}
.checkout-ordertable input[type=hidden] ~ label {
  color: var(--theme-color);
}

.woocommerce-checkout .form-group input:not(:last-child) {
  margin-bottom: var(--bs-gutter-x);
}

.checkout-ordertable th,
.checkout-ordertable td {
  border: 1px solid #ededed;
  text-align: right;
  padding: 5px 20px;
}
.checkout-ordertable th {
  text-align: left;
}

.woocommerce-checkout-payment {
  text-align: left;
}
.woocommerce-checkout-payment ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.woocommerce-checkout-payment ul li {
  padding-top: 10px;
  border-bottom: 1px solid #d8d8d8;
  border-radius: 4px;
  font-size: 16px;
}
.woocommerce-checkout-payment ul input[type=radio] ~ label {
  margin-bottom: 19px;
  color: var(--body-color);
  width: 100%;
}
.woocommerce-checkout-payment ul input[type=radio] ~ label img {
  margin-left: 30px;
  float: right;
  position: relative;
  top: 1px;
}
.woocommerce-checkout-payment .place-order {
  padding-top: 30px;
}
.woocommerce-checkout-payment .payment_box {
  color: #a1b1bc;
  background-color: #ecf0f1;
  border: 1px solid #d8d8d8;
  border-bottom: none;
  font-size: 14px;
  padding: 10px 20px;
  border-radius: 4px;
  display: none;
}
.woocommerce-checkout-payment .payment_box p {
  margin: 0;
}

.vs-checkout-wrapper form.woocommerce-form {
  margin-bottom: 25px;
}

/* Small devices */
@media (max-width: 767px) {
  tfoot.checkout-ordertable th {
    display: none;
  }

  .woocommerce-checkout-payment ul input[type=radio] ~ label img {
    max-width: 150px;
  }

  .checkout-ordertable th, .checkout-ordertable td {
    padding-left: 25%;
  }
}
/*------------------- 5.24. Woocommerce  -------------------*/
.tinv-wishlist input[type=checkbox] {
  display: inline-block;
  opacity: 1;
  visibility: visible;
  width: auto;
  height: auto;
}
.tinv-wishlist .cart-empty {
  padding: 12px 25px;
  background-color: #eee;
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
}
.tinv-wishlist p.return-to-shop .button {
  display: inline-block;
  background-color: var(--theme-color);
  color: #fff;
  font-size: 14px;
  padding: 10px 25px;
  border-radius: 4px;
  margin-top: 10px;
  font-weight: 700;
}
.tinv-wishlist p.return-to-shop .button:Hover {
  background-color: var(--title-color);
  color: var(--white-color);
}
.tinv-wishlist table th {
  color: var(--title-color);
}
.tinv-wishlist table td, .tinv-wishlist table th {
  padding: 10px;
  border: 1px solid var(--border-color);
  text-align: center;
}
.tinv-wishlist .product-cb,
.tinv-wishlist .product-remove {
  width: 40px;
  text-align: center;
}
.tinv-wishlist .product-thumbnail {
  width: 110px;
}
.tinv-wishlist .stock.in-stock {
  margin-bottom: 0;
}
.tinv-wishlist ins {
  text-decoration: none;
}
.tinv-wishlist .product-remove button {
  border: none;
  height: 22px;
  width: 22px;
  text-align: center;
  font-size: 12px;
  line-height: 22px;
}
.tinv-wishlist .tinvwl-mobile {
  display: none;
}
.tinv-wishlist .social-buttons {
  display: flex;
  max-width: 295px;
  margin-left: auto;
  align-items: center;
}
.tinv-wishlist .social-buttons ul {
  padding-left: 0;
  margin-bottom: 0;
  margin-left: auto;
}
.tinv-wishlist table.tinvwl-table-manage-list {
  font-size: 14px;
}
.tinv-wishlist table.tinvwl-table-manage-list button {
  border-radius: 5px;
}
.tinv-wishlist .product-stock i {
  margin-right: 5px;
}
.tinv-wishlist .tinv-modal .icon_big_times {
  margin-bottom: 5px;
  color: var(--theme-color);
}
.tinv-wishlist button.button {
  border: none;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-weight: 700;
  background-color: var(--theme-color);
  color: #fff;
  padding: 1px 15px;
}
.tinv-wishlist button.button i {
  font-size: 14px !important;
  margin-right: 3px !important;
}
.tinv-wishlist th,
.tinv-wishlist td.product-name {
  font-size: 16px;
  font-weight: 700;
  font-family: var(--title-font);
}
.tinv-wishlist td.product-name a {
  color: var(--title-color);
}
.tinv-wishlist td.product-name a:hover {
  color: var(--theme-color);
}
.tinv-wishlist td.product-price del {
  font-size: 0.9em;
}
.tinv-wishlist .social-buttons > span {
  font-weight: 700;
  margin-right: 10px;
  font-family: var(--title-font);
  color: var(--title-color);
}
.tinv-wishlist .social-buttons li a.social {
  background-color: var(--theme-color);
  color: #fff;
  width: 30px;
  height: 30px;
  font-size: 14px;
  line-height: 30px;
}
.tinv-wishlist .social-buttons li a.social i {
  line-height: inherit;
}
.tinv-wishlist .social-buttons li a.social:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}

/* Medium devices */
@media (max-width: 991px) {
  .tinvwl-full {
    display: none;
  }

  .tinv-wishlist .tinvwl-mobile {
    display: block;
  }

  .tinvwl-txt {
    display: none;
  }

  .product-stock {
    width: 40px;
    text-align: center;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .tinv-wishlist table {
    table-layout: fixed;
  }

  .tinv-wishlist table.tinvwl-table-manage-list tbody td.product-remove, .tinv-wishlist table.tinvwl-table-manage-list thead th:not(.product-name) {
    display: none;
  }

  .tinv-wishlist table.tinvwl-table-manage-list tbody td {
    display: block;
    width: 100% !important;
    text-align: center;
  }

  .product-name {
    text-align: center;
  }

  .tinv-wishlist table td,
.tinv-wishlist table th {
    border-bottom: none;
  }
  .tinv-wishlist table tbody {
    border-bottom: 1px solid var(--border-color);
  }

  .tinv-wishlist .social-buttons {
    max-width: 100%;
    margin-left: unset;
    flex-direction: column;
  }
  .tinv-wishlist .social-buttons ul {
    margin-left: unset;
    margin-top: 5px;
  }

  .tinvwl-txt {
    display: inline-block;
  }
}