.header-wrapper {
    position: relative;
    z-index: 2;
}
.tr-20 {
	&::before {
        content: "";
        height: 20px;
        width: 100%;
        background-color: $theme-color;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
}
.header-top-area {
    background-color: $theme-color;
    color: $white-color;
    padding: 16px 0 16px 0;
    position: relative;
    z-index: 3;
    a {
        color: $white-color;
        &:hover {
            color: $title-color;
        }
    }
    .dropdown-menu {
        top: 18px !important;
        &:before {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 12px 12px 12px;
            border-color: transparent transparent $white-color transparent;
            position: absolute;
            top: -12px;
            left: 65px;
        }
        li {
            a {
                color: $title-color;
                &:hover {
                    color: $theme-color;
                }
            }
        }
    }
    span {
        line-height: 1;
    }
    &.style2,
    &.style3 {
        padding: 16px 0;
    }
    &.style3 {
        background-color: $smoke-color;
    }
}
.header-top-shape {
	position: absolute;
	top: 0;
	left: 0;
	background-color: $theme-color;
	height: 100%;
	width: 43.5%;
    z-index: -1;
    clip-path: polygon(0px 0px, 100% 0%, 96% 100%, 0% 100%);
}
.head-top-widget {
    display: inline-block;
}
.radio-icon {
    height: 12px;
    width: 12px;
    display: inline-block;
    background-color: $white-color;
    border: 1px solid $white-color;
    border-radius: 50px;
    box-shadow: inset 0 0 0 2px $theme-color;
}
.border-right {
    border-right: 1px solid rgb(254, 112, 95);
}
.vs-social-group {
    a {
        margin-left: 25px;
    }
}
.menu-icons {
	display: flex;
}
.cart-button {
    border: none;
}
.cart-btn-wrapper {
    position: relative;
}
.shopping-cart-wrapper {
	position: absolute;
	top: calc(100% + 27px);
	left: 50%;
    transform: translateX(-50%);
	background-color: #fff;
	padding: 30px;
	border-radius: 10px;
    min-width: 340px;
    text-align: left;
    visibility: hidden;
    opacity: 0;
    z-index: 10;
    &:before {
        content: "";
        height: 0;
        width: 0;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent $white-color transparent;
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
    }
    &.show {
        visibility: visible;
        opacity: 1;
    }
}
.woocommerce-mini-cart__buttons {
    margin-bottom: 0;
}

.logo-wrapper {
	background-color: $white-color;
	text-align: center;
    box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
    position: relative;
    height: 100%;
    flex-direction: column;
    .support-link {
        font-size: 14px;
        background-color: $theme-overlay;
        padding: 7px 33px;
        border-radius: 0 0 25px 25px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    .header-logo {
        padding-top: 40px;
    }
}

.header-contact {
	background-color: #fff;
    box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
    padding: 50px 0;
    position: relative;
    z-index: 2;
}
.dropdown-toggle::after {
	content: "";
	font-family: var(--icon-font);
	border: none;
	vertical-align: 0;
	font-size: 14px;
}
// Header Shape --------------------------------
.header-shape {
    @include equal-size(100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    overflow: hidden;
    .before, .after {
        @include equal-size(80px);
        background-color: $theme-overlay;
        position: absolute;
        border-radius: 50%;
        top: -35px;
        left: -35px;
        overflow: hidden;
        border: 1px solid $theme-overlay;
        box-shadow: inset 0 0 0 10px $white-color;
    }
    .after {
        bottom: -35px;
        right: -35px;
        left: unset;
        top: unset;
    }
    &.style-2 {
        .before {
            left: -45px;
            top: -45px;
        }
        .after {
            bottom: -45px;
            right: -45px;
        }
    }
}

//contact-box
.contact-box {
    display: flex;
    align-items: center;
    .icon-box {
        margin-right: 14px;
        float: left;
        color: $theme-color;
        font-size: var(--icon-size, 36px);
    }
  
    .contact-content {
        overflow: hidden;
  
        p {
            color: var(--white-color);
            font-size: 12px;
            font-weight: 300;
            margin-bottom: 3px;
        }
  
        .contact-title {
            font-weight: 500;
            margin-bottom: 0;
            a {
                color: inherit;
                &:hover {
                    color: $theme-color;
                }
            }
            @include ml {
                font-size: 16px;
            }
        }
        .text-light {
            font-size: 14px;
            margin-bottom: 4px;
            display: block;
        }
    }
}
.mr-6 {
    margin-right: 6px;
}

// Menu Style 
.main-menu {
    ul {
        padding-left: 0;
        margin-bottom: 0;
        li {
            display: inline-block;
            list-style-type: none;
            margin-right: 32px;
            position: relative;
            a {
                display: inline-block;
                font-size: 14px;
                font-weight: 600;
                color: $title-color;
                padding: 20px 0;
            }
            &.menu-item-has-children > a::after {
                content: '\f107';
                position: relative;
                font-family: $icon-font;
                margin-left: 5px;
                top: 1px;
                font-size: 1rem;
                font-weight: 500;
            }
            &:hover {
                > ul.sub-menu, 
                > ul.mega-menu {
                    visibility: visible;
                    opacity: 1;
                    margin-top: 0;
                    z-index: 20;
                }
                > a {
                    color: $theme-color;
                }
            }
        }
        &.sub-menu, 
        &.mega-menu {
            position: absolute;
            text-align: left;
            top: 100%;
            left: 0;
            background-color: $white-color;
            box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1);
            visibility: hidden;
            min-width: 190px;
            width: -webkit-max-content;
            width: -moz-max-content;
            width: max-content;
            padding: 7px;
            margin-top: 50px;
            opacity: 0;
            z-index: -1;
            border-bottom: 3px solid $theme-color;
            box-shadow: 0px 10px 60px 0px rgba(0,0,0,0.09),0px 3px 0px 0px rgba(231,13,60,0.004);
            -webkit-transform-origin: top center;
            -ms-transform-origin: top center;
            transform-origin: top center;
            -webkit-transition: margin-top 0.4s ease-in-out 0s, visibility 0.4s ease-in-out 0s, opacity 0.4s ease-in-out 0s, z-index 0s;
            transition: margin-top 0.4s ease-in-out 0s, visibility 0.4s ease-in-out 0s, opacity 0.4s ease-in-out 0s, z-index 0s;
            li {
                display: block;
                a {
                    position: relative;
                    padding: 0;
                    line-height: 28px;
                }
            }
        }
        &.sub-menu {
            padding: 18px 15px;
            &::before {
                content: '';
                position: absolute;
                left: 29px;
                top: 30px;
                width: 1px;
                background-color: #ededed;
                height: calc(100% - 60px);
            }
            li {
                padding: 3px 9px;
                a {
                    position: relative;
                    padding-left: 20px;
                    &:before {
                        content: '\f111';
                        position: absolute;
                        top: 2.8em;
                        left: 0;
                        font-family: $icon-font;
                        @include equal-size(11px);
                        text-align: center;
                        border-radius: 50%;
                        display: inline-block;
                        font-size: 0.2em;
                        line-height: 12px;
                        color: $theme-color;
                        font-weight: 700;
                        background-color: $white-color;
                        box-shadow: inset 0px 2px 4px 0px rgba(255, 51, 51, .4);
                    }
                }
            }
        }
        &.mega-menu {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
            justify-content: space-between;
            text-align: left;
            width: 100%;
            max-width: var(--main-container);
            padding: 20px 15px 23px 15px;
            left: 50%;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            > li {
                display: block;
                width: 100%;
                padding: 0 15px;
                > a {
                    display: block;
                    padding: 0;
                    font-size: 16px;
                    padding-bottom: 8px;
                    margin-bottom: 10px;
                    text-transform: capitalize;
                    letter-spacing: 1px;
                    font-weight: 700;
                    color: $title-color;
                    border-bottom: 1px dashed;
                    border-color: $theme-color;
                }
                li {
                    padding: 4px 0;
                }
            }
        }
    }
    &.menu-style2 {
        > ul {
            > li {
                margin-right: 32px;
                > a {
                    padding: 25px 0;
                }
            }
        }
    }
    .mega-menu-wrap {
        position: static;
    }
}

// Top Icons 
.top-widget {
    .icon {
        color: $title-color;
        font-size: 14px;
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.07);
        background-color: $white-color;
        @include equal-size(40px);
        line-height: 44px;
        display: inline-block;
        text-align: center;
        border-radius: 50%;
        position: relative;
        &:hover {
            background-color: $title-color;
            color: $white-color;
            .badge {
                background-color: $theme-color;
            }
        }
    }
    .badge {
        color: $white-color;
        font-size: 10px;
        font-weight: 300;
        background-color: $title-color;
        width: fit-content;
        padding: .35em .46em;
        line-height: 8px;
        top: 0;
        right: 0;
    }
}

//-----------------------------------------
.sticky-header-wrap {
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    box-shadow: 0 0 10px 0 rgba($color: #000000, $alpha: 0.1);
    transition: all ease 0.4s;
    transform: translateY(-110%);
    transform-origin: top;
    opacity: 0;
    visibility: hidden;

    &.active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
    & .logo {
        width: 120px;
        @include md {
            transform: translateY(0);
        }
    }
}

.menu-sticky1 {
    > ul {
        > li {
            margin: 0;
            margin-right: 0;
            margin-left: 34px;
            > a {
                padding: 27px 0;
            }
        }
    }
}

// Header 2 --------------------------------
.header-wrapper2 {
    .logo-wrapper {
        text-align: left;
        background-color: transparent;
        box-shadow: none;
        .header-logo {
            padding-top: 0;
        }
    }
    .header-contact {
        background-color: transparent;
        box-shadow: none;
        padding: 40px 0;
    }
    .menu-wrapper {
        padding: 0 60px;
        box-shadow: 0px 15px 100px 0px rgba(0, 0, 0, 0.08);
        position: relative;
    }
    .btn-fhw {
        padding: 30px 33px;
    }
}

// Header 3 -------------------------------

.head-top-widget2.style3 {
    span, 
    a {
        color: $body-color;
    }
    a:hover {
        color: $theme-color;
    }
    .border-right {
        border-right: 1px solid #ccc;
    }
}
.menu-wrapper3 {
    height: 130px;
}
.main-menu.menu-style3 > ul > li > a {
	padding: 51px 0;
}

.icon-box .fa-phone-volume {
	transform: rotate(-45deg) translate(7px , 8px);
    margin-right: 4px;
}

// Header 4 ----------------------------------
.header-absolute {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
}
.main-menu.link-white > ul > li > a {
	color: $white-color;
    &:hover {
        color: $theme-color;
    }
}
.head-top-widget2.style4 {
    span, 
    a {
        color: $white-color;
    }
    a:hover {
        color: $theme-color;
    }
    .border-right {
        border-right: 1px solid #ccc;
    }
}

@include ml {
    .header-contact {
        .px-45 {
            padding-left: 20px !important;
            padding-right: 20px !important;
        }
    }
    .header-wrapper2 .header-contact {
        padding-left: 40px;
    }
    .shopping-cart-wrapper {
        left: unset;
        right: 0;
        transform: translateX(0%);
        &:before {
            bottom: 100%;
            left: calc(100% - 35px);
            transform: translateX(0);
        }
    }
}
@include lg {
    .header-logo {
        max-width: 80%;
        margin: 0 auto;
    }
    .header-wrapper2 {
        .header-logo {
            margin: 0;
        }
    }
    .logo-wrapper .support-link {
        width: 100%;
    }
    .main-menu ul.mega-menu {
        max-width: calc(var(--main-container) - var(--container-gutters));
        width: calc(100% - var(--container-gutters));;
    }
    .header-wrapper2 .menu-wrapper {
        padding: 0 40px;
    }
    .main-menu.menu-style2 > ul > li {
        margin-right: 20px;
    }
    .main-menu.menu-style2 {
        ul.mega-menu {
            max-width: 100%;
            width: 100%;
            li {
                margin-right: 10px;
            }
        }
    }
    // Header 3 
    .main-menu.menu-style3 > ul > li {
        &:last-child {
            margin-right: 0;
        }
    }
    .logo-wrapper3 .header-logo {
        width: 100%;
        max-width: 100%;
    }
    .header-top-shape {
        width: 50%;
    }
}
@include md {
    .header-wrapper {
        background-color: $white-color;
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
        .row {
            align-items: center;
        }
        &.header-wrapper4 {
            background-color: transparent;
        }
    }
    .tr-20::before {
        display: none;
    }
    .header-logo {
        max-width: 170px;
        padding: 10px 0;
    }
    .top-widget {
        position: absolute;
        right: 100px;
        top: 25px;
    }
    .top-widget.style-2 {
        top: -64px;
    }
    .menu-wrapper3 {
        height: 100px;
    }
    .shopping-cart-wrapper {
        top: calc(100% + 35px);
    }
}
@include sm {
    .header-logo {
        margin: 0;
    }
}
@include xs {
    .header-logo {
        margin: 0;
    }
    .d-xs-none {
        display: none;
    }
}