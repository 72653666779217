.widget_shopping_cart {
  .widget_title {
    margin-bottom: 10px;
  }
    ul {
      margin: 0;
      padding: 0;
  
      li {
        list-style-type: none;
      }
    }
  
    .mini_cart_item {
      position: relative;
      padding: 30px 10px 30px 90px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.10);
      margin-bottom: 0;
      text-align: left;
  
      &:first-child {
        border-top: 1px solid rgba(0, 0, 0, 0.10);
      }
    }
  
    .cart_list {
      a:not(.remove) {
        display: block;
        color: $body-color;
        font-size: 16px;
        font-weight: 500;
        font-family: $title-font;
        font-weight: 600;
        color: $title-color;
  
        &:hover {
          color: $theme-color;
        }
      }
  
      a.remove {
        position: absolute;
        top: 50%;
        left: 95%;
        transform: translateY(-50%);
        color: $body-color;
  
        &:hover {
          color: $theme-color;
        }
      }
  
      img {
        width: 75px;
        height: 75px;
        position: absolute;
        left: 0;
        top: 20px;
        border: 1px solid $border-color;
      }  
    }
  
    .quantity {
      display: inline-flex;
      white-space: nowrap;
      vertical-align: top;
      margin-right: 20px;
      font-size: 14px;
      color: $theme-color;
    }
  
    .total {
      margin-top: 20px;
      font-size: 18px;
      color: $title-color;
    }
  
  
    .amount {
      padding-left: 5px;
      font-weight: 600;
      font-family: $title-font;
    }
  
    .vs-btn {
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  
  .sidebar-area {
    
    .widget_shopping_cart .vs-btn {
      margin-right: 10px;
      padding: 8px 22px;
      font-size: 14px;
    }
  
}