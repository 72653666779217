@each $fontsMap, $value in $fontsMap {
  .font-#{$fontsMap} {
    font-family: #{$value};
  }
}

.fw-light {
  font-weight: 300;
}

.fw-normal {
  font-weight: 400;
}

.fw-medium {
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600;
}

.fw-bold {
  font-weight: 700;
}

.fw-extrabold {
  font-weight: 800;
}

.fs-md {
  font-size: 18px;
}

.fs-xs {
  font-size: 14px;
}

.fs-22 {
  font-size: 22px;
}
.fs-20 {
  font-size: 20px;
}

.fs-16 {
  font-size: 16px;
}


.fs-16 {
  font-size: 18px;
}

.fs-24 {
  font-size: 24px;
}

.fs-26 {
  font-size: 26px;
}

.fs-30 {
  font-size: 30px;
}

.f-18-600 {
  font-size: 18px;
  font-weight: 600;
}

.fs-48 {
  font-size: 48px;
}

.fw-200 {
	font-size: 200px;
	font-weight: 900;
  margin-top: -3rem;
}

@include lg {
  .fs-24 {
    font-size: 22px;
  }
  .fs-26 {
    font-size: 24px;
  }
  .fs-30 {
    font-size: 26px;
  }
  .fs-48 {
    font-size: 40px;
  }
}

@include md {
  .fs-24 {
    font-size: 20px;
  }
  .fs-26 {
    font-size: 20px;
  }
  .fs-30 {
    font-size: 24px;
  }
  .fs-48 {
    font-size: 36px;
  }
  .fw-200 {
    font-size: 150px;
    margin-top: -2.3rem;
  }
}

@include xs {
  .fs-24 {
    font-size: 16px;
  }
  .fs-26 {
    font-size: 18px;
  }
  .fs-30 {
    font-size: 20px;
  }
  .fs-48 {
    font-size: 26px;
  }
  .fw-200 {
    font-size: 100px;
    margin-top: -1.5rem;
  }
}