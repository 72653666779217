.vs-blog {
    background: $white-color;
    @include transition(.3s);
    .vs-video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .blog-audio {
        line-height: 0;
    }
    .blog-img {
        position: relative;
    }
}

// Blog page ------------------------------
.blog-big {
    --border-color: rgb(245, 245, 245);
    .blog-title {
        font-size: 30px;
        line-height: 1.3;
    }
    .blog-content {
        padding: 50px 60px 50px 60px;
        border: 2px solid $border-color;
        border-top: none;
    }
    .blog-meta {
        a {
            color: $body-color;
            margin-right: 24px;
            position: relative;
            font-size: 14px;
            >i {
                margin-right: 7px;
                color: $theme-color;
            }

            &:hover {
                color: $theme-color;
            }
        }
    }
    .read-btn {
        font-weight: 500;
        font-size: 18px;
        position: relative;
        &:after {
            content: "";
            height: 1px;
            width: 0;
            background-color: $title-color;
            position: absolute;
            bottom: 0;
            left: 0;
            transition: 0.4s ease-in-out
        }
        i {
            @include transition(0.3s);
        }
        &:hover {
            &:after {
                width: calc(100% - 20px);
            }
            i {
                margin-left: 2px;
            }
        }
    }
}
blockquote {
    --smoke-color: rgba(246, 246, 246, 1);
    font-size: 20px;
    line-height: 30px;
    padding: 50px 60px 50px 60px;
    display: block;
    position: relative;
    text-align: center;
    background-color: $smoke-color;
    overflow: hidden;
    margin: 35px 0;
    color: $title-color;
    &:before {
        content: '\f10e';
        font-family: $icon-font;
        position: absolute;
        right: 40px;
        bottom: 30px;
        font-size: 11rem;
        font-weight: 600;
        line-height: 1;
        color: $white-color;
    }
    p {
        font-family: inherit;
        margin-bottom: 0 !important;
        color: inherit;
        line-height: inherit;
        width: 100%;
        position: relative;
        max-width: 590px;
        margin: 0 auto;
        z-index: 3;
        font-weight: 500;
    }
    cite {
        display: inline-block;
        font-size: 18px;
        position: relative;
        border-color: inherit;
        line-height: 1;
        font-weight: 500;
        margin-top: 22px;
        font-style: normal;
        color: $theme-color;
    }
}

// Blog details --------------------
.blog-single {
    border-bottom: 1px solid $border-color;
    padding-bottom: 28px;
    .blog-content {
        padding-top: 35px;
    }
    .share-links {
        border-top: 1px solid $border-color;
        padding-top: 28px;
        margin-top: 25px;
    }
    .blog-meta {
        a {
            color: $body-color;
            margin-right: 24px;
            position: relative;
            font-size: 14px;
            >i {
                margin-right: 7px;
                color: $theme-color;
            }

            &:hover {
                color: $theme-color;
            }
        }
    }
} 

// Extra --------------------------------
.share-links-title {
    font-size: 18px;
	font-weight: 600;
	color: $title-color;
	margin-right: 20px;
}
.blog-social {
    --icon-size: 34px;
    --facebook: #3b5998;
    --twitter: #00acee;
    --linkedin: #0e76a8;
    --instagram: #d63084;
    padding-left: 0;
    margin: 0;
    li {
        display: inline-block;
        list-style-type: none;
        &:last-child {
            i {
                margin-right: 0;
            }
        }
    }
    a {
        display: inline-block;
    }
    i {
        @include equal-size-lineHeight(var(--icon-size));
        background-color: $theme-color;
        color: $white-color;
        text-align: center;
        border-radius: 50%;
        margin: 0 3px;
        &[class*="facebook"] {
            background-color: var(--facebook);
        }
        &[class*="twitter"] {
            background-color: var(--twitter);
        }
        &[class*="linkedin"] {
            background-color: var(--linkedin);
        }
        &[class*="instagram"] {
            background-color: var(--instagram);
        }
        &:hover {
            background-color: $title-color;
        }
    }
}
.blog-details {
    .blog-author {
        margin-top: 80px;
        padding: 55px 60px;
        border-right: 10px solid $theme-color;
        .author-text {
            line-height: 26px;
        }
    }
}

.blog-card {
    position: relative;
    .blog-date {
        font-size: 12px;
        line-height: 14px;
        padding: 12px 10px 19px 10px;
        border-radius: 0 0 5px 5px;
        color: $title-color;
        position: absolute;
        top: 30px;
        right: 30px;
        display: inline-block;
        background-color: $white-color;
        width: 58px;
        border-top: 2px solid $theme-color;
        text-align: center;
        &::first-line {
            font-size: 24px;
            font-weight: 600;
            line-height: 1.4;
        }
        &:hover {
            color: $theme-color;
        }
    }
    .blog-content {
        padding: 40px;
        background-color: white;
        border: 2px solid rgb(245, 245, 245);
        border-top: none;
        box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.04);
        @include transition(.4s)
    }
    .blog-category {
        color: $body-color;
        font-size: 14px;
        margin-bottom: 5px;
        i {
            margin-right: 4px;
        }
        a {
            color: inherit;
            font-size: inherit;
            &:hover {
                color: $theme-color;
            }
        }
    }
    .blog-title {
        padding-right: 10px;
        margin-bottom: 18px;
        line-height: 1.4;
    }
    .blog-author {
        display: flex;
        align-items: center;
        .author-img {
            width: 45px;
            height: 45px;
            border-radius: 5px;
            overflow: hidden;
            float: left;
            margin-right: 15px;
        }
        .author-info {
            span {
                font-size: 14px;
                line-height: 1;
            }
            .author-name {
                margin-bottom: 0;
            }
        }
    }
    &:hover {
        .blog-content {
            border-color: $white-color;
            box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.06);
        }
    }
}
  
.img-scale {
    overflow: hidden;
    img {
        @include transition(.4s);
    }
    &:hover {
        img {
            transform: scale(1.14);
        }
    }
}
#bbox1 {
    .slick-list.draggable {
        margin-bottom: -50px;
        padding-bottom: 50px;
    }
}

// Pagination ----------------------------
.vs-pagination {
    text-align: center;
    ul {
        margin: 0;
        padding: 0;
    }
    li {
        display: inline-block;
        margin: 0 5px;
        list-style-type: none;
        &:first-child {
            margin-left: 0;
        }
    }
    span, 
    a {
        display: inline-block;
        width: 50px;
        height: 50px;
        line-height: 48px;
        color: $body-color;
        text-align: center;
        position: relative;
        border: 2px solid rgb(242, 242, 242);
        z-index: 1;
        font-weight: 700;
        border-radius: 10px;
        &.active, 
        &:hover {
            color: $white-color;
            background-color: $theme-color;
            border-color: transparent;
        }
    }
}

@include lg {
    .vs-blog-wrapper {
        .gx-60, .g-60 {
            --bs-gutter-x: 30px;
        }
    }
    .blog-card .blog-content {
        padding: 20px;
    }
    .blog-big {
        .blog-title {
            font-size: 24px;
        }
        .blog-content {
            padding: 40px;
        }
    }
    blockquote {
        padding: 40px;
        font-size: 18px;
    }
    .blog-details .blog-author {
        padding: 30px;
    }
    .tags-margin {
        margin-bottom: 30px;
    }
}

@include md {
    .blog-big .blog-title {
        font-size: 22px;
    }
    .tags-margin {
        margin-bottom: 0;
    }
}

@include sm {
    .blog-big {
        .blog-title {
            font-size: 20px;
        }
        .blog-content {
            padding: 25px 20px;
        }
    }
    .blog-details .blog-author {
        margin-top: 40px;
        padding: 20px;
    }
    blockquote {
        padding: 30px;
    }
    blockquote::before {
        font-size: 5rem;
    }
    .tags-margin {
        margin-bottom: 30px;
    }
}